import React, { Component } from "react";
import { DatePicker } from "/src/component/general/DatePicker";
import Alert from "react-s-alert";

import { ABSENCE_USER } from "../../config/lang";

import { call } from "../../util/action";
import { dateStart, dateEnd } from "../../util/formatter";
import { getStore } from "../../util/redux/store";
import { translate } from "../../util/util";

const me = getStore().getState().me;

// console.log(me);

class Absence extends Component {
  constructor(props) {
    super(props);

    this.state = {
      item: {
        start: new Date(),
        end: new Date(),
        reason: "",
      },
      changedItem: false,
      items: [],
    };
  }

  submit = () => {
    let { item } = this.state;

    item.created_by = me.id;
    item.merchandiser = this.props.user.id;
    setTimeout(() => {
      call({ method: "POST", url: "absences/", post: item }, r => {
        if (r && r.id) {
          Alert.success("Дані успішно збережені", { position: "bottom-right" });

          let cleanItem = { reason: "", start: new Date(), end: new Date() };
          this.setState({ item: cleanItem });

          this.load();
        } else {
          Alert.warning("Error while saving item. Error: " + JSON.stringify(r), { position: "bottom-right" });
          console.log(r);
        }
      });
    }, 3000);

    this.setState({ changedItem: true });
  };

  onChange = (property, value) => {
    let item = this.state.item;
    item[property] = value;
    this.setState({ item: item, changedItem: false });
  };

  componentDidMount() {
    this.load();
  }

  load = () => {
    call({ method: "GET", url: "absences?merchandiser=" + this.props.user.id }, r => {
      this.setState({ items: r });
    });
  };

  render() {
    let { item } = this.state;
    return (
      <div className="row m-t">
        <div className="box boxEdit box-body boxForm m-auto">
          <h5>{translate(ABSENCE_USER, "query", me.language)}</h5>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>{translate(ABSENCE_USER, "date_from", me.language)} *</label>
                <DatePicker selected={new Date(item.start)} className="datePicker" onChange={date => this.onChange("start", date)} />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>{translate(ABSENCE_USER, "date_to", me.language)} *</label>
                <DatePicker selected={new Date(item.end)} className="datePicker" onChange={date => this.onChange("end", date)} />
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label>{translate(ABSENCE_USER, "reason", me.language)} *</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={translate(ABSENCE_USER, "absence_reason", me.language)}
                  value={item.reason}
                  onChange={e => this.onChange("reason", e.target.value)}
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className={`btn btn-primary white m-b ${this.state.changedItem ? "disabled" : ""}`} onClick={() => this.submit()}>
                {translate(ABSENCE_USER, "add_btn", me.language)}
              </div>
            </div>

            <div className="col-md-12 mt-5">
              <h5>{translate(ABSENCE_USER, "absence_history", me.language)}</h5>
              <table className="table table-hover table-bordered tableRoute">
                <tbody>
                  <tr className="primary tableRouteHead">
                    <td>{translate(ABSENCE_USER, "date_from", me.language)}</td>
                    <td>{translate(ABSENCE_USER, "date_to", me.language)}</td>
                    <td>{translate(ABSENCE_USER, "reason", me.language)}</td>
                  </tr>
                  {this.state.items.map(i => {
                    return (
                      <tr data-keys={i.id} key={i.id}>
                        <td>{dateStart("", i)}</td>
                        <td>{dateEnd("", i)}</td>
                        <td>{i.reason}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Absence;
