import React, { Component } from "react";
import Alert from "react-s-alert";

import ImageCanvasEditor from "../../component/image-canvas-editor/image-canvas-editor";
import Loading from "../../component/Loading";
import { API_URL_LOGIN } from "../../config/config";
import { PHOTO_REVIEW } from "../../config/lang";
import { TaskEdit } from "../task/Edit";

import { call } from "../../util/action";
import {
  dateFormat,
  reviewTaskSalePriceMonitoring,
  reviewTaskPriceMonitoring,
  reviewMerchandiserNamePhone,
  reviewTaskVisitPoint,
  reviewTaskSupervisor,
} from "../../util/formatter";
import { getStore } from "../../util/redux/store";
import { translate } from "../../util/util";

const me = getStore().getState().me;
class Preview extends Component {
  constructor(props) {
    super(props);

    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount = () => {
    document.addEventListener("keydown", this.handleKeyDown, false);
  };

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown, false);
  }

  handleKeyDown = e => {
    let { acceptRejectAnswer, passTaskToModal, moveNext } = this.props;

    switch (e.code) {
      case "ArrowLeft":
        moveNext(-1);
        break;
      case "ArrowRight":
        moveNext(+1);
        break;
      case "ArrowUp":
        acceptRejectAnswer("ACCEPT");
        break;
      case "ArrowDown":
        passTaskToModal();
        break;
    }
  };

  deleteImage = idx => {
    let images = this.props.editBlockData.item.images;
    console.log(images);
    let id = images[idx].id;
    // delete images[idx];
    this.props.editBlockData.onChange("images", []);

    call({ method: "DELETE", url: "images/" + id + "/", body: {} }, () => {
      Alert.success("Image successfully deleted", { position: "top" });
    });
  };

  handleDownlodDetailsPhoto = async e => {
    e.preventDefault();
    e.stopPropagation();

    const image = await fetch(`${e.currentTarget?.href}?chunk=${Date.now()}`); // expected <a href> link  or this.props.item.photo
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);
    // const imageName = imageURL.substring(imageURL.lastIndexOf("/") + 1);
    const imageName = this.getPhotoName();
    const link = document.createElement("a");

    link.href = imageURL;
    link.download = `${imageName}.jpg`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  getPhotoName = () => {
    const dateString = dateFormat(this.props.item.visit?.start);
    const [datePart, timePart] = dateString.split(" ");
    const [day, month, year] = datePart.split("/");
    const [hours, minutes] = timePart.split(":");
    const pointName = this.props.item.visit?.point?.name;
    const pointNameString = pointName.replace(/\s+/g, "_");

    const formattedDate = `${day}_${month}_${year}_${hours}_${minutes}_${pointNameString}`;

    return formattedDate;
  };

  isMonitoringData = () => {
    const item = this.props.item;
    return reviewTaskPriceMonitoring("", item) || reviewTaskSalePriceMonitoring("", item) ? true : false;
  };
  /*   onModalToggle = (e) => {
    //let isOpenModal = false;
    console.log(e, "isOpenModal");
     this.setState({
      isOpenModal: !this.state.isOpenModal,
    }); 
    //return !this.isOpenModal;
  }; */

  render() {
    let { item, acceptRejectAnswer, passTaskToModal, editBlockData, isLoading } = this.props;
    if (!item) {
      return null;
    }

    const tags = Array.isArray(item?.additional_info?.tags)
      ? item?.additional_info?.tags?.map(i => (
          <span key={i} className="label rounded">
            {i}
          </span>
        ))
      : typeof item?.additional_info?.tags === "string"
      ? item?.additional_info?.tags
      : "";

    let siteUrl = API_URL_LOGIN;
    siteUrl = siteUrl.replace("/login/", "");

    return (
      <div className="item taskBlock">
        <Loading loading={isLoading} disP={true} type="spinner" />
        <div className="col-md-5 pr-2 photoContainer">
          <div className={`original-photo ${editBlockData.isModal > 0 ? "hidden" : ""}`}>
            <div className="previewPhotoNav">
              {item.photo ? (
                <div className="openPhoto">
                  <a href={item.photo} target="_blank">
                    <i className="fa fa-arrows-alt" />
                  </a>
                  <a href={item.photo} download className="ml-1" onClick={this.handleDownlodDetailsPhoto}>
                    <i className="fa fa-cloud-download" />
                  </a>
                </div>
              ) : (
                "<h5>Photo Not found</h5>"
              )}
            </div>
            {item.photo ? <img src={item.photo} className="previewImage" /> : "<h5>Photo Not found</h5>"}
            <div className="details-buttons-container">
              <div className="allowed-button">
                <button
                  className="btn btn-primary acceptBtn"
                  onClick={() => {
                    acceptRejectAnswer("ACCEPT");
                  }}
                  title={translate(PHOTO_REVIEW, "approv_btn", me.language)}>
                  <i className="fa fa-check-circle" />
                </button>
              </div>
              <div className="cancel-button">
                <button
                  className="btn btn-primary rejectBtn"
                  onClick={() => {
                    passTaskToModal();
                  }}
                  title={translate(PHOTO_REVIEW, "reject_btn", me.language)}>
                  <i className="fa fa-times-circle" />
                </button>
              </div>
            </div>
          </div>
          {editBlockData.item?.images?.length > 0 && editBlockData.isModal ? (
            <div className="taskImagesEdit">
              {editBlockData.item?.images?.map((image, idx) => (
                <div key={idx} className="d-flex">
                  <img src={image.image} onClick={editBlockData.setOpen} />
                  <div className="previewPhotoNav">
                    {editBlockData.isImageEditable && <ImageCanvasEditor src={image.image} id={idx} onSaveImage={editBlockData.onSaveEditorImage} />}
                    {/*   <button type="button" className="btn btn-primary btn-sm white" onClick={this.onModalToggle}>
                      <i className="fa fa-edit" />
                    </button> */}
                    <button type="button" onClick={() => this.deleteImage(idx)}>
                      <i className="fa fa-minus-square-o" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-7 py-4">
          <div className={`photo-review-details text-left ${editBlockData.isModal ? "hidden" : ""}`}>
            <div className="photo-review-header">
              <div className="form-group">
                <img src={`${siteUrl}${item.visit?.point?.image}`} className="point-logo" alt={item.visit?.point?.locality?.name} />
                <div className="point-location">
                  {reviewTaskVisitPoint("", item)}
                  <p>{item.visit?.point?.locality?.name}</p>
                </div>
              </div>
              <div className="form-group tags-group">
                <p>{tags}</p>
              </div>
            </div>
            <div className="photo-review-content">
              <div className="form-group">
                <label>{translate(PHOTO_REVIEW, "merchendiser", me.language)}</label>
                {reviewMerchandiserNamePhone("", item)}
              </div>

              <div className="form-group">
                <label>{translate(PHOTO_REVIEW, "responsible_supervisor", me.language)}</label>
                {reviewTaskSupervisor("", item)}
              </div>

              <div className="form-group">
                <label>{translate(PHOTO_REVIEW, "start_visit", me.language)}</label>
                <p>{dateFormat(item.visit?.start)}</p>
              </div>

              <div className="form-group">
                <label>{translate(PHOTO_REVIEW, "end_visit", me.language)}</label>
                <p>{dateFormat(item.visit?.end)}</p>
              </div>

              <div className="form-group">
                <label>{translate(PHOTO_REVIEW, "comment", me.language)}</label>
                <p>{item?.additional_info?.comment}</p>
              </div>
            </div>
            {this.isMonitoringData() ? (
              <>
                <div className="photo-review-header monitoring-header">
                  <div className="form-group">
                    <div className="point-location">
                      <p className="title-block">{translate(PHOTO_REVIEW, "price_monitoring", me.language)}</p>
                    </div>
                  </div>
                </div>
                <div className="photo-review-content">
                  {reviewTaskPriceMonitoring("", item) ? (
                    <div className="form-group">
                      <label>{translate(PHOTO_REVIEW, "price", me.language)}</label>
                      {reviewTaskPriceMonitoring("", item)}
                    </div>
                  ) : null}
                  {reviewTaskSalePriceMonitoring("", item) ? (
                    <div className="form-group">
                      <label>{translate(PHOTO_REVIEW, "sales_price", me.language)}</label>
                      {reviewTaskSalePriceMonitoring("", item)}
                    </div>
                  ) : null}
                </div>
              </>
            ) : null}
          </div>
          {editBlockData.isModal ? (
            <div className="text-left new-task-wrapper">
              <Loading loading={editBlockData.newTaskLoading} disP={true} type="spinner" />
              <TaskEdit {...editBlockData} />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default Preview;
