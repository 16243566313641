import React, { Component } from "react";
import { TableHeaderColumn } from "react-bootstrap-table";

import EntityTable from "../../component/EntityTable";

import { call } from "../../util/action";
import { point } from "../../util/formatter";

import Edit from "../route/Edit";
import View from "../route/View";

class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: "VIEW",
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;

    if (id !== "new") {
      this.load(id);
    } else {
      this.setState({ mode: "EDIT" });
    }
  }

  load = id => {
    call({ method: "GET", url: "routes/" + id + "/" }, r => {
      this.setState({ item: r });
    });
  };

  toggleMode = () => {
    this.setState({ mode: this.state.mode == "EDIT" ? "VIEW" : "EDIT" });
  };

  render() {
    let { item } = this.state;

    return (
      <div className="container-fluid">
        <div className="box p-4">
          <h5 className="mt-3 mb-3">
            <span className="faIcon">
              <i className="fa fa-route" />
            </span>
            View Route #{item && item.id}
          </h5>

          <div className="item taskBlock">
            <div className="p-a-md">
              <div className="row pull-right">
                <div className="btn btn-outline-primary d-inline-block" onClick={this.toggleMode}>
                  {this.state.mode == "EDIT" ? (
                    <React.Fragment>
                      <i className="fa fa-eye" /> View
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fa fa-edit" /> Edit
                    </React.Fragment>
                  )}
                </div>
              </div>

              {this.state.mode == "VIEW" ? <View item={item} /> : <Edit item={item} />}

              <div className="row m-t">
                <div className="col-sm-12 taskTags mt-3">
                  <h4>Points</h4>

                  <EntityTable data={item && item.points} type="data">
                    <TableHeaderColumn dataField="id" width="82px" isKey></TableHeaderColumn>
                    <TableHeaderColumn dataFormat={point} dataSort>
                      Point
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="start" dataSort>
                      Start
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="end" dataSort>
                      End
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="order" dataSort>
                      Order
                    </TableHeaderColumn>
                  </EntityTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Page;
