import React, { Component, useState } from "react";
import moment from "moment";
import Lightbox from "react-image-lightbox";
import Alert from "react-s-alert";

import Loading from "../../component/Loading";
import Modal from "../../component/Modal";
import PhotoTaskSlider from "../../component/PhotoTaskSlider";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import { TaskEdit } from "./Edit";
import { modelMap } from "../../config/config";
import { TAKS_LIST } from "../../config/lang";

import { call } from "../../util/action";
import { dateFormat, taskPoint, networkName, getNetworkData, createdByInfo, supervisorName, merchandiserName, taskStatus } from "../../util/formatter";
import { getStore } from "../../util/redux/store";
import { translate } from "../../util/util";
import { urlToIdPhotoAsync, validateReviewTask } from "../../util/util";

const me = getStore().getState().me;
const defaultTemplate = getStore().getState().defaultTemplate;

const label = { inputProps: { "aria-label": "Switch demo" } };

export const BasicMenu = ({ item, acceptRejectAnswer, photoIndex }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuButtonClassNames, setMenuButtonClassNames] = useState("task-answer-menu-button");
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const getPhotoName = () => {
    const dateString = dateFormat(item.task?.created);
    const [datePart, timePart] = dateString.split(" ");
    const [day, month, year] = datePart.split("/");
    const [hours, minutes] = timePart.split(":");
    const pointName = item.task?.point?.name;
    const pointNameString = pointName.replace(/\s+/g, "_");

    const formattedDate = `${day}_${month}_${year}_${hours}_${minutes}_${pointNameString}`;

    return formattedDate;
  };
  const downloadAnswerPhoto = async (e, answer) => {
    e.preventDefault();
    e.stopPropagation();
    //
    const status = answer.photos.length > 0 ? false : answer.photo ? false : true;
    if (status) return;

    setMenuButtonClassNames("task-answer-menu-button task-answer-menu-button--in-progress");
    const photo = answer.photos.length ? answer.photos[photoIndex]?.photo_url : answer.photo;
    const image = await fetch(`${photo}?chunk=${Date.now()}`);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);
    const imageNameOrigin = getPhotoName();
    const link = document.createElement("a");
    link.href = imageURL;
    link.download = `${imageNameOrigin}.jpg`;
    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
    setMenuButtonClassNames("task-answer-menu-button");
    handleClose();
  };
  return (
    <div className="task-answer-menu">
      <span className={menuButtonClassNames}>
        <MoreHorizIcon id="basic-button" aria-controls="basic-menu" aria-haspopup="true" aria-expanded={open ? "true" : undefined} onClick={handleClick} />
      </span>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}>
        <MenuItem
          onClick={e => {
            downloadAnswerPhoto(e, item);
          }}>
          {translate(TAKS_LIST, "download_btn", me?.language)}
        </MenuItem>
        <MenuItem
          onClick={() => {
            acceptRejectAnswer("ACCEPT", item);
            handleClose();
          }}>
          {translate(TAKS_LIST, "approv_btn", me?.language)}
        </MenuItem>
        <MenuItem
          onClick={() => {
            acceptRejectAnswer("REJECT", item);
            handleClose();
          }}>
          {translate(TAKS_LIST, "reject_btn", me?.language)}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default class TaskView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      answerIndex: 0,
      isOpen: false,
      isModal: false,
      newTask: {},
      newTaskLoading: false,
      networkTaskName: "",
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    const { item } = this.props;
    const { networkTaskName } = this.state;
    const networkId = item?.point?.network;
    if (networkId && !networkTaskName) {
      call({ method: "GET", url: modelMap["network"] }, response => {
        const networkTaskName = response?.find(item => item.id === networkId)?.name || "—";
        this.setState({
          networkTaskName,
        });
      });
    }
  }

  modalOpen = () => {
    this.setState({ isModal: true });
  };

  modalClose = () => {
    this.setState({ isModal: false });
  };

  newTaskOnChange = (property, value) => {
    // let item = this.state.newTask;
    // item[property] = value;
    // this.setState({ newTask: item });
    this.setState(prevState => ({
      newTask: { ...prevState.newTask, [property]: value },
    }));
  };

  onSaveEditorImage = (index, image) => {
    const { newTask } = this.state;

    if (!image) return;

    call({ method: "POST", url: "images/", post: { owner: this.props.me, image } }, image => {
      if (image?.id) {
        this.setState({ newTask: { ...newTask, images: [image] } });
      }
    });
  };

  newTaskSubmit = () => {
    let { newTask } = this.state;
    let queueLoadingTimer = 0;
    let queueLoadingStep = 8000;
    let itemPost = Object.assign({}, newTask);
    itemPost.components = newTask.components.map(i => i.id);
    itemPost.tags = newTask.tags?.map(i => i.id);
    itemPost.network = newTask?.network?.id || null;
    itemPost.point = newTask?.point?.id || null;
    itemPost.end = moment(itemPost.end).format("yyyy-MM-DDT23:59:59");
    itemPost.images = newTask.images.map(i => (i.id ? i.id : i));
    itemPost.created_by = newTask.created_by?.id || me?.id || null;
    itemPost.template = itemPost.template === "" ? defaultTemplate : itemPost.template;

    const { validate, errors } = validateReviewTask("task", itemPost);

    if (!validate) {
      this.setState({ errors });

      return;
    }

    if (itemPost.images.every(image => image.id)) {
      itemPost.images = newTask.images?.map(image => image.id);
      this.createNewTask(itemPost);
    } else {
      this.setState({
        newTaskLoading: true,
      });

      itemPost.images = newTask.images?.map((image, index) => {
        if (image.id) {
          itemPost.images = [image.id];
          this.createNewTask(itemPost);
          this.setState({
            itemPost,
            newTaskLoading: false,
            isModal: false,
          });
          return image.id;
        } else {
          setTimeout(args => {
            urlToIdPhotoAsync(image.image, this.props.me, createdImg => {
              if (createdImg.id) {
                itemPost.images.push(createdImg.id);
                if (index === newTask.images.length - 1) {
                  itemPost.images = itemPost.images?.filter(item => !!item);
                  this.createNewTask(itemPost);
                  this.setState({
                    itemPost,
                    newTaskLoading: false,
                    isModal: false,
                  });
                }
              } else {
                console.error("image was not created");
              }
            });
          }, queueLoadingTimer);
          queueLoadingTimer += queueLoadingStep;
        }
      });
    }
  };

  sendRejectAnswer = answerId => {
    const body = {
      object_id: answerId,
      object_type: "answer", //('report', “pricemonitoringreport”, “answer”),
      is_approved: false,
    };
    this.callUpdateAnswer(body, () => {
      this.props.loadTask(this.props.item?.id);
    });
  };

  createNewTask = data => {
    if (!data) return;

    call({ method: "POST", url: "tasks/", post: data }, response => {
      if (response?.id) {
        Alert.success(translate(TAKS_LIST, "task_saved", me?.language));
        this.setState({ VIEW: "VIEW", isModal: false, errors: {} });
        this.sendRejectAnswer(data.answer_id); //true for send reject answer
      } else {
        Alert.warning("Error while saving item. Error: " + JSON.stringify(response));
        console.log(response);
      }
    });
  };

  printReport = e => {
    e.preventDefault();

    // let printContents = document.querySelector(".task-container").innerHTML;
    // let originalContents = document.body.innerHTML;

    // document.body.innerHTML = printContents;
    window.print();
    // document.body.innerHTML = originalContents;
  };

  showBigImg = answerIndex => {
    this.setState({ isOpen: true, answerIndex });
  };

  callUpdateAnswer = (body, cb) => {
    call({ method: "POST", url: "approvals/", post: body }, r => {
      Alert.success(translate(TAKS_LIST, "answer_saved", me?.language));
      cb();
    });
  };

  activePhotoIndex = (index = 0) => {
    this.setState({ photoIndex: index });
    return index;
  };

  acceptRejectAnswer = (type, answer) => {
    const { item } = this.props;
    const { photoIndex } = this.state;
    const body = {
      object_id: answer.id,
      object_type: "answer", //('report', “pricemonitoringreport”, “answer”),
      is_approved: null,
    };

    if (type == "REJECT") {
      const newTask = Object.assign({}, item);
      body.is_approved = false;

      let date = new Date(newTask.end);
      date = date.setDate(date.getDate() + 7);
      newTask.end = moment(date).format("yyyy-MM-DDT23:59:59");
      newTask.template = "";
      newTask.answer_id = answer.id;
      // newTask.status = "rejected";
      if (answer.photos.length > 0) {
        newTask.images = [{ image: answer.photos[photoIndex]?.photo_url }];
        newTask.imageFromReview = { image: answer.photos[photoIndex]?.photo_url };
      } else if (answer.photo) {
        newTask.images = [{ image: answer.photo }];
        newTask.imageFromReview = { image: answer.photo };
      }
      this.setState({ newTask, isModal: true });
    } else if (type == "ACCEPT") {
      body.is_approved = true;
      this.callUpdateAnswer(body, () => {
        this.props.loadTask(this.props.item?.id);
      });
    }
  };

  render() {
    let { item, answers } = this.props;
    const { isOpen, isModal, photoIndex, newTaskLoading, networkTaskName } = this.state;
    const lang = me?.language;
    return (
      <div>
        <div className="task-content">
          <div className="task-info">
            <div className="row text-left">
              <div className="col-md-12">
                <div className="form-group">
                  <label>{translate(TAKS_LIST, "name", lang)}</label>
                  <p>{item && item.title}</p>
                </div>
                <div className="form-group">
                  <label>{translate(TAKS_LIST, "description", lang)}</label>
                  <p>{item.description || "—"}</p>
                </div>
                <div className="form-group">
                  <label>{translate(TAKS_LIST, "addresses", lang)}</label>
                  <p>{taskPoint(item)}</p>
                </div>
                <div className="form-group">
                  <label>{translate(TAKS_LIST, "network", lang)}</label>
                  <p>{networkTaskName}</p>
                </div>
                {/*<div className="form-group">
                  <label>Опис завдання</label>
                  <p>{item && item.subtitle}</p>
                </div>*/}

                {/*<div className="form-group">
                  <label>Тип задачі</label>
                  <p>{type}</p>
                </div>*/}
                <div className="form-group">
                  <label>{translate(TAKS_LIST, "date_creation", lang)}</label>
                  <p>{item && dateFormat(item.created)}</p>
                </div>
                <div className="form-group">
                  <label>{translate(TAKS_LIST, "created_by", lang)}</label>
                  <p>{createdByInfo("", item)}</p>
                </div>
                {/*<div className="form-group">
                  <label>Дата початку</label>
                  <p>{item && dateFormat(item.start)}</p>
                </div>
                <div className="form-group">
                  <label>Дедлайн</label>
                  <p>{item && dateFormat(item.end)}</p>
                </div>*/}
                <div className="form-group">
                  <label>{translate(TAKS_LIST, "supervisor", lang)}</label>
                  <p>{supervisorName("", item)}</p>
                </div>
                <div className="form-group">
                  <label>{translate(TAKS_LIST, "merchendiser", lang)}</label>
                  <p>{merchandiserName("", item)}</p>
                </div>
                <div className="form-group">
                  {/*<Switch {...label} defaultChecked />*/}

                  <label>{translate(TAKS_LIST, "status", lang)}</label>
                  <p>{taskStatus("", item)}</p>
                </div>
                <div className="mt-3 print-report-container">
                  <a href="#" target="_blank" onClick={this.printReport}>
                    <i className="fa fa-print" /> {translate(TAKS_LIST, "print_report", lang)}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="task-photo">{item?.images?.length > 0 ? <img src={item?.images?.[0]?.image || ""} onClick={this.props.setOpen} /> : null}</div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <h3>{translate(TAKS_LIST, "responses", lang)}</h3>
            <div className="task-answers-list">
              {answers.map((answer, index) => {
                return (
                  <div data-answer-id={index} key={index} className="task-answer photo-carousel">
                    <div className="task-answer-photo-container">
                      <BasicMenu item={answer} photoIndex={this.state.photoIndex} acceptRejectAnswer={this.acceptRejectAnswer} />
                      {answer.photos !== null ? (
                        <div className="answer-photo-wrapper">
                          {/* onClick={e => answer.photo && this.setState({ isOpen: true, photoIndex: index })} */}
                          {answer.photos.length > 0 ? (
                            <PhotoTaskSlider
                              photos={answer.photos}
                              answerId={index}
                              clickCallback={() => this.showBigImg(index)}
                              activePhotoIndex={this.activePhotoIndex}
                              viewMode={"group"}
                            />
                          ) : (
                            answer.photo && (
                              <img
                                src={answer.photo}
                                className="img-placeholder"
                                onClick={() => answer.photo && this.setState({ isOpen: true, photoIndex: index })}
                              />
                            )
                          )}
                        </div>
                      ) : null}
                    </div>
                    <div className="task-answer-info">
                      {answer.boolean_answer !== null ? (
                        <div className="answer-info-radio-wrapper">
                          <span className="mr-2">
                            {answer.boolean_answer ? <RadioButtonCheckedIcon color={"info"} /> : <RadioButtonUncheckedIcon color={"info"} />} так
                          </span>
                          <span>{!answer.boolean_answer ? <RadioButtonCheckedIcon color={"info"} /> : <RadioButtonUncheckedIcon color={"info"} />} ні</span>
                        </div>
                      ) : null}
                      <p>{answer.answer}</p>
                      <div className="d-flex task-answer-info-checking">
                        <div>
                          {!!answer.visit?.user && <div>{answer.visit?.user?.first_name + " " + answer.visit?.user?.last_name}</div>}
                          <div>{dateFormat(answer.created)}</div>
                        </div>
                        <div>
                          <span>{answer.is_supervised && <CheckCircleIcon color={"success"} />}</span>
                        </div>
                      </div>
                      <div className="tags-wrapper mt-3">
                        {answer.selected_tags.map((tag, index) => (
                          <span key={index} className="label rounded">
                            {tag}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {isOpen && (
              <Lightbox
                mainSrc={answers[this.state.answerIndex]?.photos[photoIndex]?.photo_url || answers[photoIndex].photo}
                nextSrc={
                  answers[this.state.answerIndex]?.photos[(photoIndex + 1) % answers.length]?.photo_url || answers[(photoIndex + 1) % answers.length]?.photo
                }
                prevSrc={
                  answers[this.state.answerIndex]?.photos[(photoIndex - 1) % answers.length]?.photo_url || answers[(photoIndex - 1) % answers.length]?.photo
                }
                onCloseRequest={() => this.setState({ isOpen: false })}
                onMovePrevRequest={() =>
                  this.setState({
                    photoIndex:
                      answers[this.state.answerIndex]?.photos.length > 0
                        ? (photoIndex + answers[this.state.answerIndex]?.photos?.length - 1) % answers[this.state.answerIndex]?.photos?.length
                        : (photoIndex + answers.length - 1) % answers.length,
                  })
                }
                onMoveNextRequest={() =>
                  this.setState({
                    photoIndex:
                      answers[this.state.answerIndex]?.photos.length > 0
                        ? (photoIndex + answers[this.state.answerIndex]?.photos?.length - 1) % answers[this.state.answerIndex]?.photos?.length
                        : (photoIndex + answers.length + 1) % answers.length,
                  })
                }
              />
            )}
            {isModal && (
              <Modal isShow={isModal} onClose={this.modalClose}>
                <Loading loading={newTaskLoading} disP={true} type="spinner" />
                <TaskEdit
                  item={this.state.newTask}
                  type="point"
                  newTaskFromReview
                  onChange={this.newTaskOnChange}
                  onSaveEditorImage={this.onSaveEditorImage}
                  submit={this.newTaskSubmit}
                  setOpen={() => this.setState({ isOpen: true })}
                  errors={this.state.errors}
                  isImageEditable={true}
                />
              </Modal>
            )}
          </div>
        </div>
      </div>
    );
  }
}
