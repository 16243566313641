import React, { Component } from "react";
import moment from "moment";
import Lightbox from "react-image-lightbox";
import { Link } from "react-router-dom";
import Alert from "react-s-alert";

import { PageHeader, PageHeaderActions, PageHeaderTitle } from "../../component/general/page-header";
import { TaskEdit } from "./Edit";
import TaskView from "./View";
import { TAKS_LIST } from "../../config/lang";

import { call } from "../../util/action";
import { getStore } from "../../util/redux/store";
import { translate } from "../../util/util";
import { dateChangeFormatFull } from "../../util/util";
import { validateReviewTask } from "../../util/util";

import "./style-task-page.scss";
import "react-image-lightbox/style.css";

const me = getStore().getState().me;
const defaultTemplate = getStore().getState().defaultTemplate;

export default class TaskPage extends Component {
  constructor(props) {
    super(props);

    this.modeTypes = { view: "view", edit: "edit" };

    this.state = {
      mode: this.props.match.params?.id ? this.modeTypes.view : this.modeTypes.edit,
      new: false,
      type: "point",
      item: {
        images: [],
        company: null,
        title: "",
        start: new Date(),
        end: new Date(new Date().setHours(23, 59, 59)),
        tags: [],
        components: [],
        priority: 2,
        template: "",
      },
      photoIndex: 0,
      isOpen: false,
      answers: [],
      errors: {},
    };
  }

  componentDidMount() {
    const { id, type } = this.props.match.params;

    const { pathname, hash } = this.props.location;

    if (id) {
      let mode = this.modeTypes.view;
      if (~hash.indexOf(this.modeTypes.edit)) {
        mode = this.modeTypes.edit;
      }
      this.setState({ mode });
    }

    if (type === "point" || type === "network") {
      this.setState(prevState => {
        return {
          mode: this.modeTypes.edit,
          new: true,
          type,
          item: {
            ...this.state.item,
            end: dateChangeFormatFull(prevState.item.end, 14),
          },
        };
      });
    } else {
      if (id) {
        {
          this.load(id);
        }
      }
    }
  }
  submit = () => {
    let { item } = this.state;

    let itemPost = { ...item };
    itemPost.components = item.components?.map(i => i.id);
    itemPost.images = item.images?.map(i => i?.id);

    itemPost.network = item?.network?.id || null;
    itemPost.point = item?.point?.id || null;
    itemPost.user = itemPost?.user?.id || me?.id || null;
    itemPost.created_by = itemPost.user; // save both fields to compatibility
    itemPost.is_active = true;
    itemPost.start = item.new ? new Date() : moment(item.start).format("yyyy-MM-DDT00:00");
    itemPost.end = moment(itemPost.end).format("yyyy-MM-DDT23:59:59");
    itemPost.company = me?.company?.id || null;
    itemPost.template = itemPost.template === "" ? defaultTemplate : itemPost.template;
    const { validate, errors } = validateReviewTask("task", itemPost);

    if (!validate) {
      this.setState({ errors });

      return;
    }

    const url = item?.id ? `tasks/${item.id}/` : "tasks/";
    // const url = item?.id ? `tasks/${item.id}/` : "tasks/bulk_create/";
    const method = item?.id ? "PATCH" : "POST";

    call({ method, url, post: itemPost }, response => {
      if (response?.id) {
        Alert.success(translate(TAKS_LIST, "date_saved", me?.language));

        if (this.state.new) {
          window.location.replace("/#/task/" + response.id);
          document.location.reload();
        }
      } else {
        Alert.warning("Error while saving item. Error: " + JSON.stringify(response), { position: "top" });
        console.log(response);
      }
    });
  };

  loadAnswers = id => {
    call({ method: "GET", url: "answers/?task=" + id }, response => {
      const answersWithDefaultTemplate = response.results?.map(el => ({
        ...el,
        task: {
          ...el.task,
          template: "",
        },
      }));
      this.setState({ answers: answersWithDefaultTemplate });
    });
  };

  load = id => {
    call({ method: "GET", url: "tasks/" + id + "/" }, r => {
      let type = "point";

      if (r.point) type = "point";
      if (r.network) type = "network";

      this.loadAnswers(id);
      this.setState({ item: r, type });
    });
  };

  removeTask = () => {
    const { history } = this.props;
    call({ method: "DELETE", url: "tasks/" + this.props.match.params?.id + "/" }, r => {
      history.push("/task/");
    });
  };

  onChange = (property, value) => {
    let item = this.state.item;
    item[property] = value;
    this.setState({ item });
  };

  render() {
    let { item, photoIndex, isOpen, type, answers } = this.state;
    let images = item?.images?.map(i => i.image) || [];

    return (
      <div className="container-fluid single-task-container">
        {/*<PageHeader>
          <PageHeaderTitle>
            <span className="faIcon">
              <i className="fa fa-tasks" />
            </span>
            {this.state.new ? "Нове завдання на " + (type === "point" ? "точку" : "мережу") : "Перегляд завдання на " + (type === "point" ? "точку" : "мережу")}
          </PageHeaderTitle>

          <PageHeaderActions>
            <Link className="btn btn-primary" to={"/task/"}>
              <i className="fa fa-arrow-left" /> Назад
            </Link>
          </PageHeaderActions>
        </PageHeader>*/}

        <div className="task-container">
          <div className="row task-header">
            <div className="col-md-8 d-flex align-items-center">
              <h1>{translate(TAKS_LIST, "task_details_title", me?.language)}</h1>
            </div>
            <div className="col-md-4 text-right">
              {!this.state.new && (
                <div className="btn btn-outline-primary d-inline-block" onClick={this.removeTask}>
                  <i className="fa fa-remove" /> {translate(TAKS_LIST, "remove_btn", me?.language)}
                </div>
              )}
              <Link className="btn btn-primary ml-2" to={"/task/"}>
                <i className="fa fa-arrow-left" /> {translate(TAKS_LIST, "back_btn", me?.language)}
              </Link>
            </div>
          </div>

          {this.state.mode === this.modeTypes.view ? (
            <TaskView item={item} type={type} setOpen={() => this.setState({ isOpen: true })} answers={answers} loadTask={this.load} />
          ) : (
            <TaskEdit
              item={item}
              type={type}
              onChange={this.onChange}
              submit={() => this.submit()}
              setOpen={() => this.setState({ isOpen: true })}
              isImageEditable={true} // TODO remove after tests
              errors={this.state.errors}
            />
          )}

          {isOpen && (
            <Lightbox
              mainSrc={images[photoIndex]}
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() => this.setState({ photoIndex: (photoIndex + images.length - 1) % images.length })}
              onMoveNextRequest={() => this.setState({ photoIndex: (photoIndex + 1) % images.length })}
            />
          )}
        </div>
      </div>
    );
  }
}
