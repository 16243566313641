import * as React from "react"; // , { useLayoutEffect, useRef }
import _ from "lodash";

type Props = {
  loading: boolean;
  onScroll?: () => void;
  scrollContainer?: Window | Node | Element;
};
export const LazyLoading = ({ onScroll, loading, scrollContainer = window }: Props) => {
  const lazyLoadingElementRef = React.useRef<HTMLDivElement>(null);

  const isInViewport = (element: HTMLDivElement) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  const handleScrollElement = () => {
    if (lazyLoadingElementRef.current) {
      if (isInViewport(lazyLoadingElementRef.current) && !loading) {
        onScroll?.();
      }
    }
  };

  const debouncedHandleScrollElement = _.debounce(handleScrollElement, 300);

  React.useLayoutEffect(() => {
    scrollContainer.addEventListener("scroll", debouncedHandleScrollElement);

    return () => {
      scrollContainer?.removeEventListener("scroll", debouncedHandleScrollElement);
    };
  }, [loading]);

  return <div className="lazy-loading-container" style={{ flex: "none", height: "12px", width: "100%" }} ref={lazyLoadingElementRef} />;
};
