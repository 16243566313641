
export const isValidUrl = urlString => {
  urlString = urlString ? urlString : "NEW ITEM NOT EXIST";
  try {
    new URL(urlString);
    return true;
  } catch (err) {
    console.error("Error on getting photo url", err);
    return false;
  }
};
