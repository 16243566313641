import React, { Component } from "react";
import { TableHeaderColumn } from "react-bootstrap-table";

import EntityTable from "../../component/EntityTable";
import { PageHeader, PageHeaderTitle } from "../../component/general/page-header";
import { TAKS_IMPORT } from "../../config/lang";

import { openPoint, networkName, pointState, locality } from "../../util/formatter";
import { translate } from "../../util/util";

class List extends Component {
  constructor(props) {
    super(props);

    this.state = {
      valid: false,
    };
  }

  componentDidMount = () => {
    this.userValidate();
  };

  userValidate = () => {
    const { currentUser } = this.props;
    if (currentUser.role === "supervisor_manager") this.setState({ valid: true });
  };
  render() {
    const { currentUser } = this.props;
    return (
      <div className="container-fluid">
        <div className="box p-4">
          {this.state.valid ? (
            <EntityTable entity="point">
              <TableHeaderColumn dataField="id" dataFormat={openPoint} width="120px" isKey></TableHeaderColumn>
              <TableHeaderColumn dataFormat={networkName}>{translate(TAKS_IMPORT, "network_label", currentUser.language)}</TableHeaderColumn>
              <TableHeaderColumn dataField="name">{translate(TAKS_IMPORT, "name_title", currentUser.language)}</TableHeaderColumn>
              <TableHeaderColumn dataField="address">{translate(TAKS_IMPORT, "addresses_label", currentUser.language)}</TableHeaderColumn>
              <TableHeaderColumn dataField="code">{translate(TAKS_IMPORT, "code_label", currentUser.language)}</TableHeaderColumn>
              <TableHeaderColumn dataFormat={locality} dataField="locality">
                {translate(TAKS_IMPORT, "location_label", currentUser.language)}
              </TableHeaderColumn>
              <TableHeaderColumn dataFormat={pointState}>{translate(TAKS_IMPORT, "status", currentUser.language)}</TableHeaderColumn>
            </EntityTable>
          ) : (
            <>
              <PageHeader>
                <PageHeaderTitle>
                  <span className="faIcon">
                    <i className="fa fa-map-marker" />
                  </span>
                  Page Not Found
                </PageHeaderTitle>
              </PageHeader>
              <a href="/" className="btn btn-primary mt-5 ml-4">
                {translate(TAKS_IMPORT, "back_btn", currentUser.language)}
              </a>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default List;
