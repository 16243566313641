import React, { Component } from "react";

import { WEEK } from "../../config/const.js";
import { dateStart, dateEnd } from "../../util/formatter";

class View extends Component {
  render() {
    let { item } = this.props;

    return (
      <div className="row m-t">
        <div className="col-sm-12">
          <div className="clear m-b">
            <h4 className="m-0 m-b-xs">{item && item.title}</h4>
            <h4 className="m-0 m-b-xs">{item && item.subtitle}</h4>
            <p className="text-muted">
              <small>
                <i className="fa fa-calendar m-r-xs" />
                Star: {item && dateStart("", item)}
              </small>{" "}
              <br />
              <small>
                <i className="fa fa-calendar m-r-xs" />
                End: {item && dateEnd("", item)}
              </small>{" "}
              <br />
              <span className="m-r">Day of the week: {item && WEEK[item.day_of_the_week]}</span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default View;
