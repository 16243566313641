import React, { Component } from 'react'

import HeaderAvatar from './HeaderAvatar'

export default class Header extends Component {
  render() {
    let {me}=this.props;
    return (
      <div className='app-header box-shadow-z4 navbar-md white'>
        <div className='navbar navbar-toggleable-sm flex-row align-items-center'>
          <a data-toggle='modal' data-target='#aside' className='d-lg-none m-2'><i className='fa fa-bars' /></a>
          <div className='my-auto'>
              <span className="headerAvatarCompany ml-3">{me && me.company && me.company.name && me.company.name}</span>
          </div>
         {this.props.me&&<HeaderAvatar />}
        </div>
      </div>
    )
  }
}
