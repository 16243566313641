import React, { useEffect, useRef } from "react";

import Modal from "../../../component/Modal";
import MapIcon from "../../../../assets/image/marker-icon.png";

// import MapRender from "../../dashboard/_/map_block/Map";
function Map({ location, zoomLevel, isModal, modalClose }) {
  const mapRef = useRef(null);
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://unpkg.com/leaflet@1.9.4/dist/leaflet.js";
    script.integrity = "sha256-20nQCchB9co0qIjJZRGuk2/Z9VM+kNiyxNV1lvTlZBo=";
    script.crossOrigin = "";
    script.async = true;

    script.onload = () => {
      if (!mapRef.current) {
        mapRef.current = window.L.map("map-container").setView([location.lat, location.lng], zoomLevel);

        window.L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        }).addTo(mapRef.current);

        const myIcon = window.L.icon({
          iconUrl: MapIcon,
          iconSize: [25, 41],
        });

        window.L.marker([location.lat, location.lng], { icon: myIcon }).addTo(mapRef.current);
      }
    };

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [location]);
  
  return (
    <>
      <Modal isShow={isModal} onClose={modalClose}>
        <div id="map-container" style={{ height: "600px", width: "100%" }}></div>
      </Modal>
    </>
  );
}

export default Map;
