import React, { useState } from "react";

function PhotoTaskSlider({ photos, activePhotoIndex, showBigImg }) {
  const [slideIndex, setSlideIndex] = useState(1);
  const nextButtonHandler = () => {
    if (slideIndex < photos.length) {
      setSlideIndex(prev => prev + 1);
      if (activePhotoIndex instanceof Object) activePhotoIndex(slideIndex);
    }
  };

  const prevButtonHandler = () => {
    if (slideIndex > 1) {
      setSlideIndex(prev => prev - 1);
      if (activePhotoIndex instanceof Object) activePhotoIndex(slideIndex - 2);
    }
  };

  return (
    <>
      <div className="dot-list">
        {photos?.map((el, index) => (
          <span key={index} className={`dot ${index === slideIndex - 1 ? "active" : ""}`}></span>
        ))}
      </div>
      <div className="photo-list">
        {photos?.map((photo, index) => {
          return (
            <img
              key={index}
              src={photo.photo_url}
              data-index={index}
              onClick={showBigImg}
              style={{ transform: `translateX(-${index * 100}%)` }}
              className={`previewImage img-placeholder ${index === slideIndex - 1 ? "active" : ""}`}
            />
          );
        })}
      </div>
      {photos?.length > 0 && (
        <div className="carousel-navigations">
          <button className="slide-arrow slide-arrow-prev" onClick={prevButtonHandler}>
            <i className="fa fa-chevron-circle-left"></i>
          </button>
          <button className="slide-arrow slide-arrow-next" onClick={nextButtonHandler}>
            <i className="fa fa-chevron-circle-right"></i>
          </button>
        </div>
      )}
    </>
  );
}

export default PhotoTaskSlider;
