import React, { Component } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";

import { call } from "../util/action";
import { maptoSelect2Objects } from "../util/util";
import { EntityDropDownSettings } from "../config/config";

export default class ReactSelect extends Component {
  static defaultProps = {
    async: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      list: [],
      searchTerm: "",
      selectedOption: null,
    };

    this.customStyles = {
      menu: () => ({
        boxShadow: "none",
        position: "absolute",
        zIndex: 99,
        top: "100%",
        width: "100%",
      }),
      menuList: () => ({
        background: "#fff",
        maxHeight: "260px",
        overflow: "auto",
        border: "1px solid #eee",
      }),
    };
  }

  componentDidMount() {
  }


  formatValue = value => {
    if (this.props.isMulti) {
      return value?.map(i => {
        return { ...i, value: i.id, label: i.name };
      });
    }

    let r = this.state.list.find(o => {
      return o.id === value;
    });

    if (r) {
      let label = r.name ? r.name : "";
      if (this.props.entity == "merchandiser") label = r.first_name + " " + r.last_name;
      if (this.props.entity == "supervisor") label = r.first_name + " " + r.last_name;
      if (this.props.entity == "point") label = r.name + ", " + r.locality.name;

      return { value: r.id, label: label };
    } else {
      return null;
    }
  };

  asynchLoadOptions = inputValue => {
    return new Promise(resolve => {
      let url = this.props.entity + "s/?search=" + inputValue; // s in"s/?search=" - it is just a simple letter "s" was added to entity name, i.e: "product" + "s" = "products" ://

      if (this.props.county && this.props.entity == "point") {
        url = "points/?locality__county=" + this.props.county + "&search=" + inputValue;
      }

      call({ method: "GET", url: url }, r => {
        let list = r.results;
        if (this.props.entity == "template") {
          list = r;
        }

        list = maptoSelect2Objects(list, this.props.entity);
        this.setState({
          list,
        });

        resolve(list);
      });
    });
  };

  onAsynchInputChange = newValue => {
    // this.asynchLoadOptions(newValue);
    // this.setState({ searchTerm: newValue });

    return newValue;
  };

  handleChange = selectedOption => {
    this.setState({ selectedOption });
    this.props.onChange?.(selectedOption);
  };

  render() {
    const { selectedOption } = this.state;
    let className = this.props.className ? this.props.className : "";

    return (
      <div className={`react-dropdown-container ${className}`}>
        {!!this.props.async ? (
          <AsyncSelect
            cacheOptions
            defaultOptions
            action={["clear"]}
            value={this.formatValue(this.props.value) || null}
            loadOptions={this.props.options}
            onInputChange={this.onAsynchInputChange}
            placeholder={this.props.placeholder ? this.props.placeholder : EntityDropDownSettings[this.props.entity].selectTitle}
            onChange={this.props.onChange}
            isClearable={this.props.isClearable ?? true}
            isDisabled={this.props.isDisabled}
            styles={this.customStyles}
          />
        ) : (
          <Select
            options={this.props.options}
            isMulti={this.props.isMulti}
            value={selectedOption}
            placeholder={this.props.placeholder ? this.props.placeholder : EntityDropDownSettings[this.props.entity]?.selectTitle}
            onChange={this.handleChange}
            isClearable={this.props.isClearable ?? true}
            isDisabled={this.props.isDisabled}
            styles={this.customStyles}
          />
        )}
      </div>
    );
  }
}

ReactSelect.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  async: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object), //  | PropTypes.func
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
};
