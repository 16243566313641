import React from 'react'
import './user.css'
import {Status} from "./../map_block/Status";


export const User = ({item}) =>{
    return(
        <div className="user" key={item.id + item.name}>
            <div className='avatar'><img src={item.avatar} alt=""/></div>
            <div><p className='user_name'>{item.name}</p></div>
            <div><p className='location'>{item.location}</p></div>
            <div><p className=''>{item.time_start}</p></div>
            <div><p>{item.time_end}</p></div>
            <Status status={item.status} />
            <div><p>{item.num1}</p></div>
            <div><p>{item.num2}</p></div>
        </div>
    )
}