import React, { Component } from "react";

import { call } from "../../util/action";
import { PageHeader, PageHeaderTitle } from "../../component/general/page-header";

class View extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
    };
  }
  componentDidMount() {
    this.load();
  }

  load = () => {
    call({ method: "GET", url: "reports/" }, r => {
      console.log(r);
      this.setState({ list: r });
    });
  };

  render() {
    return (
      <div className="container-fluid">
        {/*<PageHeader>
          <PageHeaderTitle>
            <span className="faIcon">
              <i className="fa fa-bar-chart" />
            </span>
            BI звіти
          </PageHeaderTitle>
        </PageHeader>*/}
        <div className="box p-4">
          <div className="row">
            {this.state.list.map((i, j) => {
              return (
                <div className="col-md-12 bi-report">
                  <h4>{i.name}</h4>
                  <p>
                    <iframe src={i.url} width="100%" />
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default View;
