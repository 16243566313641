import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { getStore } from "./util/redux/store";
import { User } from "./util/permission";
import { Layout } from "./layout/Layout";

// PAGES
import Login from "./module/auth/Login";
import ForgotPassword from "./module/auth/ForgotPassword";

import Dashboard from "./module/dashboard/View";
import Profile from "./module/profile/Edit";

import MerchandicerList from "./module/merchandiser/List";
import MerchandicerView from "./module/merchandiser/Page";

import RouteList from "./module/route/List";
import RouteView from "./module/route/Page";

import { TaskList } from "./module/task/List";
import TaskPage from "./module/task/Page";

import TaskReviewList from "./module/task-review/View";
import PhotoReviewPage from "./module/photo-review/View";

import VisitList from "./module/visit/List";
import VisitView from "./module/visit/View";

import PointList from "./module/point/List";
import PointView from "./module/point/View";
import KForm from "./module/k-form/View";
import BiReport from "./module/bi-report/View";
import Document from "./module/document/View";
import TasksImport from "./module/tasks-import/component";

const PrivateRoute = ({ component: Component, ...rest }) => {
  let me = getStore().getState().me;

  return <Route {...rest} render={props => (me ? <Component {...props} {...rest} /> : <Redirect to="/login" />)} />;
};

const AppRouter = () => {
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/forgot-password" component={ForgotPassword} />

      <Layout>
        <PrivateRoute exact path="/">
          <Redirect to="/merchandiser" />
        </PrivateRoute>
        <PrivateRoute exact path="/dashboard" component={User(Dashboard)} />
        <PrivateRoute exact path="/profile" component={User(Profile)} />

        {/*MERCHANDISER*/}
        <PrivateRoute exact path="/merchandiser" component={User(MerchandicerList)} />
        <PrivateRoute exact path="/merchandiser/:id" component={User(MerchandicerView)} />

        {/*TASK*/}
        <PrivateRoute exact path="/task" component={User(TaskList)} />

        <PrivateRoute exact path="/task/new/:type" component={User(TaskPage)} />
        <PrivateRoute exact path="/task/:id" component={User(TaskPage)} />
        <PrivateRoute exact path="/task/:id/edit" component={User(TaskPage)} />
        <PrivateRoute exact path="/task/:id/view" component={User(TaskPage)} />

        {/*  TASK REVIEW */}
        <PrivateRoute exact path="/task-review" component={User(TaskReviewList)} />

        {/*  TASKS IMPORT */}
        <PrivateRoute exact path="/tasks-import" component={User(TasksImport)} />

        {/*  PHOTO REVIEW */}
        <PrivateRoute path="/photo-review" component={User(PhotoReviewPage)} />
        {/*<PrivateRoute exact path="/photo-review/:id" component={User(PhotoReviewPage)} />*/}
        {/*<PrivateRoute exact path="/photo-review/:id/edit" component={User(PhotoReviewPage)} />*/}
        {/*<PrivateRoute exact path="/photo-review/:id/view" component={User(PhotoReviewPage)} />*/}

        {/* DOCUMENTS */}
        <PrivateRoute exact path="/document" component={User(Document)} />

        {/* KAM FORMS */}
        <PrivateRoute exact path="/k-form" component={User(KForm)} />

        {/* BI-REPORT */}
        <PrivateRoute exact path="/bi-report" component={User(BiReport)} />

        {/*ROUTE*/}
        <PrivateRoute exact path="/route" component={User(RouteList)} />
        <PrivateRoute exact path="/route/:id" component={User(RouteView)} />

        {/*VISIT */}
        <PrivateRoute exact path="/visit" component={User(VisitList)} />
        <PrivateRoute exact path="/visit/:id" component={User(VisitView)} />

        {/*POINT*/}
        <PrivateRoute exact path="/point" component={User(PointList)} />
        <PrivateRoute exact path="/point/:id" component={User(PointView)} />
      </Layout>
    </Switch>
  );
};

export default AppRouter;
