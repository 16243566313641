import React from "react";

import { TAKS_LIST } from "../config/lang";

import { getStore } from "../util/redux/store";
import { translate } from "../util/util";

const me = getStore().getState().me;
export default class Pagination extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pager: {} };
  }

  getPager = (totalItems, currentPage, pageSize) => {
    // default to first page
    currentPage = currentPage || 1;

    // default page size is 10
    pageSize = pageSize || 10;

    // calculate total pages
    var totalPages = Math.ceil(totalItems / pageSize);

    var startPage, endPage;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    var startIndex = (currentPage - 1) * pageSize;
    var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    var pages = [...Array(endPage + 1 - startPage).keys()].map(i => startPage + i);

    // return object with all pager properties required by the view
    let pager = {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };

    this.setState({ pager: pager });
  };

  componentDidMount() {}

  componentWillReceiveProps(props) {
    const { pagination } = props;

    if (!pagination || !pagination.state) return;
    this.getPager(pagination.count, pagination.page, pagination.sizePerPage);
  }

  update = page => {
    const { pagination } = this.props;

    this.getPager(pagination.count, page, pagination.sizePerPage);

    this.props.updatePage(page, () => {});
  };

  render() {
    const { pagination } = this.props;
    const { pager } = this.state;
    if (!pagination || !pagination.state) return null;
    if (pagination.count == 0) return null;

    return (
      <div className="col-md-12">
        <ul className="pagination">
          <li className={pager.currentPage === 1 ? "disabled" : ""}>
            <a
              onClick={() => {
                if (pager.currentPage === 1) {
                  return;
                }
                this.update(1);
              }}>
              {translate(TAKS_LIST, "first_page", me.language)}
            </a>
          </li>
          <li className={pager.currentPage === 1 ? "disabled" : ""}>
            <a
              onClick={() => {
                if (pager.currentPage === 1) {
                  return;
                }
                this.update(pager.currentPage - 1);
              }}>
              {translate(TAKS_LIST, "previous_page", me.language)}
            </a>
          </li>

          {pager?.pages?.map((page, index) => (
            <li key={index} className={pager.currentPage === page ? "active" : ""}>
              <a onClick={() => this.update(page)}>{page}</a>
            </li>
          ))}

          <li className={pager.currentPage === pager.totalPages ? "disabled" : ""}>
            <a
              onClick={() => {
                if (pager.currentPage === pager.totalPages) {
                  return;
                }
                this.update(pager.currentPage + 1);
              }}>
              {translate(TAKS_LIST, "next_page", me.language)}
            </a>
          </li>
          <li className={pager.currentPage === pager.totalPages ? "disabled" : ""}>
            <a
              onClick={() => {
                if (pager.currentPage === pager.totalPages) {
                  return;
                }
                this.update(pager.totalPages);
              }}>
              {translate(TAKS_LIST, "last_page", me.language)}
            </a>
          </li>
        </ul>
      </div>
    );
  }
}
