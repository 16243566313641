import React, { Component } from "react";
import { DatePicker } from "/src/component/general/DatePicker";
import { TableHeaderColumn, BootstrapTable } from "react-bootstrap-table";

import EntityDropdown from "../../component/EntityDropdown";
import PointFilter from "../../component/PointFilter";
import Pagination from "./../../component/Pagination";
import { dataTableConfig } from "../../config/config";
import { TAKS_REVIEW } from "../../config/lang";

import { reviewTaskPoint, reviewTaskNework, reviewTaskPriority, reviewMerchandiserNamePhone, reviewTaskSupervisor } from "../../util/formatter";
import { getStore } from "../../util/redux/store";
import { translate } from "../../util/util";
import { getCurrentUser } from "../../util/util";

const me = getStore().getState().me;
class Table extends Component {
  constructor(props) {
    super(props);

    this.state = {
      valid: false,
    };
  }
  reviewOpenTask = (i, item) => {
    return (
      item && (
        <div
          onClick={() => {
            this.props.openTask(item);
          }}
          className="btn btn-sm btn-outline-primary">
          <i className="fa fa-external-link can_open" /> {translate(TAKS_REVIEW, "view_btn", me.language)}
        </div>
      )
    );
  };

  render() {
    const { data, totalDataSize, count, filter, changeFilter, submit, resetFilter } = this.props;
    return (
      <div className="row">
        <div className="col-md-12 mb-2 filter-bar boxForm">
          <div className="filter-datepicker">
            <DatePicker selected={new Date(filter.start)} className="datePicker mr-2" onChange={date => changeFilter("start", date)} />
            <DatePicker selected={new Date(filter.end)} className="datePicker mr-2" onChange={date => changeFilter("end", date)} />
          </div>
          <EntityDropdown
            entity="locality"
            value={filter.visit__point__locality}
            onChange={o => {
              changeFilter("visit__point__locality", o);
            }}
          />

          {getCurrentUser() ? (
            <EntityDropdown
              entity="supervisor"
              value={filter.visit__point__responsible_supervisor}
              onChange={o => {
                changeFilter("visit__point__responsible_supervisor", o);
              }}
            />
          ) : null}

          <EntityDropdown
            entity="network"
            value={filter.visit__point__network}
            onChange={o => {
              changeFilter("visit__point__network", o);
            }}
          />
          <EntityDropdown
            entity="template"
            value={filter.task__template}
            asynch={true}
            onChange={o => {
              changeFilter("task__template", o);
            }}
          />

          <EntityDropdown
            entity="merchandiser"
            value={filter.visit__user}
            onChange={o => {
              changeFilter("visit__user", o);
            }}
          />

          <PointFilter
            value={filter.visit__point}
            onChange={o => {
              changeFilter("visit__point", o);
            }}
            moduleFilter={filter.visit__point__network}
          />

          <div className="text-center">
            <div
              className="btn btn-primary mr-1 mb-1"
              onClick={() => {
                submit();
              }}>
              {translate(TAKS_REVIEW, "apply_btn", me.language)}
            </div>
            <div
              className="btn btn-outline-primary mb-1"
              onClick={() => {
                resetFilter();
              }}>
              {translate(TAKS_REVIEW, "reset_filters_btn", me.language)}
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <span>
            {translate(TAKS_REVIEW, "records_count", me.language)}: {this.props.count}
          </span>
        </div>

        <div className="col-md-12">
          <BootstrapTable
            data={data}
            version="4"
            striped
            hover
            bordered={false}
            pagination={false}
            search={false}
            multiColumnSearch={true}
            options={{ ...dataTableConfig }}
            fetchInfo={{ dataTotalSize: totalDataSize }}
            className="themed-main-table-container"
            tableHeaderClass="primary">
            <TableHeaderColumn dataField="id" dataFormat={this.reviewOpenTask} width="120px" isKey></TableHeaderColumn>
            <TableHeaderColumn dataFormat={reviewTaskPoint}>{translate(TAKS_REVIEW, "point", me.language)}</TableHeaderColumn>
            <TableHeaderColumn dataFormat={reviewTaskNework}>{translate(TAKS_REVIEW, "network", me.language)}</TableHeaderColumn>
            <TableHeaderColumn dataFormat={reviewTaskPriority}>{translate(TAKS_REVIEW, "priority", me.language)}</TableHeaderColumn>
            <TableHeaderColumn dataFormat={reviewMerchandiserNamePhone}>{translate(TAKS_REVIEW, "merchandiser", me.language)}</TableHeaderColumn>
            <TableHeaderColumn dataFormat={reviewTaskSupervisor}>{translate(TAKS_REVIEW, "responsible_supervisor", me.language)}</TableHeaderColumn>
          </BootstrapTable>
        </div>

        <div className="col-md-12">
          <span>
            {translate(TAKS_REVIEW, "records_count", me.language)}: {this.props.count}
          </span>
        </div>

        <Pagination pagination={this.props.pagination} updatePage={this.props.updatePage} />
      </div>
    );
  }
}

export default Table;
