import React from 'react'
import Map from "./Map";
import './map_block.css'

const location = {
    address: 'вул. Хрещатик 23',
    lat: 50.437808,
    lng: 30.521208
};

export const MapBlock = () =>{
    return(
        <div className="default_card map_block">
            <h5>Map</h5>
            <div className='location_list'>
                <div className="location_item"></div>
                <div className="location_item"></div>
                <div className="location_item"></div>
                <div className="location_item"></div>
                <div className="location_item"></div>
                <div className="location_item"></div>
            </div>
            <div className='map_selected'>
                <p>selected:</p>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <Map location={location} zoomLevel={13} />
        </div>
    )
}