import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Alert from "react-s-alert";

import { PageHeader, PageHeaderActions, PageHeaderTitle } from "../../component/general/page-header";
import Loading from "../../component/Loading";
import Modal from "./../../component/Modal";
import Table from "./Table";
import { TaskEdit } from "./../task/Edit";
import { TaskReviewDetails } from "./TaskReviewDetails";
import { TAKS_REVIEW } from "../../config/lang";

import { call } from "../../util/action";
import { getStore } from "../../util/redux/store";
import { translate } from "../../util/util";
import { filterUrl, validateReviewTask, urlToIdPhoto, urlToIdPhotoAsync } from "./../../util/util";

const defaultTemplate = getStore().getState().defaultTemplate;

class View extends Component {
  constructor(props) {
    super(props);

    this.state = {
      VIEW: "LIST",
      item: {},
      newTask: {},
      activePhotoIndex: 0,

      currentIndex: 0,
      data: [],
      count: 0,

      isModal: false,
      loading: false,

      pagination: {
        state: false,
        count: 0,
        sizePerPage: 50,
        pageAmount: 1,
        page: 1,
      },

      filter: {
        start: new Date(),
        end: new Date(),
        visit__point__locality: "",
        visit__point__network: "",
        visit__point: "",
        visit__user: "",
        task__template: "",
        visit__point__responsible_supervisor: "",
      },
      newTaskLoading: false,
      errors: {},
    };
  }

  setStartDate = () => {
    var start = new Date();
    start = start.setDate(start.getDate() - 7);
    let filter = this.state.filter;
    filter.start = start;
    this.setState({ filter: filter }, () => {
      this.load();
    });
  };

  componentDidMount = () => {
    this.setStartDate();
  };

  load = () => {
    let { pagination } = this.state;

    let url = "answers/photos_on_review/?page=" + pagination.page;
    let urlFilter = filterUrl("answers/photos_on_review", this.state.filter);
    if (urlFilter) url += urlFilter;

    call({ method: "GET", url: url }, r => {
      const newPagination = {
        state: true,
        pageAmount: Math.ceil(r.count / pagination.sizePerPage),
        count: r.count,
        page: pagination.page,
        sizePerPage: pagination.sizePerPage,
      };

      let list = r.results.map((i, j) => {
        i.index = j;
        return i;
      });
      this.setState({ data: list, count: r.count, loading: false, pagination: newPagination });
    });
  };

  submit = () => {
    this.updatePaging(1);
  };

  changeFilter = (filterEntity, item) => {
    let filter = this.state.filter;

    if (filterEntity == "start" || filterEntity == "end") {
      filter[filterEntity] = item;
    } else {
      filter[filterEntity] = item?.id;
    }

    this.setState({ filter });
  };

  resetFilter = () => {
    let { filter } = this.state;

    Object.keys(filter).map(i => {
      filter[i] = null;
      if (i == "start") filter[i] = new Date();
      if (i == "end") filter[i] = new Date();
    });

    this.setState({ filter: filter }, () => {
      this.setStartDate();
      this.load();
    });
  };

  openTask = item => {
    this.setState({ VIEW: "VIEW", item: { ...item, task: { ...item.task, template: "" } } });
  };

  back = () => {
    if (this.state.VIEW == "VIEW") this.setState({ VIEW: "LIST" });
    if (this.state.VIEW == "NEW") this.setState({ VIEW: "VIEW" });
    this.setState({ activePhotoIndex: 0 });
  };

  callUpdateAnswer = (item, body, cb) => {
    call({ method: "PATCH", url: "answers/" + item.id + "/", post: body }, r => {
      Alert.success("Відповідь збережена");
      this.setState({ item: r });
      this.load();
      cb();
    });
  };

  acceptRejectAnswer = type => {
    let { item } = this.state;

    let body = { id: item.id };
    body.supervised_by = this.props.me.id;

    if (type == "REJECT") {
      body.is_supervised = false;
      this.callUpdateAnswer(item, body, () => {
        this.moveNext();
      });
    }

    if (type == "ACCEPT") {
      body.is_supervised = true;
      this.callUpdateAnswer(item, body, () => {
        this.moveNext();
      });
    }
  };
  activePhotoIndex = (index = 0) => {
    this.setState({ activePhotoIndex: index });
    return index;
  };
  passTaskToModal = () => {
    let { item } = this.state;
    const newTask = Object.assign({}, item.task);
    let date = new Date(newTask.end);

    newTask.start = new Date();
    newTask.end = new Date(new Date().setDate(new Date().getDate() + 14));
    const photo = item.photos.length > 0 ? item.photos[this.state.activePhotoIndex].photo_url : item.photo;
    if (photo) {
      newTask.images = [{ image: photo }];
      newTask.imageFromReview = { image: photo };
    }

    this.setState({ newTask, isModal: true });
  };

  onSaveEditorImage = (index, image) => {
    const { newTask } = this.state;

    if (!image) return;

    call({ method: "POST", url: "images/", post: { owner: this.props.me, image } }, image => {
      if (image?.id) {
        this.setState({ newTask: { ...newTask, images: [image] } });
      }
    });
  };

  newTaskSubmit = () => {
    let { newTask } = this.state;
    let queueLoadingTimer = 0;
    let queueLoadingStep = 8000;

    let itemPost = Object.assign({}, newTask);
    itemPost.id = null; // we always create new task if use Reject button on task-review
    itemPost.components = newTask.components.map(i => i.id);
    itemPost.tags = newTask.tags?.map(i => i.id);
    itemPost.network = newTask?.network?.id || null;
    itemPost.point = newTask?.point?.id || null;
    itemPost.end = moment(itemPost.end).format("yyyy-MM-DDT23:59:59");
    itemPost.images = newTask.images.map(i => (i.id ? i.id : i));
    itemPost.created_by = newTask.created_by?.id || null;
    itemPost.template = itemPost.template === "" ? defaultTemplate : itemPost.template;

    const { validate, errors } = validateReviewTask("task-review", itemPost);

    if (!validate) {
      this.setState({ errors });

      return;
    }

    if (itemPost.images.every(image => image.id)) {
      itemPost.images = newTask.images?.map(image => image.id);
      this.createNewTask(itemPost);
    } else {
      this.setState({
        newTaskLoading: true,
      });

      itemPost.images = newTask.images?.map((image, index) => {
        if (image.id) {
          itemPost.images = [image.id];
          this.createNewTask(itemPost);
          this.setState({
            itemPost,
            newTaskLoading: false,
          });
          this.modalClose();
          return image.id;
        } else {
          setTimeout(args => {
            urlToIdPhotoAsync(image.image, this.props.me, createdImg => {
              if (createdImg.id) {
                itemPost.images.push(createdImg.id);
                if (index === newTask.images.length - 1) {
                  itemPost.images = itemPost.images?.filter(item => !!item);
                  this.createNewTask(itemPost);
                  this.setState({
                    itemPost,
                    newTaskLoading: false,
                  });
                  this.modalClose();
                }
              } else {
                console.error("image was not created");
              }
            });
          }, queueLoadingTimer);
          queueLoadingTimer += queueLoadingStep;
        }
      });
    }
  };

  createNewTask = data => {
    if (!data) return;

    call({ method: "POST", url: "tasks/", post: data }, response => {
      if (response?.id) {
        Alert.success("Завдання збережене");
        this.setState({ VIEW: "VIEW", errors: {} });
        this.modalClose();
        this.acceptRejectAnswer("REJECT");
      } else {
        Alert.warning("Error while saving item. Error: " + JSON.stringify(response));
        console.log(response);
      }
    });
  };

  moveNext = (DIRECTION = +1) => {
    let { currentIndex, data, count } = this.state;

    if (count === 0) {
      this.setState({ VIEW: "LIST" });
      return;
    }

    let newIndex = 0;
    if (currentIndex + DIRECTION < count) {
      newIndex = currentIndex + DIRECTION;
    } else {
      newIndex = 0;
    }
    if (newIndex == -1 && DIRECTION == -1) newIndex = count - 1;

    if (!data[newIndex]) {
      console.log("NEW ITEM NOT EXIST. newIndex", newIndex);

      return;
    }

    let newItem = data[newIndex];
    newItem["template"] = "";

    this.setState({ currentIndex: newIndex, item: newItem });
  };

  modalOpen = () => {
    this.setState({ isModal: true });
  };

  modalClose = () => this.setState({ isModal: false, newTask: {} });

  newTaskOnChange = (property, value) => {
    let item = this.state.newTask;
    item[property] = value;
    this.setState({ newTask: item });
  };

  // PAGINATION
  updatePaging = (page, cb) => {
    let { pagination } = this.state;
    pagination.page = page;

    this.setState({ pagination: pagination }, () => {
      this.load();
      if (cb) cb();
    });
  };

  render() {
    const { isModal, VIEW, newTaskLoading } = this.state;

    return (
      <div className="container-fluid" onKeyDown={this.handleKeyDown}>
        <PageHeader>
          <PageHeaderTitle>
            {/*<span className="faIcon">
              <i className="fa fa-list" />
            </span>
            {VIEW == "LIST" && <span>Відповіді</span>}
            {VIEW == "VIEW" && <span>Перегляд відповіді</span>}*/}
          </PageHeaderTitle>
          <PageHeaderActions>
            {VIEW == "VIEW" && (
              <div className="btn btn-primary" onClick={this.back}>
                <i className="fa fa-arrow-left" /> {translate(TAKS_REVIEW, "import_tab", this.props.me.language)}
              </div>
            )}
          </PageHeaderActions>
        </PageHeader>

        <div className="box p-4">
          {VIEW == "LIST" && (
            <Table
              {...this.state}
              changeFilter={this.changeFilter}
              resetFilter={this.resetFilter}
              submit={this.submit}
              openTask={this.openTask}
              pagination={Object.assign({}, this.state.pagination)}
              updatePage={page => {
                this.updatePaging(page);
              }}
            />
          )}

          {VIEW == "VIEW" && (
            <TaskReviewDetails
              {...this.state}
              acceptRejectAnswer={this.acceptRejectAnswer}
              passTaskToModal={this.passTaskToModal}
              activePhotoIndex={this.activePhotoIndex}
              moveNext={this.moveNext}
            />
          )}

          {isModal && (
            <Modal isShow={isModal} onClose={this.modalClose}>
              <Loading loading={newTaskLoading} disP={true} type="spinner" />
              <TaskEdit
                item={this.state.newTask}
                type="point"
                newTaskFromReview
                onChange={this.newTaskOnChange}
                onSaveEditorImage={this.onSaveEditorImage}
                submit={this.newTaskSubmit}
                setOpen={() => this.setState({ isOpen: true })}
                errors={this.state.errors}
                isImageEditable={true}
              />
            </Modal>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(connect(state => ({ me: state.me }))(View));
