import React, { Component } from "react";
import LogoC from "/assets/image/logo.svg";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Alert from "react-s-alert";

import { USER_LOGIN } from "../../config/lang";

import { auth } from "../../util/action";
import { login } from "../../util/redux/actions";
import { translate } from "../../util/util";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phone_number: "",
      password: "",
      loading: false,
      langUpdate: false,
      errors: {},
    };
  }
  updateCount = () => {
    this.setState({ langUpdate: true });
  };

  handleKeyPress = e => {
    if (e.nativeEvent.keyCode === 13) {
      this.login();
    }
  };

  change = field => {
    this.setState(field, () => {
      this.setState({ errors: {} });
    });
  };

  login = () => {
    const { dispatch, history } = this.props;
    let { phone_number, password } = this.state;

    phone_number = phone_number.trim();
    this.setState({ loading: true });

    auth({ phone_number: phone_number, password: password })
      .then(res => {
        if (res.user) {
          if (res.user.role == "supervisor" || res.user.role == "supervisor_manager") {
            res.user.key = res.key;
            if (res.user.role == "supervisor") {
              res.user.company = res.related.company;
              res.user.client = res.related.client;
              res.user.county = res.related.county;
            }

            Alert.success(translate(USER_LOGIN, "login_success_msg", "en"));

            dispatch(login(res.user));
            history.replace("/photo-review");
            window.location.reload();
          } else {
            Alert.error("Currently just users with SUPERVISOR role allowed to enter the system");
          }
        } else {
          Alert.error(translate(USER_LOGIN, "login_error_msg", "en"));
        }
      })
      .catch(err => {
        Alert.error(String(err));
      });

    this.setState({ loading: false });
  };

  render() {
    const { phone_number, password, errors, loading } = this.state;
    const { inText } = this.props;
    return (
      <div className="padH0">
        <div className="center-block w-xxl w-auto-xs p-y-md regBox regBoxChangigMargin">
          <div className="logo_wrapper">
            {" "}
            <img src={LogoC} className="logo_register" />{" "}
          </div>
          <div className="p-a-md box-color r box-shadow-z1 text-color m-a">
            <div className="m-b text-sm text-center regBoxText">Вхід до PicSell Dashboard</div>
            {/*Login to PicSell Dashboard*/}

            <form>
              <div className="md-form-group ">
                <label className={"floatingLabel"}>Телефон</label>
                {/*Phone*/}
                <input
                  type="text"
                  name="phone_number"
                  className="md-input regField  has-value"
                  onClick={this.handleKeyPress}
                  onChange={e => this.change({ phone_number: e.target.value })}
                />
                <label className={phone_number.length > 0 ? "floatingLabel" : " "}>{inText("email")}</label>
                {errors.phone_number && <span className="text-danger">{inText("error_email")}</span>}
              </div>

              <div className="md-form-group ">
                <label className={"floatingLabel"}>Пароль</label>
                {/*Password*/}
                <input
                  type="password"
                  name="password"
                  className="md-input regField has-value"
                  onKeyPress={this.handleKeyPress}
                  onChange={e => this.change({ password: e.target.value })}
                />
                <label className={password.length > 0 ? "floatingLabel" : " "}>{inText("password")}</label>
                {errors.password && <span className="text-danger">{inText("error_password")}</span>}
              </div>
            </form>

            <button type="submit" className="btn btn-block p-x-md registrationBtn mb-2" onClick={this.login}>
              Увійти {loading && <i className="fa fa-spinner fa-pulse fa-fw" />}
            </button>

            <div className="p-v-lg text-center positionAddLinks mt-3">
              <div className="m-b">
                <Link to="/forgot-password" className="additionalLink">
                  Забули пароль? {/*Forgot password?*/}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Login = connect(state => ({ me: state.me, inText: key => state.inText("login")(key) }))(Login);

export default withRouter(Login);
