import React, { Component } from "react";

import AvatarCompany from "../../../assets/image/company.png";
import { TAKS_IMPORT } from "../../config/lang";
import Map from "../dashboard/_/map_block/Map";

import { call } from "../../util/action";
import { translate } from "../../util/util";

class View extends Component {
  constructor(props) {
    super(props);

    this.state = {
      location: {},
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;

    if (id !== "new") {
      this.load(id);
    }
  }

  load = id => {
    call({ method: "GET", url: "points/" + id + "/" }, item => {
      let location = {
        address: item.address,
        lat: item.location.latitude,
        lng: item.location.longitude,
      };

      this.setState({ item: item, location: location });
    });
  };

  render() {
    let { item } = this.state;

    let imageSrc = item && item.network.image ? item.network.image : AvatarCompany;

    const { currentUser } = this.props;

    return (
      <div className="container-fluid">
        <div className="box px-4 py-1">
          <h5 className="mt-3 mb-3">
            <span className="faIcon">
              {" "}
              <i className="fa fa-map-marker" />{" "}
            </span>
            {translate(TAKS_IMPORT, "view_the_point", currentUser.language)}
          </h5>
        </div>
        <div className="box p-4">
          <div className="item taskBlock">
            <div className="p-a-md">
              <div className="row">
                <div className="col-sm-12">
                  <a href="" className="pull-center2 m-r-md">
                    <span className="avatar w-96">
                      <img src={imageSrc} />
                    </span>
                  </a>
                  <div className="clear m-b">
                    <h5 className="m-0 m-b-xs">{item && item.network && item.network.name}</h5>
                    <h4 className="m-0 m-b-xs">{item && item.name}</h4>

                    <div className="row mt-2">
                      <div className="col-md-12">
                        <div className="mt-3 mb-2">
                          {translate(TAKS_IMPORT, "addresses_label", currentUser.language)}
                          <br /> <div className="mt-3" />
                          {item && item.address}
                        </div>
                        <div className="mt-3 mb-2">
                          <i className="fa fa-map-marker m-r-xs" /> {translate(TAKS_IMPORT, "coordinates_label", currentUser.language)}
                          <br /> <div className="mt-3" />
                          {item && item.location.latitude + ", " + item.location.longitude}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="default_card map_block">
                    {item && <Map title={item && item.network && item.network.name} imageSrc={imageSrc} location={this.state.location} zoomLevel={13} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default View;
