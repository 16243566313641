import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import spinner from "./spinner.gif";
import { TRANSLATION_DOWNLOADER } from "../../../config/lang";

import { call } from "../../../util/action";
import { translate } from "../../../util/util";

import "./styles.scss";

const Downloader = props => {
  const [loading, setLoading] = useState(false);
  const [loadingAll, setLoadingAll] = useState(false);

  const TranslationDownloader = {
    export: "export",
    download: "download",
    downloadAll: "downloadAll",
    downloading: "downloading",
  };
  const translateFn = name => {
    const lang = props.me?.language ? props.me.language : "en";
    return translate(TRANSLATION_DOWNLOADER, name, lang);
  };
  const exportFiles = async (urls, exportCallback) => {
    setLoading(true);

    call(
      { method: "POST", url: "images/export/", post: { images: urls } },
      result => {
        try {
          exportCallback?.();
        } catch (e) {
          console.error({ ...e });
        }
      },
      {},
      true
    );
  };

  return (
    <div className="downloader-container">
      <button
        className={`btn ${props.options.exportMode ? "btn-primary" : "btn-outline-primary"} mr-1`}
        onClick={props.options.handleExportMode}
        disabled={!!props.options?.disabled}>
        {translateFn(TranslationDownloader.export)}
      </button>
      {props.options.exportMode && (
        <>
          <button
            className="btn btn-outline-primary mr-1"
            onClick={() => {
              setLoading(true);
              exportFiles(props.options.selectedImages, () => {
                setLoading(false);
              });
            }}
            disabled={!props.options?.selectedImages?.length || loading}>
            {loading ? (
              <>
                <img src={spinner} alt="loading spinner" className="downloader-spinner" /> {translateFn(TranslationDownloader.downloading)}
              </>
            ) : (
              translateFn(TranslationDownloader.download)
            )}
          </button>
          <button
            className="btn btn-outline-primary"
            onClick={() => {
              setLoadingAll(true);
              exportFiles(props.options.urls, () => {
                setLoadingAll(false);
              });
            }}
            disabled={loadingAll}>
            {loadingAll ? (
              <>
                <img src={spinner} alt="loading spinner" className="downloader-spinner" /> {translateFn(TranslationDownloader.downloading)}
              </>
            ) : (
              translateFn(TranslationDownloader.downloadAll)
            )}
          </button>
        </>
      )}
    </div>
  );
};

// export { Downloader };
export default withRouter(connect(state => ({ me: state.me }))(Downloader));
