import React, { Component } from "react";

import { TableHeaderColumn } from "react-bootstrap-table";
import EntityTable from "../../component/EntityTable";

import { openVisit, point, dateStart, dateEnd, merchandiserName } from "../../util/formatter";

class List extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="box px-4 py-1">
          <h5 className="mt-3 mb-3">
            {" "}
            <span className="faIcon">
              {" "}
              <i className="fa fa-tasks" />
            </span>{" "}
            Візити
          </h5>
        </div>

        <div className="box p-4">
          <EntityTable entity="visit">
            <TableHeaderColumn dataField="id" dataFormat={openVisit} isKey width="120px"></TableHeaderColumn>
            <TableHeaderColumn dataFormat={merchandiserName}>Merchandiser</TableHeaderColumn>
            <TableHeaderColumn dataFormat={point}>Point</TableHeaderColumn>
            <TableHeaderColumn dataFormat={dateStart}>Start</TableHeaderColumn>
            <TableHeaderColumn dataFormat={dateEnd}>End</TableHeaderColumn>
          </EntityTable>
        </div>
      </div>
    );
  }
}

export default List;
