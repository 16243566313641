import React, { Component } from "react";

/* Props:
  loading: boolean,
  disP: boolean,
  type: "loader" | "spinner"
*/
export default class Loading extends Component {
  render() {
    const { loading, disP = false, type = "loader" } = this.props;
    return (
      loading && (
        <div className={`data-loader${type === "spinner" ? " data-loader--spinner" : ""}`}>
          <div className={`data-loader-content text-center ${disP ? "" : "pl-5"}`}>
            <i className="fa fa-spinner fa-pulse fa-2x fa-fw" />
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )
    );
  }
}
