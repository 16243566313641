import React, { Component } from "react";
import { DatePicker } from "/src/component/general/DatePicker";
import Select from "react-select";

import Avatar from "../../../assets/image/avatar.jpg";
import { STATES_OPT, MOVEMENTS_OPT } from "../../config/const";
import { MERCHANDISER_EDITOR } from "../../config/lang";

import { translate } from "../../util/util";
import { valueObject } from "../../util/util";

class Edit extends Component {
  render() {
    let { item, me } = this.props;
    return (
      <div className="box boxEdit box-body boxForm">
        <div className="row">
          <div className="col-md-12">
            <a href="#" className="m-r-md">
              <span className="avatar avatarProfile">
                <img src={item && item.photo ? item.photo : Avatar} />
              </span>
            </a>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>{translate(MERCHANDISER_EDITOR, "name", me.language)}</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter first name"
                value={item && item.first_name}
                onChange={e => this.props.onChange("first_name", e.target.value)}
              />
            </div>

            <div className="form-group">
              <label>{translate(MERCHANDISER_EDITOR, "surname", me.language)}</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter last name"
                value={item && item.last_name}
                onChange={e => this.props.onChange("last_name", e.target.value)}
              />
            </div>

            <div className="form-group">
              <label>{translate(MERCHANDISER_EDITOR, "phone_number", me.language)}</label>
              <input
                type="text"
                className="form-control"
                value={item && item.phone_number}
                onChange={e => this.props.onChange("phone_number", e.target.value)}
              />
            </div>

            <div className="form-group">
              <label>{translate(MERCHANDISER_EDITOR, "movement", me.language)}</label>
              <Select options={MOVEMENTS_OPT} value={valueObject(item.movement, MOVEMENTS_OPT)} onChange={o => this.props.onChange("movement", o.value)} />
            </div>

            {this.props.isNew && (
              <div className="form-group">
                <label>{translate(MERCHANDISER_EDITOR, "password", me.language)}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Password"
                  value={item && item.password}
                  onChange={e => this.props.onChange("password", e.target.value)}
                />
              </div>
            )}
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>{translate(MERCHANDISER_EDITOR, "date_of_enrollment", me.language)}</label>
              <DatePicker selected={new Date(item.date_joined)} className="datePicker" onChange={date => this.props.onChange("date_joined", date)} />
            </div>

            <div className="form-group">
              <label>{translate(MERCHANDISER_EDITOR, "deactivation_date", me.language)}</label>
              <DatePicker selected={new Date(item.date_deactivated)} className="datePicker" onChange={date => this.props.onChange("date_deactivated", date)} />
            </div>

            <div className="form-group">
              <label>{translate(MERCHANDISER_EDITOR, "date_till_newbie", me.language)}</label>
              <DatePicker selected={new Date(item.date_till_newbie)} className="datePicker" onChange={date => this.props.onChange("date_till_newbie", date)} />
            </div>

            <div className="form-group">
              <label>{translate(MERCHANDISER_EDITOR, "status", me.language)}</label>
              <Select options={STATES_OPT} value={valueObject(item.is_active, STATES_OPT)} onChange={o => this.props.onChange("is_active", o.value)} />
            </div>

            {this.props.isNew && (
              <div className="form-group">
                <label>{translate(MERCHANDISER_EDITOR, "repeat_password", me.language)}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Repeat password"
                  value={item && item.password2}
                  onChange={e => this.props.onChange("password2", e.target.value)}
                />
              </div>
            )}
          </div>

          <div className="col-md-12">
            <div className="warning">{this.props.errors.join("<br/>")}</div>

            <div className="btn btn-primary white m-b" onClick={() => this.props.submit()}>
              <i className="fa fa-save" /> {translate(MERCHANDISER_EDITOR, "save_changes_btn", me.language)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Edit;
