import React, { Component } from 'react'

import {call} from "../../util/action";

import Map from "../dashboard/_/map_block/Map";

import {dateStart, dateEnd} from '../../util/formatter'

class View extends Component {

    constructor(props) {
        super(props)

        this.state = {
            location:{}
        }
    }

    componentDidMount(){
        const { id } = this.props.match.params;

        if (id!=='new') {
            this.load(id);
        }
    }

    load=(id)=>{
        call({method:"GET", url: 'visits/'+id+'/'}, (item) => {
            let location={
                address: item.point.address,
                lat: item.point.location.latitude,
                lng: item.point.location.longitude
            };

            this.setState({item: item, location:location})
        })
    }

    render() {
       let {item}=this.state;

        console.log(item);

        return (
            <div className='container-fluid'>
                <div className='box px-4 py-1'>
                    <h5 className="mt-3 mb-3"> <span className="faIcon"> <i className="fa fa-tasks" /></span> View Visit #{item&&item.id}</h5>
                </div>
                    <div className='box p-4'>
                    <div className="item taskBlock">
                        <div className="p-a-md">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="clear m-b">
                                        <p className="text-muted">
                                            <small><i className="fa fa-calendar m-r-xs"></i>Star: {item&&dateStart('',item)}</small> <br/>
                                            <small><i className="fa fa-calendar m-r-xs"></i>End:  {item&&dateEnd('',item)}</small> <br/>
                                            <small><i className="fa fa-map-marker m-r-xs"></i>Point: {item&&item.point.name+"("+item.point.address+")"}</small> <br/>
                                        </p>

                                        <div className="default_card map_block">
                                            {item&&<Map location={this.state.location}  zoomLevel={13} />}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }

}

export default View
