import React from "react";

const PageHeader = props => <div className="box row page-header">{props.children}</div>;

/*<PageHeader>
  <PageHeaderTitle></PageHeaderTitle>

  <PageHeaderActions></PageHeaderActions>
</PageHeader>*/

export const PageHeaderTitle = props => (
  <div className="col-sm-6 text-left">
    <h5 className="page-header-title">{props.children}</h5>
  </div>
);
export const PageHeaderActions = props => <div className="col-sm-6 text-right">{props.children}</div>;

export { PageHeader };
