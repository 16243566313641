import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import EntityDropdown from "./EntityDropdown";

class PointFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      county: "",
    };
  }

  componentDidMount = () => {
    let { me } = this.props;

    if (me) {
      let county = "";

      county = me?.county?.id;
      if (Number.isInteger(me.county)) county = me.county;

      this.setState({ county });
    }
  };

  changeCounty = option => {
    //remove these comments after testing
    //let initCounty = this.props.me?.county;
    /*     if (!option) {
      option = {
        ...initCounty,
        value: this.state.county,
        label: "",
      };
    } */
    if (!option) {
      option = {
        id: "",
        country: "",
        value: "",
        label: "",
      };
    }
    this.setState({ county: "" }, () => {
      this.setState({ county: option?.id });
    });
    if (typeof this.props.onChangeRegion === "function") {
      this.props.onChangeRegion(option);
    }
  };

  render() {
    let { isDisabled, moduleFilter, isMulti } = this.props;

    let className = this.props.className || "";

    return (
      <div className={"pointFilter " + className}>
        <EntityDropdown
          entity="county"
          value={this.props.saveValue ? this.props.saveValue : this.state.county}
          asynch={false}
          onChange={this.changeCounty}
          isDisabled={isDisabled}
        />
        <EntityDropdown
          entity="points"
          value={this.props.value}
          moduleFilter={moduleFilter}
          county={this.state.county}
          asynch={true}
          isMulti={isMulti}
          onChange={this.props.onChange}
          isDisabled={isDisabled}
          useFullOptionObject={this.props.useFullOptionObject}
        />
      </div>
    );
  }
}

export default withRouter(connect(state => ({ me: state.me }))(PointFilter));
