import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import LogoC from "/assets/image/logo.svg";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      errors: {},
    };
  }

  render() {
    const { inText } = this.props;
    const { email, errors } = this.state;
    return (
      <div className="padH0">
        <div className="center-block w-xxl w-auto-xs p-y-md regBox regBoxChangigMargin">
          <div className="logo_wrapper">
            {" "}
            <img src={LogoC} className="logo_register" />{" "}
          </div>

          <div className="p-a-md box-color r box-shadow-z1 text-color m-a">
            <div className="m-b regBoxText">
              {/*Forgot password?*/}
              {/*<p className='text-xs m-t secondText'>Enter your email address below and we will send you instructions on how to change your password.</p>*/}
              <p>Забули пароль?</p>
              <p className="text-xs m-t secondText">Введіть свою електронну адресу нижче, і ми надішлемо вам інструкції щодо відновлення пароля.</p>
            </div>
            <div>
              <div className="md-form-group float-label">
                <input type="text" value={email} className="md-input regField" onChange={e => this.setState({ email: e.target.value, errors: {} })} />
                <label className={email.length > 0 ? "floatingLabel" : " "}>E-mail</label>
                {errors.email && <span className="text-danger">{inText("error_email")}</span>}
              </div>
              <button type="submit" className="btn primary btn-block p-x-md" onClick={this.reset}>
                {/*Send*/}
                Відправити
              </button>
            </div>

            <div className="p-v-lg text-center mt-3">
              {" "}
              <Link to="/login" className="additionalLink">
                {/*Return to Login*/}
                Повернутися до сторінки входу
              </Link>{" "}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  inText: key => state.inText("forgot-password")(key),
}))(ForgotPassword);
