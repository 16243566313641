import React, { Component } from 'react'

import Icon from '../../component/Icon';

import './dashboard.css';

import {Visits} from "./_/visits/Visits";
import {MapBlock} from "./_/map_block/MapBlock";
import {Statistics} from "./_/statistics/Statistics";

class View extends Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
  }

  render() {

    return (
      <div className='container-fluid'>

        <div className='box p-4'>
            <h5 className="mt-3 mb-3"><img src={Icon('nav1')} className="title_icon" /> Dashboard</h5>

          <div className='content_wrap dashboard pt-5'>
              <div className="page_info">
                  {/*<h3>Dashboard</h3>*/}
                  <p>for 28.10.20</p>
              </div>
              <div className="row">
                  <div className="col-md-7">
                      <Visits/>
                  </div>
                  <div className="col-md-5">
                      <MapBlock/>
                      <Statistics/>
                  </div>
              </div>
          </div>

        </div>

      </div>
    )

  }

}

export default View
