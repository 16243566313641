import React, { Component } from "react";
import Alert from "react-s-alert";

import BasicTabs from "../../component/general/Tabs";
import LogEvents from "./tabs/LogEvents";
import Map from "./tabs/Map";
import Monitoring from "./tabs/Monitoring";
import Report from "./tabs/Report";
import Tasks from "./tabs/Tasks";
import NoVisitImg from "../../../assets/image/no-visit-photo.jpg";
import Time_icon from "../../../assets/image/time-icon.svg";
import { VISIT_PAGE } from "../../config/lang";

import { call } from "../../util/action";
import { dateStart, dateEnd } from "../../util/formatter";
import { visitOnePoint, timeSpentSingleVisit } from "../../util/formatter";
import { isImage } from "../../util/is-valid-url";
import { getStore } from "../../util/redux/store";
import { translate } from "../../util/util";

import "../../layout/leaflet.scss";

const me = getStore().getState().me;
class View extends Component {
  constructor(props) {
    super(props);

    this.state = {
      location: { lat: 50.4021379, lng: 30.3678885 },
      showMapItem: false,
      isApprovedTask: false,
    };

    this.showMap = this.showMap.bind(this);
    this.approveVisit = this.approveVisit.bind(this);
    this.rejectVisit = this.rejectVisit.bind(this);
  }

  componentDidMount() {
    const { id } = this.props.match.params;

    if (id !== "new") {
      this.load(id);
    }
  }

  load = id => {
    call({ method: "GET", url: "visits/" + id + "/" }, item => {
      let location = {
        address: item.point.address,
        lat: item.point.location.latitude,
        lng: item.point.location.longitude,
      };

      this.setState({ item: item, location: location });
    });
  };

  getVisitInfo() {
    if (!this.state.item?.id) return;
    const visit = this.state.item ? visitOnePoint(this.state.item.id, this.state.item) : "";
    visit ? (visit.spentTime = timeSpentSingleVisit(this.state.item)) : null;
    visit ? (visit.taskStatus = this.state.item.is_approved) : null;
    return visit;
  }

  handleBackClick = () => {
    this.props.history.replace("/visit");
  };

  copyToClipboard = () => {
    const currentUrl = window.location.origin + "/#" + this.props.location.pathname;
    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        alert("URL copied in the clipboard!");
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  };

  showMap = () => {
    this.setState({ showMapItem: true });
  };

  approveVisit = () => {
    let { item } = this.state;
    if (item.is_approved) {
      Alert.warning(translate(VISIT_PAGE, "already_approve_visit_msg", me.language));
      return;
    }
    let body = { is_approved: true, approved_by: me.id };

    call({ method: "PATCH", url: "visits/" + item.id + "/", post: body }, r => {
      Alert.success(translate(VISIT_PAGE, "approve_visit_msg", me.language));
      this.setState({ isApprovedTask: true });
      this.load(item.id);
    });
  };

  rejectVisit = () => {
    let { item } = this.state;
    if (item.is_approved === false) {
      Alert.warning(translate(VISIT_PAGE, "already_reject_visit_msg", me.language));
      return;
    }
    let body = { is_approved: false, approved_by: me.id };

    call({ method: "PATCH", url: "visits/" + item.id + "/", post: body }, r => {
      Alert.success(translate(VISIT_PAGE, "reject_visit_msg", me.language));
      this.setState({ isApprovedTask: false });
      this.load(item.id);
    });
  };

  modalClose = () => this.setState({ showMapItem: false });

  render() {
    let { item } = this.state;
    const visit = this.getVisitInfo();
    return (
      <div className="container-fluid visit-single-page">
        <div className="px-4 py-2">
          <div className="btn back-to-visit" onClick={this.handleBackClick}>
            {translate(VISIT_PAGE, "back_to_btn_label", me.language)}
          </div>
        </div>
        <div className="box p-4">
          <div className="visit-item-wrapper">
            <div className="visit-item-description">
              <img src={isImage(item?.point?.image) ? item.point.image : NoVisitImg} alt={`${visit?.name}`} className="w-62 visit-item-avatar" />
              <div className="visit-item-info-wrapper">
                <p className="visit-item-name">{visit ? visit.name : ""}</p>
                <p className="added-time">{visit ? visit.date : ""}</p>
                {visit ? (
                  <p className={`status-label-text ${visit.taskStatus ? "approved-task" : visit.taskStatus === false ? "no-approved-task" : "task-on-check"}`}>
                    {visit.taskStatus
                      ? translate(VISIT_PAGE, "approved_single_visit_name", me.language)
                      : visit.taskStatus === false
                      ? translate(VISIT_PAGE, "no_approved_single_visit_name", me.language)
                      : translate(VISIT_PAGE, "on_verification_single_visit_name", me.language)}
                  </p>
                ) : null}
                <div className="time-block">
                  <img src={Time_icon} className="time-icon" />
                  {visit ? visit.spentTime : ""}
                </div>
              </div>
            </div>
            <div className="visit-item-author-information">
              <div className="author-avatar-wrapper">
                <img src={isImage(item?.user?.photo) ? item.user.photo : NoVisitImg} alt={`${visit?.author}`} className="w-62 visit-item-author-avatar" />
                <span className={`author-task-status ${item?.user?.is_active ? "active" : "inactive"}`}></span>
              </div>
              <div className="author-block-info">
                <p className="visit-author-name">{visit ? visit.author : "None"}</p>
                <p className="visit-author-role">{translate(VISIT_PAGE, "merchandiser", me.language)}</p>
                <p className="visit-author-phone">{item?.user.phone_number ? item.user.phone_number : "None"}</p>
              </div>
            </div>
          </div>
          <div className="visit-single-action-block pt-4">
            <div className="visit-single-btn-wrapper">
              <button type="button" className="btn btn-default approve-visit" onClick={this.approveVisit}>
                {translate(VISIT_PAGE, "approv_btn", me.language)}
              </button>
              <button type="button" className="btn btn-default reject-visit" onClick={this.rejectVisit}>
                {translate(VISIT_PAGE, "reject_btn", me.language)}
              </button>
            </div>

            <ul className="visit-quick-action-list">
              <li className="visit-quick-item copy-btn" onClick={this.copyToClipboard}></li>
              {/* <li className="visit-quick-item download-btn"></li> */}
              <li className="visit-quick-item locale-btn" onClick={this.showMap}></li>
            </ul>
          </div>
          <div className="item taskBlock">
            <div className="p-y-md">
              <div className="tabs-wrapper">
                {item ? (
                  <BasicTabs
                    className="tabs-list"
                    labels={[
                      translate(VISIT_PAGE, "zvit_tab_name", me.language),
                      translate(VISIT_PAGE, "task_tab_name", me.language),
                      translate(VISIT_PAGE, "monitoring_tab_name", me.language),
                      translate(VISIT_PAGE, "log_tab_name", me.language),
                    ]}
                    tabs={[
                      <Report key="report-visit-tab" data={item} />,
                      <Tasks key="task-visit-tab" data={item} />,
                      <Monitoring key="monitoring-visit-tab" data={item} />,
                      <LogEvents key="log-visit-tab" data={item} />,
                    ]}
                  />
                ) : null}
              </div>
            </div>
          </div>
          {this.state.showMapItem ? (
            <Map
              key="map-visit-tab"
              location={this.state.location}
              zoomLevel={12}
              isModal={this.state.showMapItem}
              modalClose={this.modalClose}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default View;
