import React from "react";
import { DatePicker } from "/src/component/general/DatePicker";
import _ from "lodash";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import Alert from "react-s-alert";

import EntityDropdown from "../../component/EntityDropdown";
import ImageCanvasEditor from "../../component/image-canvas-editor/image-canvas-editor";
import { TAKS_NEW, TAKS_LIST } from "../../config/lang";

import { call } from "../../util/action";
import { getStore } from "../../util/redux/store";
import { translate } from "../../util/util";

const me = getStore().getState().me;

export class TaskEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: [],
      points: [],
      components: [],
    };
  }

  deleteImage = idx => {
    let images = this.props.item.images;
    let id = images[idx].id;
    // delete images[idx];
    this.props.onChange("images", []);

    call({ method: "DELETE", url: "images/" + id + "/", body: {} }, image => {
      Alert.success("Image successfully deleted", { position: "top" });
    });
  };

  uploadFile = acceptedFiles => {
    let file = acceptedFiles[0];
    let reader = new FileReader();
    reader.onload = e => {
      let { result } = e.target;

      call({ method: "POST", url: "images/", post: { owner: me.id, image: result } }, image => {
        if (image?.id) {
          // Alert.success('Image successfully saved', {position: 'bottom-right'});
          let images = this.props.item.images;
          images.push(image);
          this.props.onChange("images", images);
        } else {
          console.log("Error while saving image. Error: " + JSON.stringify(r), { position: "bottom-right" });
          console.log(image);
        }
      });
    };
    reader.readAsDataURL(file);
  };

  closeEditImgBlock = () => {
    this.props.closeModal();
  };

  render() {
    let { item, type, newTaskFromReview = false, newTaskFromReviewPhoto = false } = this.props;
    let endDate = new Date(item.end).setHours(23, 59, 59);
    return (
      <div className={"new-task-wrapper box  box-body boxForm " + (!newTaskFromReview && !newTaskFromReviewPhoto ? "boxEdit" : "")}>
        <div className="edit-images-block col-md-5 pr-2">
          <div className="taskImagesEdit">
            {item?.images?.map((image, idx) => (
              <div key={idx} className="d-inline-block">
                <img src={image.image} onClick={this.props.setOpen} />
                <div className="editPhotoNav">
                  <span className="deleteImage" onClick={() => this.deleteImage(idx)}>
                    <i className="fa fa-minus-square-o" />
                  </span>
                  {this.props.isImageEditable && <ImageCanvasEditor src={image.image} id={idx} onSaveImage={this.props.onSaveEditorImage} />}
                </div>
              </div>
            ))}
          </div>

          {!item?.images?.length && (
            <Dropzone
              onDrop={acceptedFiles => this.uploadFile(acceptedFiles)}
              accept={"image/jpeg, image/png"}
              styles={{ dropzone: { minHeight: 150, maxHeight: 250 } }}
              multiple={false}>
              {({ getRootProps, getInputProps }) => (
                <section className="dropzone mt-3">
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>{translate(TAKS_NEW, "drag_msg", me.language)}</p>
                  </div>
                </section>
              )}
            </Dropzone>
          )}
        </div>
        <div className="task-main-row col-md-7 py-4">
          <h4>{translate(TAKS_NEW, "new_order", me.language)}</h4>
          <div className="task-main-content">
            <div className="col-md-12">
              {type === "network" && (
                <div className="form-group">
                  <label>{translate(TAKS_LIST, "network", me.language)}</label>
                  <EntityDropdown
                    entity="network"
                    value={item?.network?.id}
                    onChange={o => {
                      this.props.onChange("network", o);
                    }}
                  />
                </div>
              )}

              {type === "point" && !newTaskFromReview && !newTaskFromReviewPhoto && (
                <div className="form-group">
                  <label>{translate(TAKS_LIST, "point", me.language)}</label>
                  <EntityDropdown
                    entity="point"
                    value={item?.point?.id}
                    onChange={o => {
                      this.props.onChange("point", o);
                    }}
                    asynch={true}
                  />
                </div>
              )}

              <div className="form-group">
                <label>{translate(TAKS_LIST, "task_title", me.language)}</label>
                <input type="text" className="form-control" placeholder="" value={item.title} onChange={e => this.props.onChange("title", e.target.value)} />
              </div>

              <div className="form-group">
                <label>{translate(TAKS_LIST, "description", me.language)}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  value={item?.description}
                  onChange={e => this.props.onChange("description", e.target.value)}
                />
              </div>
            </div>

            <div className={"col-md-12"}>
              <div className="form-group">
                <label>{translate(TAKS_NEW, "task_template", me.language)}</label>
                <EntityDropdown
                  entity="template"
                  value={item.template}
                  onChange={o => this.props.onChange("template", o?.value)}
                  useDefaultValue
                  // setDefaultValue={this.setEntityDropDownDefaultValue}
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>{translate(TAKS_NEW, "start_date", me.language)}</label>
                <DatePicker
                  selected={new Date(item.start)}
                  popperPlacement="top"
                  className="datePicker"
                  onChange={date => this.props.onChange("start", date)}
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>{translate(TAKS_NEW, "end_date", me.language)}</label>
                <DatePicker selected={new Date(endDate)} popperPlacement="top" className="datePicker" onChange={date => this.props.onChange("end", date)} />
              </div>
            </div>
          </div>
          {!_.isEmpty(this.props.errors) && (
            <div className="col-md-12 error mt-2">
              {this.props.errors &&
                Object.values(this.props.errors).map((error, index) => {
                  return <div key={index}>{error}</div>;
                })}
            </div>
          )}
          <div className="col-md-12 mt-3 actions-block">
            <button type="button" className="btn-primary save-image-popup" onClick={this.props.submit}>
              <i className="fa fa-save" /> {translate(TAKS_NEW, "save_btn", me.language)}
            </button>
            <button type="button" className="btn-primary cancel-image-popup" onClick={this.closeEditImgBlock}>
              <i className="fa fa-mail-reply" /> {translate(TAKS_LIST, "cancel_btn", me.language)}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

TaskEdit.propTypes = {
  item: PropTypes.object,
  type: PropTypes.oneOf(["point", "network"]),
};
