
export const WEEK = {
  0: "monday",
  1: "tuesday",
  2: "wednesday",
  3: "thursday",
  4: "friday",
  5: "saturday",
  6: "sunday",
};

export const PRIORITY = {
  3: "Low",
  2: "Medium",
  1: "High",
  0: "Required",
};

export const MOVEMENTS = {
  "on_foot": "On foot",
  "motorcycle": "Motorcycle",
  "bike/scooter": "Bike or scooter",
  "automobile": "Automobile",
  "public_transport": "Public transport",
};

export const PRIORITY_OPT = [
  { value: 3, label: "Low" },
  { value: 2, label: "Medium" },
  { value: 1, label: "High" },
  { value: 0, label: "Required" },
];

export const COMPONENTS_OPT = [
  { value: "boolean", label: "boolean" },
  { value: "photo", label: "photo" },
  { value: "text", label: "text" },
];

export const MOVEMENTS_OPT = [
  { value: "on_foot", label: "On foot" },
  { value: "motorcycle", label: "Motorcycle" },
  { value: "bike/scooter", label: "Bike or scooter" },
  { value: "automobile", label: "Automobile" },
  { value: "public_transport", label: "Public transport" },
];

export const STATES_OPT = [
  { value: true, label: "Active" },
  { value: false, label: "Not Active" },
];

export const NKA_OPT = [
  { value: "direct", label: "NKA Direct" },
  { value: "indirect", label: "NKA Indirect" },
];

export const translatedComponents = {
  boolean: "так/ні",
  photo: "фото",
  text: "текст",
};
