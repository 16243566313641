import React, { Component } from "react";
import BasicTabs from "/src/component/general/Tabs";

import { PageHeader, PageHeaderTitle } from "../../component/general/page-header";
import { ImportAdvanced } from "./import-advanced/ImportAdvanced";
import { ImportAMatrix } from "./import-amatrix/ImportAMatrix";
import ImportCore from "./import/ImportCore";
import { UploadLogs } from "./upload-logs/UploadLogs";
import { TAKS_IMPORT } from "../../config/lang";

import { translate } from "../../util/util";

import "./style.scss";

class TasksImport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      valid: false,
    };
  }

  componentDidMount = () => {
    this.userValidate();
  };

  userValidate = () => {
    const { currentUser } = this.props;
    if (currentUser.role === "supervisor_manager") this.setState({ valid: true });
  };

  render() {
    const { uploadLogs } = this.state;
     const { currentUser } = this.props;
    return (
      <div className="container-fluid">
        <div className="box p-4">
          {this.state.valid ? (
            <BasicTabs
              labels={[
                translate(TAKS_IMPORT, "import_tab", currentUser.language),
                translate(TAKS_IMPORT, "import_advanced_tab", currentUser.language),
                translate(TAKS_IMPORT, "import_matrix_tab", currentUser.language),
                translate(TAKS_IMPORT, "import_downloads_tab", currentUser.language),
              ]}
              tabs={[
                <ImportCore key="import" />,
                <ImportAdvanced key="advance-import" />,
                <ImportAMatrix key="import-matrix" />,
                <UploadLogs key="my-uploads" uploadLogs={uploadLogs} />,
              ]}
            />
          ) : (
            <>
              <PageHeader>
                <PageHeaderTitle>
                  <span className="faIcon">
                    <i className="fa fa-download" />
                  </span>
                  Page Not Found
                </PageHeaderTitle>
              </PageHeader>
              <a href="/" className="btn btn-primary mt-5 ml-4">
                {translate(TAKS_IMPORT, "back_btn", currentUser.language)}
              </a>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default TasksImport;
