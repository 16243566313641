import React from "react";
import Avatar from "/assets/image/avatar.jpg";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { MANAGER_PROFILE } from "../config/lang";

import { logout } from "../util/redux/actions";
import { translate } from "../util/util";

export default function HeaderAvatar() {
  const { me } = useSelector(state => state);

  const dispatch = useDispatch();

  let callLogout = () => {
    dispatch(logout());
  };

  return (
    <ul className="nav navbar-nav pull-right navbar_avatar">
      <li className="nav-item nav-item-avatar dropdown open">
        <a className="nav-link clear headerUserBar" href="#" data-toggle="dropdown" aria-expanded="true">
          <span className="avatar w-32 mr-2">
            <img id="aside-avatar" src={me?.photo ? me.photo : Avatar} alt={`${me?.first_name} ${me?.last_name}`} className="w-32 img-circle avatar-crop" />
            <i className="on b-white bottom"></i>
          </span>

          <span className="avatar-label text-ellipsis dropdown-toggle">{`${me?.first_name} ${me?.last_name}`}</span>
        </a>
        <div className="dropdown-menu pull-right dropdown-menu-scale ng-scope">
          <Link className="dropdown-item" to="/profile">
            {" "}
            <i className="fa fa-user"></i> <span>{translate(MANAGER_PROFILE, "my_profile", me?.language)}</span>
          </Link>
          <div className="dropdown-divider"></div>
          <Link className="dropdown-item" to="/help">
            {" "}
            <i className="fa fa-question"></i> {translate(MANAGER_PROFILE, "my_help", me?.language)}
          </Link>
          <div className="dropdown-divider"></div>
          <a className="dropdown-item" href="#" onClick={callLogout}>
            <i className="fa fa-sign-out"></i>
            {translate(MANAGER_PROFILE, "exit", me?.language)}
          </a>
        </div>
      </li>
    </ul>
  );
}
