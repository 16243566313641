import React, { Component } from "react";
import Alert from "react-s-alert";

// import EntityDropdown from "../../component/EntityDropdown";
import PointFilter from "./../../component/PointFilter";
import { WEEK } from "../../config/const.js";
import { MERCHANDISER_MAIN_PAGE } from "../../config/lang";

import { translate } from "../..//util/util";
import { call } from "../../util/action";
import { getStore } from "../../util/redux/store";

const me = getStore().getState().me;

class Route extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [
        { day_of_the_week: 0, title: "", points: [] },
        { day_of_the_week: 1, title: "", points: [] },
        { day_of_the_week: 2, title: "", points: [] },
        { day_of_the_week: 3, title: "", points: [] },
        { day_of_the_week: 4, title: "", points: [] },
        { day_of_the_week: 5, title: "", points: [] },
        { day_of_the_week: 6, title: "", points: [] },
      ],
      changedItem: false,
    };
  }

  componentDidMount() {
    this.load();
  }

  load = () => {
    call({ method: "GET", url: "routes?user=" + this.props.user.id }, routes => {
      this.populateItems(routes);
    });
  };

  populateItems = routes => {
    let items = this.state.items;
    routes.map(i => {
      items[i.day_of_the_week] = i;
    });
    this.setState({ items: items });
  };

  prepareItemsToSend = items => {
    items.forEach((i, idx) => {
      items[idx] = { ...i, title: WEEK[i.day_of_the_week], user: this.props.user.id, start: null, end: null, created_by: me ? me.id : null };
    });

    return items;
  };

  submit = () => {
    let { changedItem } = this.state;
    if (!changedItem) return;
    let items = this.prepareItemsToSend(this.state.items);

    items.map(this.submitOne);
    this.setState({ changedItem: false });
  };

  submitOne = item => {
    console.log("Submit item", item);

    this.addRoute(item);
  };

  addRoute = item => {
    call({ method: "POST", url: "routes/", post: item }, r => {
      console.log("Route add callback", r);

      if (r && r.id) {
        Alert.success("Марштур успішно збережені", { position: "bottom-right" });
        this.submitPoints(r.id, item.points);
      } else {
        Alert.warning("Error while saving route. Error: " + JSON.stringify(r), { position: "bottom-right" });
      }
    });
  };

  submitPoints = (route_id, points) => {
    let orders = [];
    points &&
      points.map((i, idx) => {
        orders.push({ route: route_id, point: i.point.id, order: idx });
      });

    if (orders.length > 0) {
      // console.log('orders save for'+route_id, orders);

      call({ method: "POST", url: "order/", post: orders }, r => {
        // console.log('R-order', r);
      });
    }
  };

  deletePoint = (route, pointIdx) => {
    let items = this.state.items;
    let points = route.points;
    points.splice(pointIdx, 1);
    items[route.day_of_the_week].points = points;
    this.setState({ items: items, changedItem: true });
  };

  addPoint = (route, obj) => {
    if(!obj) return;
    let items = this.state.items;
    let points = route.points;
    points.push({ point: obj, order: 0 });
    items[route.day_of_the_week].points = points;
    this.setState({ items: items });
  };

  checkChangedItem = (obj) => {
      if (!obj) return;
      this.setState({ changedItem: true });
  }

  onTitleChange = (e, route) => {
    let items = this.state.items;
    items[route.day_of_the_week].title = e.target.value;
    this.setState({ items: items });
  };

  routePoints = route => {
    return (
      <div className="routePoints">
        {route.points.length > 0
          ? route.points.map((i, pointIdx) => {
              if (i.point)
                return (
                  <div className="routePointRow" key={i.point?.id + i.point?.name + pointIdx}>
                    <span className="label-lg rounded primary labelPoint">
                      <i className="fa fa-map-marker" /> {i.point?.name} - {i.point?.address}
                    </span>
                    <span className="routeDelete" onClick={() => this.deletePoint(route, pointIdx)}>
                      <i className="fa fa-minus-circle" />
                    </span>
                  </div>
                );
            })
          : null}
        <div className="routePointsAdd">
          <div className="btn routeAdd">
            <i className="fa fa-plus" />
          </div>

          {/* <EntityDropdown entity='point' asynch={true} onChange={(o)=>{  }} /> */}

          <PointFilter
            onChange={o => {
              this.addPoint(route, o);
              this.checkChangedItem(o);
            }}
          />
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="row m-t">
        <div className="col-md-12">
          <table className="table table-hover table-bordered tableRoute">
            <tbody>
              <tr className="primary tableRouteHead">
                <td>{translate(MERCHANDISER_MAIN_PAGE, "day_title", me.language)}</td>
                <td>{translate(MERCHANDISER_MAIN_PAGE, "points_title", me.language)}</td>
              </tr>
              {this.state.items.map((i, index) => {
                return (
                  <tr data-key={i.id ? i.id + index : index} key={i.id ? i.id + index : index}>
                    <td className="primary tableRouteDay">{WEEK[i.day_of_the_week]}</td>
                    <td className="tableRoutePoint">{this.routePoints(i)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="col-md-12">
          <div className="btn btn-primary white m-b" onClick={() => this.submit()}>
            {translate(MERCHANDISER_MAIN_PAGE, "save_btn", me.language)}
          </div>
        </div>
      </div>
    );
  }
}

export default Route;
