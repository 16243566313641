import React, { Component } from "react";
import { httpRequest } from "/src/util/action";
import { fieldIsRequired } from "/src/util/util";
import _ from "lodash";
import Dropzone from "react-dropzone";
import Alert from "react-s-alert";

import { TAKS_IMPORT } from "../../../config/lang";

import { getStore } from "../../../util/redux/store";
import { translate } from "../../../util/util";

import "../style.scss";

const me = getStore().getState().me;
// export const crateFileXLSX = (data: any, fileName: string) => {
//     return new File([data], fileName, {
//         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//     });
// };
class ImportAMatrix extends Component {
  constructor(props) {
    super(props);

    this.dataTypeList = [
      {
        id: 1,
        label: translate(TAKS_IMPORT, "network_label", me.language),
        value: "network",
      },
      {
        id: 2,
        label: translate(TAKS_IMPORT, "point_label", me.language),
        value: "point",
      },
    ];

    this.state = {
      totalDataSize: 0,
      sizePerPage: 10,
      pageAmount: 1,
      page: 1,
      errorList: [""],
      errors: {
        name: "",
        point: "",
        network: "",
        template: "",
        tasks: [],
      },
      filter: {
        dataType: null, // "network" | "point"
        network: null,
        point: null,
        template: null,
        bulkName: "",
      },
      tasks: [],
      uploadFile: null,
      fileDataToSend: {},
    };
  }

  componentDidMount() {}

  validate = formValues => {
    let { errors } = this.state;
    let hasErrors = false;

    if (!formValues.tasks?.length) {
      errors["tasks"] = fieldIsRequired();
    } else {
      formValues.tasks.map((task, taskIndex) => {
        errors["tasks"][taskIndex] = { title: "", description: "", start: null, end: null };

        Object.entries(task).map((entry, index) => {
          if (!entry[1]?.length) {
            errors["tasks"][taskIndex][entry[0]] = fieldIsRequired();
            hasErrors = true;
          }
        });
      });
    }

    if (!formValues.action_name) {
      errors["name"] = fieldIsRequired();
    }
    if (!formValues.template) {
      errors["template"] = fieldIsRequired();
    }

    // console.log("formValues", formValues);

    return !hasErrors;
  };

  submit = (fileDataToSend = []) => {
    let tasks = this.prepareItemsToSend(this.state, fileDataToSend);

    if (!this.validate(tasks)) {
      return;
    }
    this.setState({
      errorList: [""],
    });
    httpRequest({ method: "POST", url: "assortment_matrix/bulk_create/", post: tasks }, async response => {
      const result = await response.json();

      if (response.status === 200) {
        Alert.success("Items successfully saved", { position: "bottom-right" });
        this.setState({
          tasks: [],
          uploadFile: null,
          fileDataToSend: {},
        });
      }

      if (response.status === 400) {
        this.setState({
          errorList: [result.details],
        });
      }
    });
  };

  prepareItemsToSend = (state, fileDataToSend) => {
    return {
      action_name: state.filter.bulkName,
      points: fileDataToSend.pointCodes,
      assortment_matrix_list: fileDataToSend.assortmentMatrix,
    };
  };

  deleteRow = idx => {
    this.setState(prevState => ({
      tasks: prevState.tasks.filter((item, index) => index !== idx),
    }));
  };

  addRow = i => {
    this.setState({
      tasks: [
        ...this.state.tasks,
        {
          title: "",
          info: "",
          start: new Date(),
          end: new Date(),
        },
      ],
    });
  };

  onChange = (property, value, idx) => {
    let tasks = this.state.tasks;
    tasks[idx][property] = value;
    this.setState({ tasks });
  };

  changeFilter = (filterEntity, item) => {
    let filter = { ...this.state.filter };

    filter[filterEntity] = item?.id;

    this.setState(prevState => ({
      filter,
    }));
  };

  handleCangeDataType = option => {
    this.setState(prevState => ({
      filter: {
        ...prevState.filter,
        dataType: option.value,
      },
    }));
  };

  handleChangeBulkName = e => {
    this.setState(prevState => ({
      filter: {
        ...prevState.filter,
        bulkName: e.target.value || "",
      },
    }));
  };

  uploadFile = acceptedFiles => {
    let file = acceptedFiles[0];
    let reader = new FileReader();
    reader.onload = e => {
      // console.log("reader.result", reader.result, file);
      // return;
      const uploadFile = reader.result.split(/\r?\n/).filter(item => item?.length);

      this.getUploadData(uploadFile);
      this.setState({ uploadFile: file });
    };
    reader.readAsText(file);
    reader.readAsBinaryString(file);
    reader.readAsDataURL(file);
  };

  getUploadData = (stringArray = [""]) => {
    let pointCodes = stringArray.shift();
    let productCodes = [];
    let nonEmptyFields = [];
    let assortmentMatrix = {};

    pointCodes = pointCodes.split(",").filter((item, index) => !!index);
    productCodes = stringArray.map((row, rowIndex) => row.split(",")[0]);

    const preparedUploadData = stringArray.map((row, index) => {
      let fields = row.split(",");
      let nonEmptyProductCodes = [];

      fields.shift();
      fields.map((field, fieldIndex) => {
        if (!!field.trim()?.length) {
          nonEmptyProductCodes.push(fields[0]);
          nonEmptyFields.push([fieldIndex, index]);
          if (!!assortmentMatrix[pointCodes[fieldIndex]]) {
            assortmentMatrix[pointCodes[fieldIndex]].push(productCodes[index]);
          } else {
            assortmentMatrix[pointCodes[fieldIndex]] = [productCodes[index]];
          }
        }
      });
    });

    this.setState({ fileDataToSend: { assortmentMatrix, pointCodes } });

    return preparedUploadData;
  };

  renderUploadFileResult = (stringArray = [""]) => {
    const titles = stringArray.shift();

    const renderHead = (numRow = 0) => (
      <thead className="primary text-center">
        <tr>
          {titles?.split(",").map((field, index) => (
            <th key={index}>{field}</th>
          ))}
        </tr>
      </thead>
    );
    const renderList = (numRow = 0) => {
      return (
        <tbody>
          {stringArray.map((row, index) => (
            <tr key={index}>
              {stringArray[index].split(",").map((field, index) => (
                <td key={index}>{field}</td>
              ))}
            </tr>
          ))}
        </tbody>
      );
    };

    return (
      stringArray.some(item => !!item) && (
        <table className="table table-hover table-bordered table-tasks-import">
          {renderHead()}
          {renderList()}
        </table>
      )
    );
  };

  render() {
    const { errorList, filter, uploadFile, fileDataToSend } = this.state;
    const isShowErrorList = errorList.some(item => !!item);

    return (
      <div className="box p-4">
        {isShowErrorList && (
          <div className="error-container">
            <div className="error">
              {errorList?.map((error, index) => (
                <div key={index}>{error}</div>
              ))}
            </div>
          </div>
        )}
        <div className="filter-bar mb-2 boxForm">
          <input
            type="text"
            className="form-control searchInput searchInputPhoto mr-1"
            placeholder={translate(TAKS_IMPORT, "download_name", me.language)}
            value={filter.bulkName}
            onChange={this.handleChangeBulkName}
          />

          <div>
            <Dropzone
              onDrop={acceptedFiles => this.uploadFile(acceptedFiles)}
              accept={".csv, .xlsx"}
              styles={{ dropzone: { minHeight: 150, maxHeight: 250 } }}
              multiple={false}>
              {({ getRootProps, getInputProps }) => (
                <div className="file-input mr-1" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <span>{uploadFile?.name || translate(TAKS_IMPORT, "click_or_drag_file", me.language)}</span>
                </div>
              )}
            </Dropzone>
            <button className="btn btn-primary" disabled={_.isEmpty(fileDataToSend)} onClick={e => this.submit(fileDataToSend)}>
              <i className="fa fa-save" /> {translate(TAKS_IMPORT, "download_btn", me.language)}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export { ImportAMatrix };
