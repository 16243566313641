import { API_URL_LOGIN, API_URL_PREFIX } from "../config/config";

const HEADERS = { "Accept": "application/json", "Content-Type": "application/json", "X-Frame-Options": "deny" };

export function call(body = {}, cb, customHeaders = {}, isBlob = false) {
  const headers = new Headers(HEADERS);

  let state = JSON.parse(localStorage.getItem("state"));
  headers.append("Authorization", `Token ${state?.me?.key || null}`);

  if (customHeaders) {
    Object.entries(customHeaders).map(header => {
      headers.append(header[0], header[1]);
    });
  }

  let params = { method: body.method, headers, mode: "cors", cache: "default" };

  if (["POST", "PATCH", "PUT"].indexOf(body.method) !== -1) {
    params.body = JSON.stringify(body.post);
  }

  let url = API_URL_PREFIX + body.url;

  if (body.outforz) {
    url = "https://dev.api.outforz.com/api/contents/";
  }

  if (isBlob) {
    fetch(url, params)
      .then(response => {
        if (!response || response.status === 500) {
          console.log("Status", response.status);
          return;
        }

        return response.blob();
      })
      .then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");

        a.href = url;
        a.download = "export.zip";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again

        cb();
      })
      .catch(err => {
        console.log({ ...err });
      });
  } else {
    fetch(url, params)
      .then(response => {
        if (!response || response.status === 500) {
          console.log("Status", response.status);
          return;
        }
        if (response.status === 400) {
          console.log("ERROR CATCHED");
          console.log(response);
          cb(response);
          return;
        }
        if (response.status === 204) {
          cb(response);
          return;
        }

        return response.json();
      })
      .then(response => {
        if (response?.detail === "Invalid token.") {
          state.me = null;
          localStorage.setItem("state", state);
          window.location.replace("/#/login");
        }
        if (!!response) {
          cb(response);
        }
      })
      .catch(err => {
        console.log("ERROR CATCHED");
        console.log(err);
      });
  }
}

export function httpRequest(body = {}, cb, customHeaders = {}, isBlob = false) {
  const headers = new Headers(HEADERS);

  let state = JSON.parse(localStorage.getItem("state"));
  headers.append("Authorization", `Token ${state?.me?.key || null}`);

  if (customHeaders) {
    Object.entries(customHeaders).map(header => {
      headers.append(header[0], header[1]);
    });
  }

  let params = { method: body.method, headers, mode: "cors", cache: "default" };

  if (["POST", "PATCH", "PUT"].indexOf(body.method) !== -1) {
    params.body = JSON.stringify(body.post);
  }

  let url = API_URL_PREFIX + body.url;

  if (body.outforz) {
    url = "https://dev.api.outforz.com/api/contents/";
  }

  if (isBlob) {
    fetch(url, params)
      .then(response => {
        if (!response || response.status === 500) {
          console.log("Status", response.status);
          return;
        }

        return response.blob();
      })
      .then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");

        a.href = url;
        a.download = "export.zip";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again

        cb();
      })
      .catch(err => {
        console.log({ ...err });
      });
  } else {
    fetch(url, params)
      .then(response => {
        if (!response || response.status === 500) {
          console.log("Status", response.status);
          return;
        }
        if (response.status === 400) {
          console.log("ERROR CATCHED");
          console.log(response);
          cb(response);
          return;
        }
        if (response.status === 204 || response.status === 200) {
          cb(response);
          return;
        }

        return response.json();
      })
      .then(response => {
        if (response?.detail === "Invalid token.") {
          state.me = null;
          localStorage.setItem("state", state);
          window.location.replace("/#/login");
        }
        if (!!response) {
          cb(response);
        }
      })
      .catch(err => {
        console.log("ERROR CATCHED");
        console.log(err);
      });
  }
}

export const callAsync = async (body = {}, cb) => {
  const headers = new Headers(HEADERS);
  let state = JSON.parse(localStorage.getItem("state"));

  headers.append("Authorization", `Token ${state.me ? state.me.key : null}`);

  let params = { method: body.method, headers, mode: "cors", cache: "default" };

  if (["POST", "PATCH", "PUT"].indexOf(body.method) !== -1) {
    params.body = JSON.stringify(body.post);
  }

  let url = API_URL_PREFIX + body.url;

  if (body.outforz) {
    url = "https://dev.api.outforz.com/api/contents/";
  }

  fetch(url, params)
    .then(response => {
      if (!response || response.status === 500) {
        console.log("Status", response.status);
        return;
      }

      return response.json();
    })
    .then(response => {
      if (response && response.detail && response.detail === "Invalid token.") {
        state.me = null;
        localStorage.setItem("state", state);
        window.location.replace("/#/login");
      }

      cb(response);
    })
    .catch(err => {
      console.log("ERROR CATCHED");
      console.log(err);
    });
};

export function auth(body = {}) {
  const headers = new Headers(HEADERS);
  let params = { method: "POST", headers: headers, mode: "cors", cache: "default" };
  params.body = JSON.stringify(body);

  return new Promise((resolve, reject) => {
    fetch(`${API_URL_LOGIN}`, params)
      .then(response => response.json())
      .then(resolve)
      .catch(reject);
  });
}

export function upload(body = {}, cb) {
  const headers = new Headers();

  let state = JSON.parse(localStorage.getItem("state"));
  headers.append("Authorization", `Token ${state.me ? state.me.key : null}`);

  let params = { method: body.method, headers: headers, mode: "cors", cache: "default" };
  params.body = body.post;

  let url = API_URL_PREFIX + body.url;

  fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(res => {
      cb(res);
    })
    .catch(err => {
      console.log("ERROR CATCHED");
      console.log(err);
    });
}
