import React from 'react'
import './statistics.css'


export const Statistics = () =>{
    return(
        <div className="statistics_block default_card">
            <h5>Statistics</h5>
            <div className="statistics_wrapper">
                <div className="bg_blue one"></div>
                <div className='bg_coral two'></div>
                <div className='bg_dark_blue three'></div>
                <div className='bg_green four'></div>
                <div className='bg_orange five'></div>
            </div>
        </div>
    )
}