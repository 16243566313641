import React, { Component } from "react";
import { TableHeaderColumn } from "react-bootstrap-table";
import Alert from "react-s-alert";

import EntityTable from "../../component/EntityTable";
import { MERCHANDISER_MAIN_PAGE } from "../../config/lang";
import Absence from "../merchandiser/Absence";
import Edit from "../merchandiser/Edit";
import Route from "../merchandiser/Route";
import View from "../merchandiser/View";

import { translate } from "../..//util/util";
import { call } from "../../util/action";
import { openTask, openVisit, point, dateStart, dateEnd, networkTask, imageCount, tagCount } from "../../util/formatter";
import { getStore } from "../../util/redux/store";

class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: "VIEW",
      new: false,
      changedItem: false,

      item: {
        date_joined: new Date(),
        date_deactivated: new Date(),
        date_till_newbie: new Date(),
        password: "",
        password2: "",
        company: null,
      },
      errors: [],

      tasks: [],
      routes: [],
      visits: [],
    };
  }

  getMe = () => {
    const me = getStore().getState().me;
    return me;
  };

  submit = () => {
    let { item, changedItem } = this.state;
    let errors = [];
    const me = this.getMe();
    if (item.password !== item.password2) {
      errors["password"] = "Passwords should match.";
    }

    console.log(errors);
    if (errors.length > 0) {
      this.setState({ errors: errors });
      return;
    }

    if (!changedItem) return;

    let itemCopy = Object.assign(item, {});
    delete itemCopy.photo;
    //FIXME: why does the supervisor company attach the ID of its company to the merchandiser and not itself company id of the merchandiser
    itemCopy.company = me && me.company ? me.company.id : null;

    call({ method: item && item.id ? "PATCH" : "POST", url: item && item.id ? "merchandisers/" + item.id + "/" : "merchandisers/", post: item }, r => {
      if (r && r.id) {
        Alert.success(translate(MERCHANDISER_MAIN_PAGE, "data_successfully_saved", me.language), { position: "bottom-right" });

        console.log(r);
      } else {
        Alert.warning("Error while saving item. Error: " + JSON.stringify(r), { position: "bottom-right" });
        console.log(r);
      }
    });
    this.setState({ changedItem: false });
  };

  componentDidMount() {
    const { id } = this.props.match.params;

    if (id !== "new") {
      this.load(id);
    } else {
      this.setState({ mode: "EDIT", new: true });
    }
  }

  load = id => {
    call({ method: "GET", url: "merchandisers/" + id + "/" }, r => {
      this.setState({ item: r });

      call({ method: "GET", url: "tasks?user=" + id }, r => {
        if (r.results) {
          this.setState({ tasks: r.results });
        }
      });

      call({ method: "GET", url: "visits?user=" + id }, r => {
        if (r.results) {
          this.setState({ visits: r.results });
        }
      });
    });
  };

  onChange = (property, value) => {
    let item = this.state.item;
    item[property] = value;
    this.setState({ item: item, changedItem: true });
  };

  toggleMode = () => {
    this.setState({ mode: this.state.mode == "EDIT" ? "VIEW" : "EDIT" });
  };

  render() {
    let { item } = this.state;
    const me = this.getMe();
    return (
      <div className="container-fluid">
        <div className="box px-4 py-1">
          <h5 className="mt-3 mb-3">
            <span className="faIcon">
              <i className="fa fa-users" />
            </span>
            {this.state.new ? "New Merchandiser" : (this.state.mode == "EDIT" ? "Edit" : "View") + " Merchandiser #" + (item && item.id)}
          </h5>
        </div>

        <div className="box p-4">
          <div className="item merchandiserBlock p-a-md">
            {!this.state.new && (
              <div className="row pull-right">
                <div className="btn btn-outline-primary d-inline-block" onClick={this.toggleMode}>
                  {this.state.mode == "EDIT" ? (
                    <React.Fragment>
                      <i className="fa fa-eye" /> {translate(MERCHANDISER_MAIN_PAGE, "view_btn", me.language)}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fa fa-edit" /> {translate(MERCHANDISER_MAIN_PAGE, "edit_btn", me.language)}
                    </React.Fragment>
                  )}
                </div>
              </div>
            )}
            {this.state.mode == "VIEW" ? (
              <View item={item} lang={me.language} />
            ) : (
              <Edit me={this.getMe()} item={item} isNew={this.state.new} errors={this.state.errors} submit={() => this.submit()} onChange={this.onChange} />
            )}

            {!this.state.new && (
              <div className="col-sm-12">
                <div className="b-b b-primary nav-active-primary">
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <a className="nav-link active" href="" data-toggle="tab" data-target="#tab1" aria-expanded="false">
                        {translate(MERCHANDISER_MAIN_PAGE, "edit_btn", me.language)}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="" data-toggle="tab" data-target="#tab2" aria-expanded="false">
                        {translate(MERCHANDISER_MAIN_PAGE, "assignment", me.language)}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="" data-toggle="tab" data-target="#tab3" aria-expanded="true">
                        {translate(MERCHANDISER_MAIN_PAGE, "visits", me.language)}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="" data-toggle="tab" data-target="#tab4" aria-expanded="true">
                        {translate(MERCHANDISER_MAIN_PAGE, "presence", me.language)}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="tab-content p-a m-b-md">
                  <div className="tab-pane animated fadeIn active" id="tab1" aria-expanded="false">
                    {item && item.id && <Route user={item} />}
                  </div>
                  <div className="tab-pane animated fadeIn  " id="tab2" aria-expanded="false">
                    <EntityTable data={this.state.tasks} type="data" entity="merchandiser.task">
                      <TableHeaderColumn dataField="id" dataFormat={openTask} width="82px" isKey></TableHeaderColumn>
                      <TableHeaderColumn dataFormat={networkTask}>{translate(MERCHANDISER_MAIN_PAGE, "network_title", me.language)}</TableHeaderColumn>
                      <TableHeaderColumn dataField="title">{translate(MERCHANDISER_MAIN_PAGE, "title_title", me.language)}</TableHeaderColumn>
                      {/*<TableHeaderColumn dataField='subtitle'>Subtitle</TableHeaderColumn>*/}
                      <TableHeaderColumn dataFormat={dateStart}>{translate(MERCHANDISER_MAIN_PAGE, "start_title", me.language)}</TableHeaderColumn>
                      <TableHeaderColumn dataFormat={dateEnd}>{translate(MERCHANDISER_MAIN_PAGE, "end_title", me.language)}</TableHeaderColumn>
                      <TableHeaderColumn dataFormat={imageCount}>
                        {translate(MERCHANDISER_MAIN_PAGE, "number_of_images_title", me.language)}
                      </TableHeaderColumn>
                      <TableHeaderColumn dataFormat={tagCount}>
                        {translate(MERCHANDISER_MAIN_PAGE, "number_of_tags_title", me.language)}
                      </TableHeaderColumn>
                    </EntityTable>
                  </div>
                  <div className="tab-pane animated fadeIn  " id="tab3" aria-expanded="true">
                    <EntityTable data={this.state.visits} type="data" entity="merchandiser.visit">
                      <TableHeaderColumn dataField="id" dataFormat={openVisit} isKey width="82px"></TableHeaderColumn>
                      <TableHeaderColumn dataFormat={dateStart}>{translate(MERCHANDISER_MAIN_PAGE, "start_title", me.language)}</TableHeaderColumn>
                      <TableHeaderColumn dataFormat={dateEnd}>{translate(MERCHANDISER_MAIN_PAGE, "completion_title", me.language)}</TableHeaderColumn>
                      <TableHeaderColumn dataFormat={point}>{translate(MERCHANDISER_MAIN_PAGE, "point_title", me.language)}</TableHeaderColumn>
                    </EntityTable>
                  </div>
                  <div className="tab-pane animated fadeIn" id="tab4" aria-expanded="true">
                    {item && item.id && <Absence user={item} />}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Page;
