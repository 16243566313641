import React, { Component } from 'react'

import Avatar from "../../../assets/image/avatar.jpg";
import {MOVEMENTS} from '../../config/const.js'
import { MERCHANDISER_MAIN_PAGE } from "../../config/lang";

import { translate } from "../..//util/util";
import {userState, userDateJoined,userDateDeactivated, userDateTillNewbie} from '../../util/formatter'

class View extends Component {

    render() {
        let { item, lang } = this.props;
        return (
          <div className="row viewItem">
            <div className="col-sm-12">
              <span className="avatar w-96">
                <img src={item && item.photo ? item.photo : Avatar} />{" "}
              </span>
              <div className="m-t">
                <h3 className="m-0 m-b-xs">{item && item.first_name + " " + item.last_name}</h3>
                <div className="text-muted row mt-3">
                  <div className="col-md-6 text-right">
                    <div>
                      <i className="fa fa-phone m-r-xs"></i>
                      {item && item.phone_number}
                    </div>
                    <div>
                      {translate(MERCHANDISER_MAIN_PAGE, "movement", lang)}
                      <div className="label rounded primary">{item && MOVEMENTS[item.movement]}</div>
                    </div>
                    <div>
                      {translate(MERCHANDISER_MAIN_PAGE, "user_status", lang)} {item && userState("", item)}
                    </div>
                  </div>

                  <div className="col-md-6 text-left">
                    <div>
                      <i className="fa fa-calendar m-r-xs"></i> {translate(MERCHANDISER_MAIN_PAGE, "connected", lang)}:{" "}
                      {item && userDateJoined("", item)}
                    </div>
                    <div>
                      <i className="fa fa-calendar m-r-xs"></i> {translate(MERCHANDISER_MAIN_PAGE, "deactivated", lang)}:{" "}
                      {item && userDateDeactivated("", item)}
                    </div>
                    <div>
                      <i className="fa fa-calendar m-r-xs"></i> {translate(MERCHANDISER_MAIN_PAGE, "till_newbie", lang)}:{" "}
                      {item && userDateTillNewbie("", item)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
}

export default View
