import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import Alert from "react-s-alert";

import { TAKS_IMPORT } from "../../../config/lang";

import { call, httpRequest } from "../../../util/action";
import { getStore } from "../../../util/redux/store";
import { translate } from "../../../util/util";

const me = getStore().getState().me;
export const UploadLogs = () => {
  const [uploadLogs, setUploadLogs] = useState([]);

  useEffect(() => {
    getUploadLogs();
  }, []);

  const getUploadLogs = (nextLink = null, page = 1) =>
    httpRequest({ method: "GET", url: nextLink || `actions/?page=${page}` }, async response => {
      const data = await response.json();

      // if (data.next) {
      //   getUploadLogs(undefined, page + 1);
      // }

      setUploadLogs(prevState => {
        return [...prevState, ...data?.results];
      });
    });

  /*
   * @params data any
   * @return string
   */
  const getAMatrixLogData = data => {
    let preparedLogData = "";
    let points = [];
    let productCodes = [];
    const table = [];

    data?.invalid_matrices.map((item, index) => {
      points.push(item.point);
      productCodes.push(...item.products);
    });
    productCodes = [...new Set(productCodes)];
    preparedLogData = `,${points.join()}\n`;

    productCodes?.map(code => {
      let row = [];

      row.push(String(code));
      data?.invalid_matrices.map((item, index) => {
        if (item.products?.includes(code)) {
          row.push("1");
        } else {
          row.push("");
        }
      });
      table.push(row.join());
    });

    preparedLogData += `${table.join("\n")}`;
    // console.log("table", table);
    // console.log("productCodes", productCodes);
    // console.log("preparedLogData", preparedLogData);

    return preparedLogData;
  };

  /*
   * @params data any
   * @return string
  //  */
  // const testData = {
  //   end: "2021-12-25T00:00",
  //   point: "2000",
  //   start: "2021-11-25T00:00",
  //   title: "Dmytro import Test 6",
  //   network: "",
  //   description: "Dmytro Import Description 6",
  //   validation_error: {
  //     point: ["This field cannot be blank."],
  //   },
  // };
  const geTaskImportLogData = data => {
    console.log(data);
    let preparedLogData = "";
    if (data.invalid_tasks?.length) {
      preparedLogData = "Title,Description,Start date,End Date, Point, Network\n";
      preparedLogData += data.invalid_tasks
        .map(logItem => `${logItem.title},${logItem.start},${logItem.end},${logItem.description},${logItem.point},${logItem.network}\n`)
        .join("");
    }

    return preparedLogData;
  };

  const downloadLogs = (data, dataType, fileName, fileType) => {
    let logData = "";

    switch (dataType) {
      case "ASSORTMENT_MATRIX_CREATION":
        logData = getAMatrixLogData(data);
        break;
      case "TASKS_CREATION":
        logData = geTaskImportLogData(data);
        break;
      default:
        console.log(data);
    }

    if (!logData) return;

    let file = new Blob([logData], { type: fileType });
    if (window.navigator.msSaveOrOpenBlob)
      // IE10+
      window.navigator.msSaveOrOpenBlob(file, fileName);
    else {
      // Others
      let a = document.createElement("a"),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      setTimeout(function() {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  };

  const deleteImport = id => {
    call({ method: "DELETE", url: `actions/${id}/delete_action_data/` }, r => {
      Alert.success(translate(TAKS_IMPORT, "download_was_delete_msg", me.language), { position: "bottom-right" });
      let updatedLogs = [...uploadLogs];
      updatedLogs = uploadLogs.map(item => (item.id === id ? { ...item, status: "deleted" } : item));
      // _.remove(updatedLogs, item => item.id === id);
      setUploadLogs(updatedLogs);
    });
  };

  return uploadLogs.length ? (
    <div className="box p-4">
      <div className="upload-logs-container">
        <div className="row-title">{translate(TAKS_IMPORT, "name_title", me.language)}</div>
        <div className="row-title">{translate(TAKS_IMPORT, "type_tasks_title", me.language)}</div>
        <div className="row-title">{translate(TAKS_IMPORT, "download_date_title", me.language)}</div>
        <div className="row-title">{translate(TAKS_IMPORT, "loading_errors_title", me.language)}</div>
        <div className="row-title upload-logs-row-small">{translate(TAKS_IMPORT, "status", me.language)}</div>
        <div className="row-title upload-logs-row-small upload-logs-remove">{translate(TAKS_IMPORT, "remove_btn", me.language)}</div>
        <div className={"flex-break-line"} />
        {uploadLogs?.map((item, index) => (
          <React.Fragment key={index}>
            <div>{item.name || "—"}</div>
            <div>{item.type || "—"}</div>
            <div>{item.created ? moment(item.created).format("DD.MM.yyyy HH:MM") : "—"}</div>
            <div>
              {(item.type !== "TASKS_CREATION" && !_.isEmpty(item.logs)) || (item.type === "TASKS_CREATION" && item.logs.invalid_tasks?.length) ? (
                <a
                  onClick={e => {
                    e.preventDefault();
                    downloadLogs(item.logs, item.type, `${item.created ? moment(item.created).format("DD.MM.yyyy HH:MM") : "upload"}-logs.csv`, "csv");
                  }}>
                  {translate(TAKS_IMPORT, "download_btn", me.language)}
                </a>
              ) : (
                "—"
              )}
            </div>
            <div className="upload-logs-row-small">
              {item.status ? <span className={`label rounded ${item.status?.replace(" ", "-")}`}>{item.status}</span> : "—"}
            </div>
            <div className="upload-logs-row-small upload-logs-remove">
              {
                <span className="faIcon" onClick={() => deleteImport(item.id)}>
                  <i className="fa fa-trash"></i>
                </span>
              }
            </div>
            <div className={"flex-break-line"} />
          </React.Fragment>
        ))}
      </div>
    </div>
  ) : null;
};
