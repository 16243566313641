import React, { Component } from "react";

import { TableHeaderColumn } from "react-bootstrap-table";
import EntityTable from "../../component/EntityTable";

import Modal from "../../component/Modal";

import { openRoute, day, pointsCount, merchandiserName } from "../../util/formatter";

class List extends Component {
  open = (id, item) => {
    // return <a href="#" className="btn btn-sm btn-outline-primary"> <i className='fa fa-external-link can_open' /> View </a>
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="box px-4 py-1">
          <h5 className="mt-3 mb-3">
            <span className="faIcon">
              {" "}
              <i className="fa fa-route" />{" "}
            </span>{" "}
            Routes
          </h5>
        </div>

        <div className="box p-4">
          <EntityTable entity="route">
            <TableHeaderColumn dataField="id" dataFormat={this.open} isKey width="82px"></TableHeaderColumn>
            <TableHeaderColumn dataFormat={merchandiserName}>Merchandiser</TableHeaderColumn>
            <TableHeaderColumn dataFormat={day}>Day</TableHeaderColumn>
            <TableHeaderColumn dataFormat={pointsCount}>Amount of points</TableHeaderColumn>
          </EntityTable>
        </div>
      </div>
    );
  }
}

export default List;
