import { Visit } from "/src/models/visit";
import { PHOTO_REVIEW_IMAGE_URL_BASE } from "../config/config";
import { isValidUrl } from "../util/is-valid-url";

export class PhotoReview {
  constructor(data) {
    this.id = data.id; // number
    this.additional_info = data.additional_info || null; // object
    this.ticket = data.ticket || ""; // string
    this.ticket_additional_info = data.ticket_additional_info || null; // object
    this.tip = data.tip || ""; // string
    this.photo = data.url_photo && isValidUrl(data.url_photo) ? data.url_photo : ""; // string ${PHOTO_REVIEW_IMAGE_URL_BASE}
    this.visit = data.visit && new Visit(data.visit);
    this.visit_id = data.visit_id; // string
  }
}
