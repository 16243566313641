import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'

import { reducer } from './reducer'
import { loadState } from './localStorage'

export function getStore(){
  const persistedState = loadState()
  const store = createStore(reducer, persistedState, composeWithDevTools(applyMiddleware(thunkMiddleware)))
  if (module.hot) {
    module.hot.accept('../reducers', () => {
      store.replaceReducer(reducer)
    })
  }
  return store
}
