import { actionTypes } from "./constants";

export const login = user => dispatch => dispatch({ type: actionTypes.LOGIN, body: user });

export const register = user => dispatch => dispatch({ type: actionTypes.REGISTER, body: user });

export const logout = () => dispatch => dispatch({ type: actionTypes.LOGOUT });

export const update_me = user => dispatch => dispatch({ type: actionTypes.UPDATE_ME, body: user });

export const setDefaultTemplate = template => dispatch => dispatch({ type: actionTypes.SET_DEFAULT_TEMPLATE, body: template });
