import React from 'react'
import './visits.css'

import avatar from "../../../../../assets/img/image/avatar.png"
import avatar2 from "../../../../../assets/img/image/avatar2.png"
import avatar3 from "../../../../../assets/img/image/avatar3.png"

import {User} from "../../../dashboard/_/user/User";
// const avatar={};
// const avatar2={};
// const avatar3={};

const users = [
    {id:1, avatar: avatar , name: 'OsinniiD', location: 'Watsons, Воздвиженська', time_start: '14:19', time_end: '14:35', status: 'yellow', num1: 419, num2: 420 },
    {id:2, avatar: avatar2 , name: 'OsinniiD', location: 'Watsons, Воздвиженська', time_start: '14:21', time_end: '14:27', status: 'green', num1: 419, num2: 420 },
    {id:3, avatar: avatar3 , name: 'OsinniiD', location: 'Watsons, Воздвиженська', time_start: '14:21', time_end: '14:22', status: 'green', num1: 419, num2: 420 },
    {id:4, avatar: avatar , name: 'OsinniiD', location: 'Watsons, Воздвиженська', time_start: '14:54', time_end: '14:55', status: 'yellow', num1: 419, num2: 420 },
    {id:5, avatar: avatar2 , name: 'OsinniiD', location: 'Watsons, Воздвиженська', time_start: '14:12', time_end: '15:15', status: 'yellow', num1: 419, num2: 420 },
    {id:6, avatar: avatar3 , name: 'OsinniiD', location: 'Watsons, Воздвиженська', time_start: '14:19', time_end: '14:25', status: 'green', num1: 419, num2: 420 }
];

export const Visits = () =>{
    return(
        <div className="default_card">
            <h5>Visits</h5>
            {
                users.map((item) => <User item={item}/>)
            }
        </div>
    )
}