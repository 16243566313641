
export const actionTypes = {
  LOGIN: "LOGIN",
  REGISTER: "REGISTER",
  LOGOUT: "LOGOUT",
  UPDATE_ME: "UPDATE_ME",
  SET_DEFAULT_TEMPLATE: "SET_DEFAULT_TEMPLATE",
};

export const initialState = { me: undefined, defaultTemplate: null };
