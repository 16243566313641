import React, { Component } from "react";
import { DatePicker } from "/src/component/general/DatePicker";
import { formatDistanceToNowStrict } from "date-fns";
import EXIF from "exif-js";

import EntityDropdown from "../../component/EntityDropdown";
import Downloader from "../../component/general/Downloader/Downloader";
import { LazyLoading } from "../../component/general/LazyLoading";
import Loading from "../../component/Loading";
import PhotoTaskSlider from "../../component/PhotoTaskSlider";
import PointFilter from "../../component/PointFilter";
import { ENTITY_DROPDOWN_SETTINGS, PHOTO_REVIEW } from "../../config/lang";

import { classNames } from "../../util/classNames";
import { translate } from "../../util/util";

import "./style.scss";

class Table extends Component {
  constructor(props) {
    super(props);
    this.imgRefs = [];
    this.myGroupBtnRef = React.createRef();
    this.mySingleBtnRef = React.createRef();

    this.containerRefs = [];
    this.state = {
      overflowStates: [],
    };

    this.state = {
      loading: false,
      loadedImages: [],
      selectedImages: [],
      exportMode: false,
      isActiveAccordion: false,
      isIOS: false,
      imdData: [],
      photoIndex: 0,
      gridModeView: "single",
    };
  }

  componentDidMount() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    this.checkOverflow();

    if ((/iPad|iPhone|iPod|Macintosh|Mac OS/.test(userAgent) && !window.MSStream) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) {
      this.setState({ isIOS: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.checkOverflow();
    }
  }

  checkOverflow = () => {
    const { data } = this.props;
    if (Array.isArray(data)) {
      if (this.state.overflowStates?.length !== data.length) {
        this.setState({
          overflowStates: new Array(data.length).fill(false),
        });
      }

      const overflowStates = data.map((item, index) => {
        const container = this.containerRefs[index];
        return container && container.offsetWidth > 188;
      });

      this.setState({ overflowStates });
    }
  };

  loadPhotoReviewFull = index => {
    let loadedImagesArr = this.state.loadedImages;
    loadedImagesArr[index] = true;
    // this.imgRefs[index] = src;
    // this.handleExImg(index);
    this.setState({
      loadedImages: loadedImagesArr,
    });
  };

  loadProductsList = (event, entity) => {
    // event: Event, entity: string
    this.props.changeFilter(entity, event);
  };

  handleSelectedImages = (e, index, url) => {
    let selectedImages = this.state.selectedImages;
    selectedImages[index] = e.target?.checked ? url : undefined;
    this.setState({
      selectedImages,
    });
  };
  handleExportMode = () => {
    this.setState(
      prevState => ({
        exportMode: !prevState.exportMode,
      }),
      () => {
        console.log("exportMode", this.state.exportMode);
      }
    );
  };

  handlerAccordion = () => {
    this.setState({ isActiveAccordion: !this.state.isActiveAccordion });
  };

  handleExImg = index => {
    let orientation = null;
    let thumbnail = null;

    const self = this;
    EXIF.getData(this.imgRefs[index], function() {
      orientation = EXIF.getTag(this, "Orientation");
      thumbnail = EXIF.getTag(this, "thumbnail");
      if (JSON.stringify(orientation) !== "6" && JSON.stringify(thumbnail?.Orientation) == "6") {
        self.setState(prevState => ({
          imdData: [...prevState.imdData, Number(index)],
        }));
      }
    });
  };

  checkIosImgInArr = index => {
    return [...new Set(this.state.imdData)].includes(Number(index)) ? true : false;
  };

  translate = (name, entity = ENTITY_DROPDOWN_SETTINGS) => {
    const lang = this.props.lang ? this.props.lang : "en";
    return translate(entity, name, lang);
  };

  handleGridChangeButtonClick = (e, type) => {
    // e.target.className += " btn-active";
    //reset loadedImages data before change view type
    if (this.state.gridModeView === type) return;
    e.target.closest(".switch-view").setAttribute("data-view-type", type);
    this.props.gridPhotoView(type);

    if (type === "single") {
      this.myGroupBtnRef.current.classList.remove("btn-active");
      this.mySingleBtnRef.current.classList.add("btn-active");
    } else {
      this.mySingleBtnRef.current.classList.remove("btn-active");
      this.myGroupBtnRef.current.classList.add("btn-active");
    }

    this.setState({ gridModeView: type, loading: true });
  };

  activePhotoIndex = (index = 0) => {
    this.setState({ photoIndex: index });
    return index;
  };

  render() {
    const {
      data,
      openTask,
      filter,
      changeFilter,
      resetFilter,
      createNewFilter,
      addNewFilterName,
      list,
      removeUserFilterPopup,
      acceptRejectAnswer,
    } = this.props;
    const { selectedImages, loadedImages, exportMode } = this.state;
    const { overflowStates } = this.state;

    return (
      <div className="row photo-review">
        <div className="col-md-12 mb-2 filter-bar boxForm">
          <div className="accordion-static-item">
            <div className="filter-first-line">
              <div className="filter-datepicker">
                <DatePicker selected={new Date(filter.start)} className="datePicker mr-2" onChange={date => changeFilter("start", date)} />
                <DatePicker selected={new Date(filter.end)} className="datePicker mr-2" onChange={date => changeFilter("end", date)} />
              </div>
              <EntityDropdown
                entity="filters"
                value={filter?.filters?.id}
                updatedList={list}
                onChange={o => {
                  changeFilter("filters", o);
                }}
              />
              <EntityDropdown
                entity="clients"
                placeholder={this.translate("clients")}
                value={filter.clients}
                onChange={o => {
                  changeFilter("clients", o);
                }}
              />
              <EntityDropdown
                entity="merchandisers"
                value={filter.users}
                onChange={o => {
                  changeFilter("users", o);
                }}
                className="react-dropdown-container--multiselect"
                isMulti
              />
              <EntityDropdown
                entity="network"
                value={filter.point__network}
                onChange={o => {
                  changeFilter("point__network", o);
                }}
              />
            </div>
            <PointFilter
              saveValue={filter.county}
              value={filter.points}
              onChange={o => {
                changeFilter("points", o);
              }}
              onChangeRegion={o => {
                changeFilter("county", o);
              }}
              className="react-dropdown-container--multiselect"
              isMulti
              moduleFilter={filter.point__network}
            />
          </div>
          <div className={`accordion-item ${this.state.isActiveAccordion ? "active" : ""}`}>
            <div className="accordion-title" onClick={this.handlerAccordion}>
              {this.translate("additional_filters")}
            </div>
            <div className={`accordion-item-content ${this.state.isActiveAccordion ? "" : "hidden"}`}>
              <EntityDropdown
                entity="tags"
                value={filter?.tags}
                onChange={o => {
                  changeFilter("tags", o);
                }}
                className="react-dropdown-container--multiselect"
                isMulti
              />

              <EntityDropdown
                entity="brands"
                value={filter?.brands}
                onChange={o => {
                  changeFilter("brands", o);
                }}
                className="react-dropdown-container--multiselect"
                isMulti
              />

              <input
                type="text"
                className="form-control searchInput searchInputPhoto"
                placeholder={this.translate("comment")}
                value={filter.comment}
                onChange={e => {
                  changeFilter("comment", e.target.value);
                }}
              />

              <EntityDropdown
                entity="products"
                value={filter?.products}
                asynch
                // onInputChange
                onChange={o => {
                  this.loadProductsList(o, "products");
                }}
                className="custom-products react-dropdown-container--multiselect"
                isMulti
              />

              <EntityDropdown
                entity="subcategories"
                value={filter?.subcategories}
                onChange={o => {
                  changeFilter("subcategories", o);
                }}
                className="custom-subcategories react-dropdown-container--multiselect"
                isMulti
              />
            </div>
          </div>
          <div className="filter-navigations">
            <button className="btn btn-primary mr-1 mb-0" onClick={this.props.submit}>
              {this.translate("apply")}
            </button>
            <button className="btn btn-outline-primary mr-1 mb-0" onClick={resetFilter}>
              {this.translate("filter_reset")}
            </button>
            {this.state.gridModeView === "single" ? (
              <Downloader
                options={{
                  url: data[0]?.photo,
                  urls: data?.map(img => img.photo[0]),
                  selectedImages: selectedImages?.filter(url => !!url),
                  disabled: !loadedImages?.length,
                  handleExportMode: this.handleExportMode,
                  exportMode,
                }}
              />
            ) : null}
            <div className={`save-filter-nav ${filter.isChange ? "visible" : "hidden"}`}>
              <input
                type="text"
                className="form-control custom-filter-name mb-0"
                placeholder={this.translate("filter_name_placeholder")}
                value={filter?.newFilter}
                onChange={e => {
                  addNewFilterName(e.target.value);
                }}
              />
              <button
                className="btn btn-outline-primary btn-save-custom mr-1 mb-0"
                onClick={() => {
                  createNewFilter("filters", filter?.newFilter);
                }}>
                {this.translate("filter_save")}
              </button>
              <button
                className="btn btn-outline-primary primary-red mr-1 mb-0"
                onClick={() => {
                  removeUserFilterPopup("filters");
                }}>
                {this.translate("filter_remove")}
              </button>
            </div>
            <label htmlFor="photo-filter-view" className="switch-view" data-view-type={this.state.gridModeView} aria-label="Toggle Photo Filter">
              <span className="switch-btn" ref={this.mySingleBtnRef} onClick={e => this.handleGridChangeButtonClick(e, "single")}>
                {this.translate("photo_review_type_single")}
              </span>
              <span className="switch-btn" ref={this.myGroupBtnRef} onClick={e => this.handleGridChangeButtonClick(e, "group")}>
                {this.translate("photo_review_type_group")}
              </span>
            </label>
          </div>
        </div>
        <div className="col-md-12 p-4 photo-review-list">
          {data.map((item, index) => {
            const tags = Array.isArray(item?.additional_info?.tags)
              ? item?.additional_info?.tags?.map(i => (
                  <span key={i} className="label rounded">
                    {i}
                  </span>
                ))
              : typeof item?.additional_info?.tags === "string"
              ? item?.additional_info?.tags
              : "";

            const isOverflowing = overflowStates[index] !== undefined ? overflowStates[index] : false;
            return (
              <div
                key={index}
                className={`photo-review-item${exportMode ? " photo-review-item--export-mode" : ""}${
                  index === this.props.currentIndex ? " photo-review-item--active" : ""
                }`}
                title={`/photo-review/${item.id}/view`}>
                <div className="root-container">
                  <Loading loading={!loadedImages?.[index]} disP={true} />
                  <div className={classNames("photo-review-item-wrapper", index === this.props.currentIndex && "photo-review-item-wrapper--active")}>
                    <PhotoTaskSlider
                      activePhotoIndex={this.activePhotoIndex}
                      photos={item.photo}
                      clickCallback={photoIndex => {
                        if (loadedImages[index] && !exportMode) {
                          openTask(item, false, photoIndex);
                        }
                      }}
                      isDeepArray={true}
                      loadPhotoReviewFn={() => this.loadPhotoReviewFull(index)}
                      viewMode={this.state.gridModeView}
                    />
                    {this.state.gridModeView === "single" ? (
                      <div className="details-buttons-container single-details-buttons">
                        <div className={`allowed-button ${item.approval?.is_approved === true ? "is-approval" : ""}`}>
                          <button
                            className="btn btn-primary acceptBtn"
                            title={this.translate("approv_btn", PHOTO_REVIEW)}
                            onClick={() => {
                              acceptRejectAnswer("ACCEPT", item, true);
                            }}>
                            <i className="fa fa-check-circle" />
                          </button>
                        </div>
                        <div className={`cancel-button ${item.approval?.is_approved === false ? "not-approval" : ""}`}>
                          <button
                            className="btn btn-primary rejectBtn"
                            title={this.translate("reject_btn", PHOTO_REVIEW)}
                            onClick={() => {
                              openTask(item, true, 0);
                            }}>
                            <i className="fa fa-times-circle" />
                          </button>
                        </div>
                      </div>
                    ) : null}
                    {this.state.gridModeView === "group" ? (
                      <div className="details-buttons-container">
                        <div className={`allowed-button ${item.approval?.is_approved === true ? "is-approval" : ""}`}>
                          <button
                            className="btn btn-primary acceptBtn"
                            onClick={() => {
                              acceptRejectAnswer("ACCEPT", item, true);
                            }}
                            title={this.translate("approv_btn", PHOTO_REVIEW)}>
                            <i className="fa fa-check-circle" />
                          </button>
                        </div>
                        <div className={`cancel-button ${item.approval?.is_approved === false ? "not-approval" : ""}`}>
                          <button
                            className="btn btn-primary rejectBtn"
                            onClick={() => {
                              if (loadedImages[index] && !exportMode) {
                                openTask(item, true, this.state.photoIndex); // for quic open reject order from grid
                              }
                            }}
                            title={this.translate("reject_btn", PHOTO_REVIEW)}>
                            <i className="fa fa-times-circle" />
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div
                    className="review-item"
                    onClick={() => {
                      if (loadedImages[index] && !exportMode) {
                        openTask(item, false, 0);
                      }
                    }}>
                    <div className="review-item-text">
                      <p className="photo-review-item-content first-item">{item?.visit?.point?.locality?.name}</p>
                      <p className="photo-review-item-content">{item?.visit?.point?.name}</p>
                    </div>
                    {item.approval?.is_approved != null ? (
                      <div className={`status-square ${item.approval?.is_approved === true ? "approved" : "rejected"}`}>
                        {item.approval?.is_approved === true ? "✔" : "✖"}
                      </div>
                    ) : null}
                  </div>

                  <div
                    className="tags-form-group tags-group-content"
                    onClick={() => {
                      if (loadedImages[index] && !exportMode) {
                        openTask(item, false, 0);
                      }
                    }}>
                    <div className="tags-container">
                      <div ref={el => (this.containerRefs[index] = el)}>
                        {tags.map((tag, idx) => (
                          <span key={idx} className="tag-label">
                            <span>{tag}</span>
                          </span>
                        ))}
                      </div>
                    </div>
                    {isOverflowing && (
                      <span className="tag-ellipsis">
                        <span>...</span>
                      </span>
                    )}
                  </div>

                  <div
                    className="visit-info"
                    onClick={() => {
                      if (loadedImages[index] && !exportMode) {
                        openTask(item, false, 0);
                      }
                    }}>
                    <span className="user-name">
                      {item?.visit?.user?.first_name} {item?.visit?.user?.last_name}
                    </span>
                    <span className="time-elapsed">{formatDistanceToNowStrict(new Date(item?.visit?.start), { addSuffix: true })}</span>
                  </div>
                </div>

                {exportMode && (
                  <label className="photo-review-item-to-download">
                    <input
                      type="checkbox"
                      name={`photo-review-selected[${index}]`}
                      className="form-filter-checkbox"
                      checked={selectedImages[index]}
                      value={selectedImages[index]}
                      onChange={e => {
                        this.handleSelectedImages(e, index, item.photo[0]);
                      }}
                    />
                  </label>
                )}
              </div>
            );
          })}
        </div>

        {!this.props.loading && (
          <>
            <LazyLoading
              loading={false}
              onScroll={async () => {
                this.props.updatePaging();
              }}
            />
          </>
        )}
      </div>
    );
  }
}

export default Table;
