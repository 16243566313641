import React, { Component } from "react";
import { DatePicker } from "/src/component/general/DatePicker";
import EXIF from "exif-js";

import EntityDropdown from "../../component/EntityDropdown";
import Downloader from "../../component/general/Downloader/Downloader";
import { LazyLoading } from "../../component/general/LazyLoading";
import Loading from "../../component/Loading";
import PointFilter from "../../component/PointFilter";
import { ENTITY_DROPDOWN_SETTINGS } from "../../config/lang";

import { classNames } from "../../util/classNames";
import { translate } from "../../util/util";

class Table extends Component {
  constructor(props) {
    super(props);
    this.loadPhotoReview = this.loadPhotoReview.bind(this);
    this.imgRefs = [];

    this.state = {
      loadedImages: [],
      selectedImages: [],
      exportMode: false,
      isActiveAccordion: false,
      isIOS: false,
      imdData: [],
    };
  }

  componentDidMount() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if ((/iPad|iPhone|iPod|Macintosh|Mac OS/.test(userAgent) && !window.MSStream) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) {
      this.setState({ isIOS: true });
    }
  }

  loadPhotoReview = (event, src, index) => {
    let loadedImages = this.state.loadedImages;

    loadedImages[index] = true;
    this.handleExImg(index);
    this.setState({
      loadedImages,
    });
  };

  loadProductsList = (event, entity) => {
    // event: Event, entity: string
    this.props.changeFilter(entity, event);
  };

  handleSelectedImages = (e, index, url) => {
    let selectedImages = this.state.selectedImages;
    selectedImages[index] = e.target?.checked ? url : undefined;
    this.setState({
      selectedImages,
    });
  };
  handleExportMode = () => {
    this.setState(
      prevState => ({
        exportMode: !prevState.exportMode,
      }),
      () => {
        console.log("exportMode", this.state.exportMode);
      }
    );
  };

  handlerAccordion = () => {
    this.setState({ isActiveAccordion: !this.state.isActiveAccordion });
  };

  handleExImg = index => {
    let orientation = null;
    let thumbnail = null;

    const self = this;
    EXIF.getData(this.imgRefs[index], function() {
      orientation = EXIF.getTag(this, "Orientation");
      thumbnail = EXIF.getTag(this, "thumbnail");
      if (JSON.stringify(orientation) !== "6" && JSON.stringify(thumbnail.Orientation) == "6") {
        self.setState(prevState => ({
          imdData: [...prevState.imdData, Number(index)],
        }));
      }
    });
  };

  checkIosImgInArr = index => {
    return [...new Set(this.state.imdData)].includes(Number(index)) ? true : false;
  };

  translate = name => {
    const lang = this.props.lang ? this.props.lang : "en";
    return translate(ENTITY_DROPDOWN_SETTINGS, name, lang);
  };

  render() {
    const { data, openTask, filter, changeFilter, resetFilter, createNewFilter, addNewFilterName, list, removeUserFilterPopup } = this.props;
    const { selectedImages, loadedImages, exportMode } = this.state;

    return (
      <div className="row photo-review">
        <div className="col-md-12 mb-2 filter-bar boxForm">
          <div className="accordion-static-item">
            <div className="filter-first-line">
              <div className="filter-datepicker">
                <DatePicker selected={new Date(filter.start)} className="datePicker mr-2" onChange={date => changeFilter("start", date)} />
                <DatePicker selected={new Date(filter.end)} className="datePicker mr-2" onChange={date => changeFilter("end", date)} />
              </div>
              <EntityDropdown
                entity="filters"
                value={filter?.filters?.id}
                updatedList={list}
                onChange={o => {
                  changeFilter("filters", o);
                }}
              />
              <EntityDropdown
                entity="merchandisers"
                value={filter.users}
                onChange={o => {
                  changeFilter("users", o);
                }}
                className="react-dropdown-container--multiselect"
                isMulti
              />
              <EntityDropdown
                entity="network"
                value={filter.point__network}
                onChange={o => {
                  changeFilter("point__network", o);
                }}
              />
            </div>
            <PointFilter
              saveValue={filter.county}
              value={filter.points}
              onChange={o => {
                changeFilter("points", o);
              }}
              onChangeRegion={o => {
                changeFilter("county", o);
              }}
              className="react-dropdown-container--multiselect"
              isMulti
              moduleFilter={filter.point__network}
            />
          </div>
          <div className={`accordion-item ${this.state.isActiveAccordion ? "active" : ""}`}>
            <div className="accordion-title" onClick={this.handlerAccordion}>
              {this.translate("additional_filters")}
            </div>
            <div className={`accordion-item-content ${this.state.isActiveAccordion ? "" : "hidden"}`}>
              <EntityDropdown
                entity="tags"
                value={filter?.tags}
                onChange={o => {
                  changeFilter("tags", o);
                }}
                className="react-dropdown-container--multiselect"
                isMulti
              />

              <EntityDropdown
                entity="brands"
                value={filter?.brands}
                onChange={o => {
                  changeFilter("brands", o);
                }}
                className="react-dropdown-container--multiselect"
                isMulti
              />

              <input
                type="text"
                className="form-control searchInput searchInputPhoto"
                placeholder={this.translate("comment")}
                value={filter.comment}
                onChange={e => {
                  changeFilter("comment", e.target.value);
                }}
              />

              <EntityDropdown
                entity="products"
                value={filter?.products}
                asynch
                // onInputChange
                onChange={o => {
                  this.loadProductsList(o, "products");
                }}
                className="custom-products react-dropdown-container--multiselect"
                isMulti
              />

              <EntityDropdown
                entity="subcategories"
                value={filter?.subcategories}
                onChange={o => {
                  changeFilter("subcategories", o);
                }}
                className="custom-subcategories react-dropdown-container--multiselect"
                isMulti
              />
            </div>
          </div>
          <div className="filter-navigations">
            <button className="btn btn-primary mr-1 mb-1" onClick={this.props.submit}>
              {this.translate("apply")}
            </button>
            <button className="btn btn-outline-primary mr-1 mb-1" onClick={resetFilter}>
              {this.translate("filter_reset")}
            </button>
            <Downloader
              options={{
                url: data[0]?.photo,
                urls: data?.map(img => img.photo),
                selectedImages: selectedImages?.filter(url => !!url),
                disabled: !loadedImages?.length,
                handleExportMode: this.handleExportMode,
                exportMode,
              }}
            />
            <div className={`save-filter-nav ${filter.isChange ? "visible" : "hidden"}`}>
              <input
                type="text"
                className="form-control custom-filter-name"
                placeholder={this.translate("filter_name_placeholder")}
                value={filter?.newFilter}
                onChange={e => {
                  addNewFilterName(e.target.value);
                }}
              />
              <button
                className="btn btn-outline-primary btn-save-custom mr-1 mb-1"
                onClick={() => {
                  createNewFilter("filters", filter?.newFilter);
                }}>
                {this.translate("filter_save")}
              </button>
              <button
                className="btn btn-outline-primary primary-red mr-1 mb-1"
                onClick={() => {
                  removeUserFilterPopup("filters");
                }}>
                {this.translate("filter_remove")}
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-12 p-4 photo-review-list">
          {data.map((item, index) => {
            return (
              <div
                key={index}
                className={`photo-review-item${exportMode ? " photo-review-item--export-mode" : ""}`}
                onClick={() => {
                  if (loadedImages[index] && !exportMode) {
                    openTask(item);
                  }
                }}
                title={`/photo-review/${item.id}/view`}>
                <Loading loading={!this.state?.loadedImages?.[index]} disP={true} />
                <div className={classNames("photo-review-item-wrapper", index === this.props.currentIndex && "photo-review-item-wrapper--active")}>
                  <img
                    className={this.checkIosImgInArr(index) && this.state.isIOS ? "ios-photo" : null}
                    src={item.photo}
                    data-img-index={index}
                    ref={ref => (this.imgRefs[index] = ref)}
                    onLoad={event => {
                      this.loadPhotoReview(event, item.photo, index);
                    }}
                  />
                </div>

                <p className="photo-review-item-content">{item?.visit?.point?.name}</p>
                {exportMode && (
                  <label className="photo-review-item-to-download">
                    <input
                      type="checkbox"
                      name={`photo-review-selected[${index}]`}
                      className="form-filter-checkbox"
                      checked={selectedImages[index]}
                      value={selectedImages[index]}
                      onChange={e => {
                        this.handleSelectedImages(e, index, item.photo);
                      }}
                    />
                  </label>
                )}
              </div>
            );
          })}
        </div>

        {!this.props.loading && (
          <>
            <LazyLoading
              loading={false}
              onScroll={async () => {
                this.props.updatePaging();
              }}
            />
          </>
        )}
      </div>
    );
  }
}

export default Table;
