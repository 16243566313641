import React, { Component } from "react";
import Dropzone from "react-dropzone";
import Alert from "react-s-alert";

import { PageHeader, PageHeaderTitle } from "../../component/general/page-header";
import Modal from "./../../component/Modal";
import { DOCUMENTS } from "../../config/lang";

import { call, upload } from "../../util/action";
import { translate } from "../../util/util";
import { dateTimeCreated } from "./../../util/formatter";

class View extends Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      name: "",

      list: [],
      count: 0,
      loading: false,

      errors: [],
      isModal: false,
    };
  }

  onDrop = files => {
    this.setState({ files });
  };

  componentDidMount() {
    this.load();
  }

  load = () => {
    call({ method: "GET", url: "documents/" }, r => {
      this.setState({ list: r.results });
    });
  };

  delete = id => {
    const lang = this.props.currentUser.language;
    call({ method: "DELETE", url: "documents/" + id + "/" }, r => {
      console.log("DELETE", r);
      Alert.success(translate(DOCUMENTS, "file_deleted", lang), { position: "bottom-right" });
      this.load();
    });
  };

  validate = () => {
    let errors = [];
    const lang = this.props.currentUser.language;

    if (!this.state.name) {
      errors.push(translate(DOCUMENTS, "file_name_cannot_be_empty", lang));
    }

    if (!this.state.file) {
      errors.push(translate(DOCUMENTS, "need_to_add_file", lang));
    }

    this.setState({ errors: errors });

    return errors.length == 0;
  };

  submit = () => {
    const lang = this.props.currentUser.language;

    if (this.validate()) {
      var formData = new FormData();
      formData.append("document", this.state.file);
      formData.append("title", this.state.name);

      upload({ method: "POST", url: "documents/", post: formData }, r => {
        Alert.success(translate(DOCUMENTS, "file_successfully_uploaded", lang), { position: "bottom-right" });
        this.setState({ file: null, name: "" });
        this.load();
      });
    }
  };

  uploadFile = acceptedFiles => {
    this.setState({ file: acceptedFiles[0] });
  };

  onChange = (property, value) => {
    let state = this.state;
    state[property] = value;
    this.setState(state);
  };

  modalClose = () => {
    this.setState({ isModal: false });
  };

  render() {
    let { file, isModal } = this.state;
    const files = file && (
      <li key={file.name}>
        {file.name} - {file.size} bytes
      </li>
    );
    const { currentUser } = this.props;
    return (
      <div className="container-fluid">
        {/*<PageHeader>
          <PageHeaderTitle>
            <span className="faIcon">
              <i className="fa fa-file" />
            </span>
            Документи
          </PageHeaderTitle>
        </PageHeader>*/}

        <div className="box p-a-md">
          <div className="item taskBlock">
            <div className="row">
              <div className="col-md-12 text-left">
                <button className="btn btn-primary" onClick={() => this.setState({ isModal: true })}>
                  <i className="fa fa-plus" /> {translate(DOCUMENTS, "add_file", currentUser.language)}
                </button>
              </div>
            </div>

            <div className="row">
              <Modal isShow={isModal} onClose={() => this.modalClose()}>
                <div className="">
                  <div className="col-md-12 mt-3 text-center">
                    <div className="form-group d-inline-block">
                      <label>{translate(DOCUMENTS, "file_name", currentUser.language)}</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={translate(DOCUMENTS, "file_name", currentUser.language)}
                        value={this.state.name}
                        style={{ width: "400px" }}
                        onChange={e => this.onChange("name", e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <Dropzone onDrop={this.uploadFile} styles={{ dropzone: { minHeight: 150, maxHeight: 250 } }}>
                      {({ getRootProps, getInputProps }) => (
                        <section className="dropzone">
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>{translate(DOCUMENTS, "click_or_drag_files_here", currentUser.language)}</p>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    {files}
                  </div>

                  <div className="col-md-12 mt-3">
                    {this.state.errors.map(i => (
                      <div key={i} className="error">
                        {i}
                      </div>
                    ))}
                    <div className="btn btn-primary" onClick={() => this.submit()}>
                      <i className="fa fa-upload" /> {translate(DOCUMENTS, "download_file_btn", currentUser.language)}
                    </div>
                  </div>
                </div>
              </Modal>

              <div className="col-md-12 mt-3">
                <table className="fileTable">
                  <thead>
                    <tr className="header">
                      <td className="fileName">{translate(DOCUMENTS, "file_name", currentUser.language)}</td>
                      <td className="">{translate(DOCUMENTS, "creation_time", currentUser.language)}</td>
                      <td> </td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.list.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {" "}
                            <a target="_blank" href={item.document}>
                              {item.title}
                            </a>
                          </td>
                          <td> {dateTimeCreated(item)} </td>
                          <td>
                            <a target="_blank" href={item.document}>
                              {" "}
                              <div className="deleteFile">
                                <i className="fa fa-download" />
                              </div>
                            </a>
                            <div className="deleteFile" onClick={() => this.delete(item.id)}>
                              <i className="fa fa-trash" />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default View;
