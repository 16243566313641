import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Alert from "react-s-alert";

import EntityDropdown from "../../component/EntityDropdown";
import {LangUpdateContext} from "../../layout/LangContext"
import Avatar from "../../../assets/image/avatar.jpg";
import { MANAGER_PROFILE } from "../../config/lang";

import { call } from "../../util/action";
import { update_me } from "../../util/redux/actions";
import {translate} from "../../util/util";

class Edit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      item: {
        first_name: "",
        last_name: "",
        email: "",
        county: "",
        telegram_id: "",
        position: "",
      },
    };
  }
  static contextType = LangUpdateContext;

  componentDidMount() {
    const { item } = this.state;
    const { me } = this.props;
    this.setState({ item: { ...item, ...me } });
  }

  onChange = (property, value) => {
    let item = this.state.item;
    item[property] = value;
    this.setState({ item: item });
  };

  translate = name => {
    const lang = this.state.item.language;
    return translate(MANAGER_PROFILE, name, lang);
  };

  submit = () => {
    const { dispatch } = this.props;

    let { item } = this.state;

    delete item.photo;
    //delete item.password;
    //delete item.password2;

    if (item.county && item.county.name == "") {
      delete item.county;
    }

    call({ method: "PUT", url: "users/" + item.id + "/", post: item }, r => {
      if (r && r.id) {
        Alert.success("Дані успішно збережені", { position: "bottom-right" });
        console.log(r);
        let m = Object.assign(this.props.me, r);
        dispatch(update_me(m));
        this.context.updateLangFn();
      } else {
        Alert.warning("Error while saving item. Error: " + JSON.stringify(r), { position: "bottom-right" });
        console.log(r);
      }
    });
  };

  render() {
    let { item } = this.state;
    
    return (
      <div className="container-fluid">
        <div className="box px-4 py-1">
          <h5 className="mt-3 mb-3">
            <i className="fa fa-user" /> {this.translate("my_profile")}
          </h5>
        </div>

        <div className="box p-4">
          <div className="item merchandiserBlock p-a-md">
            <div className="box boxEdit box-body boxForm">
              <div className="row m-t">
                <div className="col-md-12">
                  <span className="avatar avatarProfile">
                    <img src={item && item.photo ? item.photo : Avatar} />
                  </span>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>{this.translate("name")}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={this.translate("name")}
                      value={item.first_name}
                      onChange={e => this.onChange("first_name", e.target.value)}
                    />
                  </div>

                  {/* <div className="form-group">
                    <label>E-mail</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="E-mail"
                      value={item.email}
                      onChange={e => this.onChange("email", e.target.value)}
                    />
                  </div> */}
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>{this.translate("surname")}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={this.translate("surname")}
                      value={item.last_name}
                      onChange={e => this.onChange("last_name", e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <label>{this.translate("position")}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={this.translate("position")}
                      value={item.position}
                      onChange={e => this.onChange("position", e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>{this.translate("my_region")}</label>
                    <EntityDropdown
                      entity="county"
                      value={item.county && item.county.id ? item.county.id : item.county}
                      onChange={e => this.onChange("county", e?.id)}
                    />
                  </div>

                  <div className="form-group">
                    <label>{this.translate("telegram")}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Telegram"
                      value={item.telegram_id}
                      onChange={e => this.onChange("telegram_id", e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>{this.translate("language")}</label>
                    <EntityDropdown
                      entity="language"
                      id={item.id ? item.id : null}
                      value={item.id && item.language ? item.language : 1}
                      onChange={e => this.onChange("language", e?.code)}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  {/* <div className="form-group">
                                    <label>Пароль</label>
                                    <input type="text" className="form-control" placeholder="Пароль" value={this.state.password} onChange={(e)=>this.onChange('password', e.target.value)} />
                                </div> */}
                </div>

                <div className="col-md-6">
                  {/* <div className="form-group">
                                            <label>Повторити Пароль</label>
                                            <input type="text" className="form-control" placeholder="Повторити Пароль" value={this.state.password2} onChange={(e)=>this.onChange('password2', e.target.value)}/>
                                </div> */}
                </div>
              </div>

              <div className="col-md-12">
                <div className="btn btn-primary white m-b" onClick={() => this.submit()}>
                  <i className="fa fa-save" /> {this.translate("save")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(state => ({ me: state.me }))(Edit));
