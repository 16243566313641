export class Visit {
  constructor(data) {
    this.id = data.id; // string
    this.meta = data.meta; // string (included an array of coordinates)
    this.point = data.point; // object
    this.start = data.start; // string
    this.end = data.end; // string
    this.user = data.user;
  }
}
