import React from "react";

import Loading from "../Loading";
import Modal from "../Modal";

import "./styles.scss";

export default class ImageCanvasEditor extends React.Component {
  constructor(props) {
    super(props);

    this.imageEditorRef = React.createRef();
    this.canvasLayerArrow = React.createRef();
    // this.testRef = React.createRef();
    // this.test = React.createRef();
    this.typesDraw = {
      line: "line",
      rect: "rect",
      text: "text",
      arrow: "arrow",
      curve: "curve",
    };

    this.maxWidth = 325.33;

    this.state = {
      canvas: null,
      ctx: null,
      img: {
        src: "",
        width: 0,
        height: 0,
      },
      typeDraw: this.typesDraw.curve,
      cursor: {
        x: 0,
        y: 0,
      },
      resultImg: null,
      isDown: false,
      startX: 0,
      startY: 0,
      textContainer: null,
      modeEditText: false,
      isOpenModal: false,
      imageData: null, // save current canvas pixels while redraw shapes
      canvasLayer: null,
      canvasLayerCtx: null,
      canvasImgLoading: false,
      pathsry: [],
      points: [],
    };

    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
  }

  componentDidMount() {
    if (this.state.isOpenModal) {
      this.initCanvasEditor(); // TODO split init to some parts
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const { isOpenModal } = this.state;
    if (prevState.isOpenModal !== isOpenModal && isOpenModal) {
      await this.initCanvasEditor();
    }
  }

  initCanvasEditor = async () => {
    const img = new Image();
    const cachedSrc = `${this.props.src}?chunk=cached`;
    let isImageCached = false;
    let chunksCached = [];

    img.crossOrigin = "anonymous";

    try {
      const currentChunksCached = JSON.parse(localStorage.getItem("chunks"));
      isImageCached = currentChunksCached?.length && currentChunksCached.includes(cachedSrc);

      if (!isImageCached) {
        chunksCached.push(cachedSrc);
        localStorage.setItem("chunks", JSON.stringify(chunksCached));
      }

      img.src = cachedSrc;
      this.setState({ canvasImgLoading: true });

      img.addEventListener("load", () => {
        this.loadCanvasData(img);
      });
    } catch (error) {
      console.dir(error);

      img.src = `${this.props.src}?chunk=${new Date().getTime()}`; // reload new image from cdn to fix server's restriction
      this.setState({ canvasImgLoading: true });

      img.addEventListener("load", () => {
        this.loadCanvasData(img);
      });
    }
  };

  loadCanvasData = img => {
    console.log(img);
    console.log(img.width);
    console.log(img.height);
    try {
      const width = img.width > this.maxWidth ? this.maxWidth : img.width;
      const height = img.width > this.maxWidth ? img.height / (img.width / this.maxWidth) : img.height;
      /*       const width = 325;
      const height = "100%"; */
      const canvas = this.imageEditorRef.current;
      const ctx = canvas?.getContext("2d");
      const canvasLayer = this.canvasLayerArrow.current;
      const canvasLayerCtx = canvasLayer?.getContext("2d");
      const textContainer = document.createElement("div");

      img.width = width;
      img.height = height;
      textContainer.contentEditable = "true";
      textContainer.classList.add("image-editor-text-container");
      textContainer.style.display = "none";
      canvas?.parentNode?.insertBefore(textContainer, canvas);

      this.setState(
        () => {
          return {
            img,
            canvas,
            ctx,
            textContainer,
            canvasLayer,
            canvasLayerCtx,
          };
        },
        () => {
          this.draw(img);
        }
      );
      this.setState({ canvasImgLoading: false });
    } catch (e) {
      console.error({ ...e });
    }
  };

  draw = img => {
    const { canvas, ctx, canvasLayerCtx } = this.state;

    ctx.drawImage(img, 0, 0, this.state.img?.width, this.state.img?.height);
    ctx.strokeStyle = "red";
    ctx.lineWidth = 3;
    ctx.lineCap = "round";

    canvasLayerCtx.strokeStyle = "red";
    canvasLayerCtx.lineWidth = 3;
    canvasLayerCtx.lineCap = "round";

    ctx.save();

    this.setState({
      resultImg: canvas.toDataURL(),
    });
  };

  drawRect = (x, y, w, h) => {
    const { canvasLayerCtx: ctx, canvasLayer: canvas } = this.state;
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.strokeRect(x, y, w, h);
    ctx.save();
  };

  drawText = (text = "", x = 0, y = 0) => {
    const { canvas, ctx } = this.state;

    ctx.fillStyle = "red";
    ctx.font = "20px / 1.3 sans-serif";
    ctx.textBaseline = "hanging";
    ctx.lineWidth = 1;
    ctx.fillText(text, x, y + 5);
    ctx.save();
  };

  drawLineWithArrowhead = (p0, p1, headLength) => {
    const { canvasLayerCtx: ctx, canvasLayer: canvas } = this.state;

    let PI = Math.PI;
    let degreesInRadians225 = 1.1 * PI;
    let degreesInRadians135 = 0.9 * PI;

    // calc the angle of the line
    let dx = p1.x - p0.x;
    let dy = p1.y - p0.y;
    let angle = Math.atan2(dy, dx);

    // calc arrowhead points
    let x225 = p1.x + headLength * Math.cos(angle + degreesInRadians225);
    let y225 = p1.y + headLength * Math.sin(angle + degreesInRadians225);
    let x135 = p1.x + headLength * Math.cos(angle + degreesInRadians135);
    let y135 = p1.y + headLength * Math.sin(angle + degreesInRadians135);

    ctx.save();
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // draw line plus arrowhead
    ctx.beginPath();
    // draw the line from p0 to p1
    ctx.moveTo(p0.x, p0.y);
    ctx.lineTo(p1.x, p1.y);
    // draw partial arrowhead at 225 degrees
    ctx.moveTo(p1.x, p1.y);
    ctx.lineTo(x225, y225);
    // draw partial arrowhead at 135 degrees
    ctx.moveTo(p1.x, p1.y);
    ctx.lineTo(x135, y135);
    // stroke the line and arrowhead
    ctx.closePath();
    ctx.stroke();
  };

  handleMouseDown(event) {
    const { textContainer, canvas, modeEditText, ctx, canvasLayer, canvasLayerCtx } = this.state;
    let points = this.state.points;

    this.setState(
      {
        isDown: true,
        startX: event.offsetX,
        startY: event.offsetY,
        imageData: ctx.getImageData(0, 0, canvas.width, canvas.height),
      },
      () => {
        const { canvas, ctx, typeDraw } = this.state;
        let x = event.offsetX;
        let y = event.offsetY;

        // typeDraw === this.typesDraw.line ||
        if (typeDraw === this.typesDraw.rect || typeDraw === this.typesDraw.arrow) {
          canvasLayer.classList.add("canvas-layer--active");
          canvasLayerCtx.moveTo(x, y);
          canvasLayerCtx.lineTo(x + 1, y + 1);
          canvasLayerCtx.stroke();
          canvasLayerCtx.save();
        }

        if (typeDraw === this.typesDraw.curve) {
          ctx.beginPath();
          ctx.moveTo(x, y);
          ctx.save();

          points?.push({ x: event.offsetX, y: event.offsetY });
        }

        if (typeDraw === this.typesDraw.text) {
          if (!modeEditText) {
            textContainer.style.top = `${y}px`;
            textContainer.style.left = `${x}px`;
            textContainer.style.width = `${300}px`;
            textContainer.style.height = `${100}px`;
            textContainer.style.display = "block";
          } else {
            this.drawText(textContainer.innerText, parseInt(textContainer.style.left), parseInt(textContainer.style.top));
            textContainer.style.display = "none";
            textContainer.innerText = "";
          }
          this.setState({
            modeEditText: !modeEditText,
          });
        }

        this.setState({
          resultImg: canvas.toDataURL(),
          points,
        });
      }
    );
  }

  handleMouseMove(event) {
    if (this.state.isDown) {
      const { canvas, ctx, typeDraw, startX, startY } = this.state;
      let points = this.state.points;
      let x = event.offsetX;
      let y = event.offsetY;

      if (typeDraw === this.typesDraw.curve) {
        ctx.lineTo(x, y);
        ctx.stroke();

        points?.push({ x: event.offsetX, y: event.offsetY });
      }

      if (typeDraw === this.typesDraw.arrow) {
        this.drawLineWithArrowhead({ x: startX, y: startY }, { x, y }, 25);
      }

      if (typeDraw === this.typesDraw.rect) {
        let width = parseInt(startX - x);
        let height = parseInt(startY - y);

        this.drawRect(x, y, width, height);
      }

      this.setState({ points });
    }
  }

  handleMouseUp(event) {
    let pathsry = this.state.pathsry;

    this.setState({
      isDown: false,
    });
    const { canvas, ctx, typeDraw, canvasLayer, canvasLayerCtx } = this.state;

    if (typeDraw === this.typesDraw.curve) {
      pathsry?.push({ path: this.state.points, typeDraw });

      ctx.closePath();
      ctx.save();
    }

    if (typeDraw === this.typesDraw.arrow || typeDraw === this.typesDraw.rect) {
      const imgData = new Image();
      imgData.src = canvasLayer.toDataURL();
      pathsry?.push({ imgData, typeDraw });
      ctx.drawImage(canvasLayer, 0, 0, this.state.img?.width, this.state.img?.height);
      canvasLayerCtx.clearRect(0, 0, canvas.width, canvas.height);
    }

    this.setState({
      resultImg: canvas.toDataURL(),
      pathsry,
      ctx,
      points: [],
      canvasLayerCtx,
    });
  }

  drawPaths = () => {
    // delete everything
    const { canvas, ctx, typeDraw, pathsry, img } = this.state;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(img, 0, 0, this.state.img?.width, this.state.img?.height);

    pathsry?.forEach(shape => {
      if (shape.typeDraw === this.typesDraw.rect || shape.typeDraw === this.typesDraw.arrow) {
        ctx.drawImage(shape.imgData, 0, 0, this.state.img?.width, this.state.img?.height);
      }
      if (shape.typeDraw === this.typesDraw.curve) {
        ctx.beginPath();
        ctx.moveTo(shape.path[0].x, shape.path[0].y);

        for (let i = 1; i < shape.path.length; i++) {
          ctx.lineTo(shape.path[i].x, shape.path[i].y);
        }
        ctx.stroke();
      }
    });

    this.setState({
      resultImg: canvas.toDataURL(),
    });
  };

  onModalToggle = () => {
    this.setState({
      isOpenModal: !this.state.isOpenModal,
    });
  };

  onUndo = () => {
    let pathsry = this.state.pathsry;
    pathsry?.pop();
    this.setState({ pathsry });
    this.drawPaths();
  };

  render() {
    const { isOpenModal, canvasImgLoading } = this.state;

    return (
      <div className="image-editor-wrapper">
        {!isOpenModal && (
          <button type="button" className="btn btn-primary btn-sm white button-edit-img" onClick={this.onModalToggle}>
            <i className="fa fa-edit" />
          </button>
        )}
         <Modal isShow={isOpenModal} onClose={this.onModalToggle}>
        <div className="canvas-container" style={{ width: `${this.state.img?.width}px; height: ${this.state.img?.height}px` }}>
          <Loading loading={canvasImgLoading} disP={true} />
          <canvas
            ref={this.imageEditorRef}
            width={this.state.img?.width}
            height={this.state.img?.height}
            onMouseDown={e => {
              let nativeEvent = e.nativeEvent;
              this.handleMouseDown(nativeEvent);
            }}
            onMouseMove={e => {
              let nativeEvent = e.nativeEvent;
              this.handleMouseMove(nativeEvent);
            }}
            onMouseUp={e => {
              let nativeEvent = e.nativeEvent;
              this.handleMouseUp(nativeEvent);
            }}
          />
          <canvas
            ref={this.canvasLayerArrow}
            className="canvas-layer canvas-layer--arrow"
            width={this.state.img?.width}
            height={this.state.img?.height}
            onMouseDown={e => {
              let nativeEvent = e.nativeEvent;
              this.handleMouseDown(nativeEvent);
            }}
            onMouseMove={e => {
              let nativeEvent = e.nativeEvent;
              this.handleMouseMove(nativeEvent);
            }}
            onMouseUp={e => {
              let nativeEvent = e.nativeEvent;
              this.handleMouseUp(nativeEvent);
            }}
          />
        </div>
        {this.state.resultImg && (
          <div className="details-buttons-container bb">
            {/* <div
              className="btn btn-primary btn-sm white m-b"
              onClick={() => {
                this.setState({
                  typeDraw: this.typesDraw.arrow,
                });
              }}>
              <i className="fa fa-long-arrow-left" />
            </div> */}
            <div
              className="btn btn-primary btn-sm white m-b"
              onClick={() => {
                this.setState({
                  typeDraw: this.typesDraw.curve,
                });
              }}>
              <i className="fa fa-paint-brush" />
            </div>
            {/* <div
              className="btn btn-primary btn-sm white m-b"
              onClick={() => {
                this.setState({
                  typeDraw: this.typesDraw.text,
                });
              }}>
              <i className="fa fa-text-width" />
            </div> */}
            {/* <div
              className="btn btn-primary btn-sm white m-b"
              onClick={() => {
                this.setState({
                  typeDraw: this.typesDraw.rect,
                });
              }}>
              <i className="fa fa-square" />
            </div> */}
            <div className="btn btn-primary btn-sm white m-b" onClick={this.onUndo}>
              <i className="fa fa-step-backward" />
            </div>
            <div
              className="btn btn-primary btn-sm white m-b"
              onClick={() => {
                console.log(this.props.onSaveImage?.(this.props.id, this.state.resultImg));
                this.props.onSaveImage?.(this.props.id, this.state.resultImg);
                this.onModalToggle();
              }}>
              <i className="fa fa-save" />
            </div>
          </div>
        )}
        </Modal>
      </div>
    );
  }
}
