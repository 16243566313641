import { actionTypes, initialState } from "./constants";

const inText = (translate, localization) => module => key => (translate[module][key] && translate[module][key][localization]) || key;

export const reducer = (state = initialState, action) => {
  const itf = state && state.translate ? inText(state.translate, state.localization) : () => () => "";

  state = Object.assign({}, state, { inText: itf });

  switch (action.type) {
    case actionTypes.LOGIN:
      window.location.replace("/#/");
      return Object.assign({}, state, { me: action.body });

    case actionTypes.REGISTER:
      window.location.replace("/#/");
      return Object.assign({}, state, { me: action.body });

    case actionTypes.LOGOUT:
      window.location.replace("/#/login");
      window.location.reload(true);
      return Object.assign({}, state, { me: null });

    case actionTypes.UPDATE_ME:
      return Object.assign({}, state, { me: action.body });

    case actionTypes.SET_DEFAULT_TEMPLATE:
      return Object.assign({}, state, { defaultTemplate: action.body });


    default:
      return state;
  }
};
