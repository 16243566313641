
export const SIDE_NAV = {
  photo_report: {
    uk: "Фото звіт",
    en: "Photo report",
  },
  all_reports: {
    uk: "Bсі звіти",
    en: "All reports",
  },
  merchandiser: {
    uk: "Мерчендайзер",
    en: "Merchandiser",
  },
  tasks: {
    uk: "Завдання",
    en: "Tasks",
  },
  answers: {
    uk: "Відповіді",
    en: "Answers",
  },
  task_uploading: {
    uk: "Завантаження завдань",
    en: "Task uploading",
  },
  points: {
    uk: "Точки",
    en: "Points",
  },
  visit: {
    uk: "Візити",
    en: "Visits",
  },
  documents: {
    uk: "Документи",
    en: "Documents",
  },
  logout: {
    uk: "Вихід",
    en: "Logout",
  },
};
export const MANAGER_PROFILE = {
  my_profile: {
    uk: "Мій профайл",
    en: "My profile",
  },
  my_help: {
    uk: "Допомога",
    en: "Help",
  },
  name: {
    uk: "Імя",
    en: "Name",
  },
  surname: {
    uk: "Прізвище",
    en: "Surname",
  },
  position: {
    uk: "Посада",
    en: "Position",
  },
  my_region: {
    uk: "Мій регіон",
    en: "My region",
  },
  telegram: {
    uk: "Телеграм",
    en: "Telegram",
  },
  language: {
    uk: "Мова",
    en: "Language",
  },
  save: {
    uk: "Зберегти",
    en: "Save",
  },
  exit: {
    uk: "Вихід",
    en: "Logout",
  },
};
export const ENTITY_DROPDOWN_SETTINGS = {
  custom_filter: {
    uk: "Користувацький фільтр",
    en: "Customer filter",
  },
  merchandiser: {
    uk: "Мерчендайзер",
    en: "Merchandiser",
  },
  merchandisers: {
    uk: "Супервайзери",
    en: "Merchandisers",
  },
  supervisor: {
    uk: "Супервайзер",
    en: "Supervisor",
  },
  comment: {
    uk: "Коментар ...",
    en: "Comment ...",
  },
  point: {
    uk: "Точка",
    en: "Point",
  },
  points: {
    uk: "Точки",
    en: "Points",
  },
  network: {
    uk: "Мережа",
    en: "Network",
  },
  networks: {
    uk: "Мережі",
    en: "Networks",
  },
  created_by: {
    uk: "Ким створено",
    en: "Created by",
  },
  region: {
    uk: "Регіон",
    en: "Region",
  },
  city: {
    uk: "Місто",
    en: "City",
  },
  brand: {
    uk: "Бренд",
    en: "Brand",
  },
  brands: {
    uk: "Бренди",
    en: "Brands",
  },
  product: {
    uk: "Продукт",
    en: "Product",
  },
  products: {
    uk: "Продукти",
    en: "Products",
  },
  category: {
    uk: "Категорія",
    en: "Category",
  },
  categories: {
    uk: "Категорії",
    en: "Categories",
  },
  tag: {
    uk: "Тег",
    en: "Tag",
  },
  tags: {
    uk: "Теги",
    en: "Tags",
  },
  component: {
    uk: "Компонент",
    en: "Сomponent",
  },
  template: {
    uk: "Шаблон",
    en: "Template",
  },
  task_status: {
    uk: "Статус: всі",
    en: "Status: all",
  },
  task_negative: {
    uk: "Негативна відповідь",
    en: "Negative answer",
  },
  task_deadline: {
    uk: "Дедлайн",
    en: "Deadline",
  },
  additional_filters: {
    uk: "Додаткові фільтри",
    en: "Additional filters",
  },
  apply: {
    uk: "Застосувати",
    en: "Apply",
  },
  filter_reset: {
    uk: "Скинути фільтри",
    en: "Reset the filters",
  },
  filter_save: {
    uk: "Зберегти фільтр",
    en: "Save the filter",
  },
  filter_remove: {
    uk: "Видалити фільтр",
    en: "Remove the filter",
  },
  filter_name_placeholder: {
    uk: "Назва фільтру",
    en: "Filter name",
  },
};
export const TRANSLATION_DOWNLOADER = {
  export: {
    uk: "Експортувати",
    en: "Export",
  },
  download: {
    uk: "Скачати",
    en: "Download",
  },
  downloadAll: {
    uk: "Скачати всі",
    en: "Download All",
  },
  downloading: {
    uk: "Завантаження",
    en: "Downloading",
  },
  confirmation_msg: {
    uk: "Ви впевненні що хочете видалити фільтр?",
    en: "Are you sure that you want to delete the filter?",
  },
  remove_confirmation_msg: {
    uk: "Потрібно вибрати фільтр для видалення",
    en: "Need to select a filter to delete",
  },
  remove_success_msg: {
    uk: "Фільтр видалений",
    en: "The filter is deleted",
  },
  valid_error_msg: {
    uk: "Ім'я фільтра не може бути пустим!",
    en: "The filter's name cannot be empty!",
  },
  newtask_success_msg: {
    uk: "Завдання зберeжене",
    en: "The task is saved",
  },
  task_reject_msg: {
    uk: "Відхилення",
    en: "Rejection",
  },
  task_accept_msg: {
    uk: "Затвердження",
    en: "Approval",
  },
  back: {
    uk: "Назад",
    en: "Back",
  },
  yes: {
    uk: "Так",
    en: "Yes",
  },
  cancel: {
    uk: "Відмінити",
    en: "Cancel",
  },
};
export const ENTITY_TABLE = {
  records_count: {
    uk: "Кількість записів",
    en: "Records count",
  },
  start_date: {
    uk: "Дата початку",
    en: "Start date",
  },
  deadline_date: {
    uk: "Дата дедлайна",
    en: "Deadline date",
  },
  filter_overdue_tasks: {
    uk: "Відфільтрувати простроковані задачі",
    en: "Filter overdue tasks",
  },
  filter_tasks_with_negative_responses: {
    uk: "Відфільтрувати задачі з негативними відповідями",
    en: "Filter tasks with negative responses",
  },
  creation_date_from: {
    uk: "Дата створення з",
    en: "Creation date from",
  },
  creation_date_to: {
    uk: "Дата створення по",
    en: "Creation date to",
  },
  search_placeholder: {
    uk: "Пошук ...",
    en: "Search ...",
  },
  filter_reset_btn: {
    uk: "Скинути фільтри",
    en: "Reset filters",
  },
  create_btn: {
    uk: "Створити",
    en: "Create",
  },
  to_point_btn: {
    uk: "на точку",
    en: "to point",
  },
  to_network_btn: {
    uk: "на мережу",
    en: "to network",
  },
};
export const MERCHANDISER_EDITOR = {
  name: {
    uk: "Імя",
    en: "Name",
  },
  date_of_enrollment: {
    uk: "Дата приєднання",
    en: "Date of joining",
  },
  deactivation_date: {
    uk: "Дата деактивації",
    en: "Deactivation date",
  },
  date_till_newbie: {
    uk: "Дата до новачка",
    en: "Date till newbie",
  },
  surname: {
    uk: "Прізвище",
    en: "Surname",
  },
  phone_number: {
    uk: "Телефон",
    en: "Phone number",
  },
  movement: {
    uk: "Пересування",
    en: "Movement",
  },
  password: {
    uk: "Пароль",
    en: "Password",
  },
  repeat_password: {
    uk: "Повторити пароль",
    en: "Repeat password",
  },
  status: {
    uk: "Статус",
    en: "Status",
  },
  save_changes_btn: {
    uk: "Зберегти зміни",
    en: "Save changes",
  },
};
export const MERCHANDISER_MAIN_PAGE = {
  data_successfully_saved: {
    uk: "Дані успішно збережені",
    en: "Data successfully saved",
  },
  view_btn: {
    uk: "Перегляд",
    en: "View",
  },
  edit_btn: {
    uk: "Редагувати",
    en: "Edit",
  },
  route: {
    uk: "Маршрут",
    en: "Route",
  },
  assignment: {
    uk: "ЗАВДАННЯ",
    en: "Assignment",
  },
  visits: {
    uk: "ВІЗИТИ",
    en: "VISITS",
  },
  presence: {
    uk: "ВІДСУТНІСТЬ",
    en: "PRESENCE",
  },
  network_title: {
    uk: "Мережа",
    en: "Network",
  },
  title_title: {
    uk: "Титул",
    en: "Title",
  },
  start_title: {
    uk: "Срт",
    en: "Start",
  },
  end_title: {
    uk: "Кінець",
    en: "End",
  },
  number_of_images_title: {
    uk: "Кількість зображень",
    en: "Number of images",
  },
  number_of_tags_title: {
    uk: "Кількість тегів",
    en: "Number of tags",
  },
  completion_title: {
    uk: "Закінчення",
    en: "Completion",
  },
  point_title: {
    uk: "Точка",
    en: "Point",
  },
  points_title: {
    uk: "Точки",
    en: "Points",
  },
  day_title: {
    uk: "День",
    en: "Day",
  },
  save_btn: {
    uk: "Зберегти",
    en: "Save",
  },
  movement: {
    uk: "Пересування",
    en: "Movement",
  },
  connected: {
    uk: "Приєднався",
    en: "Connected",
  },
  deactivated: {
    uk: "Деактивований",
    en: "Deactivated",
  },
  till_newbie: {
    uk: "До новачків",
    en: "Till Newbie",
  },
  user_status: {
    uk: "Статус",
    en: "Status",
  },
};
export const MERCHANDISER_LIST = {
  user_name: {
    uk: "Ім'я користувача",
    en: "User name",
  },
  phone_number: {
    uk: "Телефон",
    en: "Phone",
  },
  movement: {
    uk: "Пересування",
    en: "Movement",
  },
  user_state: {
    uk: "Стан",
    en: "User State",
  },
  date_join: {
    uk: "Дата приєднання",
    en: "Date of joining",
  },
};
export const ABSENCE_USER = {
  query: {
    uk: "Запит",
    en: "Request",
  },
  date_from: {
    uk: "Дата з",
    en: "Date from",
  },
  date_to: {
    uk: "Дата до",
    en: "Date to",
  },
  reason: {
    uk: "Причина",
    en: "Reason",
  },
  add_btn: {
    uk: "Додати",
    en: "Add",
  },
  absence_history: {
    uk: "Історія відсутностей",
    en: "Absence history",
  },
  absence_reason: {
    uk: "Будь ласка, введіть причину відсутності",
    en: "Please enter the reason of absence",
  },
};
export const WEEK = {
  monday: {
    uk: "Понеділок",
    en: "Monday",
  },
  tuesday: {
    uk: "Вівторок",
    en: "Tuesday",
  },
  wednesday: {
    uk: "Середа",
    en: "Wednesday",
  },
  thursday: {
    uk: "Четвер",
    en: "Thursday",
  },
  friday: {
    uk: "Пятниця",
    en: "Friday",
  },
  saturday: {
    uk: "Субота",
    en: "Saturday",
  },
  sunday: {
    uk: "Неділя",
    en: "Sunday",
  },
};
export const TAKS_LIST = {
  point: {
    uk: "Точка",
    en: "Point",
  },
  created_by: {
    uk: "Ким створено",
    en: "Created by",
  },
  supervisor: {
    uk: "Супервайзер",
    en: "Supervisor",
  },
  merchendiser: {
    uk: "Мерчендайзер",
    en: "Merchendiser",
  },
  name: {
    uk: "Назва",
    en: "Name",
  },
  task_title: {
    uk: "Титул",
    en: "Title",
  },
  finishing: {
    uk: "Закінчення",
    en: "Finishing",
  },
  status: {
    uk: "Статус",
    en: "Status",
  },
  date_creation: {
    uk: "Дата створення",
    en: "Date of creation",
  },
  print_report: {
    uk: "Друкувати звіт",
    en: "Print report",
  },
  responses: {
    uk: "Відповіді",
    en: "Responses",
  },
  first_page: {
    uk: "Перша",
    en: "First",
  },
  previous_page: {
    uk: "Попередня",
    en: "Previous",
  },
  next_page: {
    uk: "Наступна",
    en: "Next",
  },
  last_page: {
    uk: "Остання",
    en: "Last",
  },
  description: {
    uk: "Опис",
    en: "Description",
  },
  addresses: {
    uk: "Адреса",
    en: "Addresses",
  },
  network: {
    uk: "Мережа",
    en: "Network",
  },
  answer_saved: {
    uk: "Відповідь збережена",
    en: "The answer is saved",
  },
  task_saved: {
    uk: "Завдання збережене",
    en: "The task is saved",
  },
  date_saved: {
    uk: "Дані успішно збережені",
    en: "The data is successfully saved",
  },
  download_btn: {
    uk: "Завантажити",
    en: "Download",
  },
  approv_btn: {
    uk: "Затвердити",
    en: "To approve",
  },
  reject_btn: {
    uk: "Відхилити",
    en: "Reject",
  },
  remove_btn: {
    uk: "Видалити",
    en: "Remove",
  },
  cancel_btn: {
    uk: "Відмінити",
    en: "Cancel",
  },
  back_btn: {
    uk: "Назад",
    en: "Back",
  },
  task_details_title: {
    uk: "Деталі завдання",
    en: "Details of the task",
  },
};
export const TAKS_NEW = {
  drag_msg: {
    uk: "Клікніть або перетягніть зображення сюди",
    en: "Click or drag the image here",
  },
  new_order: {
    uk: "Нове доручення",
    en: "New order",
  },
  task_template: {
    uk: "Шаблон",
    en: "Template",
  },
  start_date: {
    uk: "Дата початку",
    en: "The start date",
  },
  end_date: {
    uk: "Дата закінчення",
    en: "The end date",
  },
  save_btn: {
    uk: "Зберегти",
    en: "Save",
  },
};
export const TAKS_REVIEW = {
  apply_btn: {
    uk: "Застосувати",
    en: "Apply",
  },
  reject_btn: {
    uk: "Відхилити",
    en: "Reject",
  },
  reset_filters_btn: {
    uk: "Скинути фільтри",
    en: "Reset the filters",
  },
  records_count: {
    uk: "Кількість записів",
    en: "Records count",
  },
  point: {
    uk: "Точка",
    en: "Point",
  },
  network: {
    uk: "Мережа",
    en: "Network",
  },
  priority: {
    uk: "Пріоритет",
    en: "Priority",
  },
  merchandiser: {
    uk: "Мерчендайзер",
    en: "Merchandiser",
  },
  responsible_supervisor: {
    uk: "Відповідальний супервайзер",
    en: "Responsible supervisor",
  },
  supervisor: {
    uk: "Супервайзер",
    en: "Supervisor",
  },
  view_btn: {
    uk: "Перегляд",
    en: "View",
  },
  photo_title: {
    uk: "Фото",
    en: "Photo",
  },
  details_task: {
    uk: "Деталі Завдання",
    en: "Details of the task",
  },
  title_task: {
    uk: "Титул",
    en: "Title",
  },
  description_task: {
    uk: "Опис завдання",
    en: "Description of the task",
  },
  tags_task: {
    uk: "Теги",
    en: "Tags",
  },
  images_task: {
    uk: "Зображення",
    en: "Images",
  },
  deadline_task: {
    uk: "Дедлайн",
    en: "Deadline",
  },
  details_response_task: {
    uk: "Деталі Відповіді",
    en: "Response Details",
  },
  date_response_task: {
    uk: "Дата відповіді",
    en: "Response Date",
  },
  text_response_task: {
    uk: "Текст відповіді",
    en: "Response Text",
  },
  photo_response_task: {
    uk: "Фото відповіді",
    en: "Response Photo",
  },
  yes: {
    uk: "Так",
    en: "Yes",
  },
  no: {
    uk: "Ні",
    en: "No",
  },
  visit_id: {
    uk: "Номер Візиту",
    en: "Visit ID",
  },
  back_btn: {
    uk: "Назад",
    en: "Back",
  },
};
export const TAKS_IMPORT = {
  import_tab: {
    uk: "Імпорт",
    en: "Import",
  },
  import_advanced_tab: {
    uk: "Розширений імпорт",
    en: "Advanced Import",
  },
  import_matrix_tab: {
    uk: "Імпорт: асортимента матриця",
    en: "Import: Assortment Matrix",
  },
  import_downloads_tab: {
    uk: "My Downloads",
    en: "Import",
  },
  type_loading: {
    uk: "Тип завантаження",
    en: "Loading Type",
  },
  click_or_drag_file: {
    uk: "Клікніть або перетягніть файл",
    en: "Click or Drag a File",
  },
  download_btn: {
    uk: "Завантажити",
    en: "Download",
  },
  add_record_btn: {
    uk: "Додати запис",
    en: "Add Record",
  },
  save_btn: {
    uk: "Зберегти",
    en: "Save",
  },
  back_btn: {
    uk: "Назад",
    en: "Back",
  },
  status: {
    uk: "Статус",
    en: "Status",
  },
  name_title: {
    uk: "Назва",
    en: "Name",
  },
  line_title: {
    uk: "Рядок",
    en: "Line",
  },
  start_date_title: {
    uk: "Дата початку",
    en: "Start Date",
  },
  end_date_title: {
    uk: "Дата кінця",
    en: "End Date",
  },
  photo_title: {
    uk: "Фото",
    en: "Photo",
  },
  description_title: {
    uk: "Опис",
    en: "Description",
  },
  type_tasks_title: {
    uk: "Тип задач",
    en: "Type of tasks",
  },
  download_date_title: {
    uk: "Дата завантаження",
    en: "Download date",
  },
  loading_errors_title: {
    uk: "Помилки завантаження",
    en: "Loading errors",
  },
  need_title_task_msg: {
    uk: "Потрібно ввести титул завдання",
    en: "You need to enter the title of the task",
  },
  download_was_delete_msg: {
    uk: "Завантаження успішно видалено",
    en: "Download has been successfully deleted",
  },
  need_choose_task_msg: {
    uk: "Потрібно вибрати шаблон",
    en: "You need to choose a template",
  },
  need_choose_point_msg: {
    uk: "Потрібно вибрати точку",
    en: "Point is required field",
  },
  empty_field_msg: {
    uk: "Це поле не може бути пустим",
    en: "This field cannot be empty",
  },
  download_name: {
    uk: "Назва завантаження",
    en: "Download name",
  },
  select_download_type: {
    uk: "Оберіть типа завантаження",
    en: "Choose the download type",
  },
  click_drag_img: {
    uk: "Клікніть або перетягніть зображення сюди",
    en: "Click or drag the image here",
  },
  only_one_photo_allowed_for_type_point: {
    uk: "Для типу завантаження 'Точка' дозволене лише одне фото",
    en: "For upload type 'Point', only one photo is allowed",
  },
  complete_at_least_one_task_msg: {
    uk: "Заповніть хоча б одне завдання або виберіть файл для імпорту",
    en: "Complete at least one task or select a file to import",
  },
  network_label: {
    uk: "Мережа",
    en: "Network",
  },
  point_label: {
    uk: "Точка",
    en: "Point",
  },
  remove_btn: {
    uk: "Видалити",
    en: "Remove",
  },
  addresses_label: {
    uk: "Адреса",
    en: "Addresses",
  },
  code_label: {
    uk: "Код",
    en: "Code",
  },
  location_label: {
    uk: "Розташування",
    en: "Location",
  },
  coordinates_label: {
    uk: "Координати",
    en: "Coordinates",
  },
  view_the_point: {
    uk: "Перегляд точки",
    en: "View the point",
  },
};
export const DOCUMENTS = {
  file_deleted: {
    uk: "Файл видалений",
    en: "File Deleted",
  },
  file_name_cannot_be_empty: {
    uk: "Ім'я файлу не може бути пустим",
    en: "File Name Cannot Be Empty",
  },
  need_to_add_file: {
    uk: "Потрібно додати файл",
    en: "Need to Add File",
  },
  file_successfully_uploaded: {
    uk: "Файл успішно завантажений",
    en: "File Successfully Uploaded",
  },
  add_file: {
    uk: "Додати файл",
    en: "Add File",
  },
  file_name: {
    uk: "Назва файлу",
    en: "File Name",
  },
  click_or_drag_files_here: {
    uk: "Клікніть або перетягніть файли сюди",
    en: "Click or Drag Files Here",
  },
  download_file_btn: {
    uk: "Завантажити файл",
    en: "Download File",
  },
  creation_time: {
    uk: "Час створення",
    en: "Creation Time",
  },
};

export const PHOTO_REVIEW = {
  merchendiser: {
    uk: "Мерчендайзер",
    en: "Merchendiser",
  },
  responsible_supervisor: {
    uk: "Відповідальний супервайзер",
    en: "Responsible supervisor",
  },
  start_visit: {
    uk: "Старт візиту",
    en: "The start of the visit",
  },
  end_visit: {
    uk: "Кінець візиту",
    en: "The end of the visit",
  },
  comment: {
    uk: "Коментар",
    en: "Comment",
  },
  price: {
    uk: "Ціна",
    en: "Price",
  },
  sales_price: {
    uk: "Ціна продажу",
    en: "Sales price",
  },
  price_monitoring: {
    uk: "Моніторинг цін",
    en: "Price monitoring",
  },
  approv_btn: {
    uk: "Затвердити",
    en: "To approve",
  },
  reject_btn: {
    uk: "Відхилити",
    en: "Reject",
  },
};
export const USER_LOGIN = {
  login_success_msg: {
    uk: "Ви успішно увійшли в систему",
    en: "You have successfully logged in to the system",
  },
  login_error_msg: {
    uk: "Користувач не існує або неправильні дані для входу.",
    en: "User does not exist or incorrect login data.",
  },
};
