import "babel-polyfill";
import "/node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import Alert from "react-s-alert";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/stackslide.css";
import dotenv from "dotenv";

import { getStore } from "./util/redux/store";
import { saveState } from "./util/redux/localStorage";
import AppRouter from "./router";

dotenv.config();

const store = getStore();
store.subscribe(() => {
  const { me } = store.getState();
  saveState({ me: me });
});

const application = (
  <Provider store={store}>
    <div>
      <Alert stack={{ limit: 3 }} timeout={5000} position={"top"} offset={20} />
      <HashRouter>
        {" "}
        <AppRouter />
      </HashRouter>
    </div>
  </Provider>
);

const root = document.getElementById("app");
ReactDOM.render(application, root);
