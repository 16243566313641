import React, { Component } from "react";

export default class Modal extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    if (this.props.isShow) {
      document.body.classList.add("opened");
    }
  };

  componentWillUnmount = () => {
    if (document.body.classList.value === "opened") {
      document.body.classList.remove("opened");
    }
  };

  render() {
    const { title = "", onClose, isShow, customClass = "", isSingle = false, previewNavigationData = "", moveNext = "", isSupervised } = this.props;

    if (!isShow) return null;

    return (
      <div
        id="s-f-m-a"
        className={`modal fade ${isShow ? "show" : ""} scrollableHorizontal`}
        data-backdrop="true"
        style={{ display: isShow ? "block" : "none" }}>
        <div className={`modal-dialog modal-xl ${customClass} ${isShow ? "fade-down-big" : ""}`}>
          <div className="modal-content">
            <div className="modal-body text-center">{isShow && this.props.children}</div>

            {/*<div className='modal-footer'>*/}
            {/*      <div className="row">*/}
            {/*          <div className="col-md-6">*/}
            {/*              <button type='button' className='btn btn-outline-primary p-x-md' onClick={onClose}>Close</button>*/}
            {/*          </div>*/}
            {/*          <div className="col-md-6">*/}
            {/*              <button type='button' className='btn primary p-x-md' onClick={onSubmit}> <i className="fa fa-plus-circle"></i> Submit</button>*/}
            {/*          </div>*/}
            {/*      </div>*/}
            {/*</div>*/}
          </div>
        </div>
        <div className="header-modal" onClick={onClose}>
          <i className="fa fa-times" />
        </div>
        {isSingle && isSupervised ? (
          <div className="single-photo-review-nav">
            <button
              title="Попередій звіт"
              className="btn btn-primary white"
              disabled={previewNavigationData.currentIndex === 0}
              onClick={() => {
                moveNext(-1);
              }}>
              <i className="fa fa-angle-left" />
            </button>
            <button
              title="Наступний звіт"
              className="btn btn-primary white"
              disabled={previewNavigationData.currentIndex === previewNavigationData.lastIndex - 1}
              onClick={() => {
                moveNext(+1);
              }}>
              <i className="fa fa-angle-right" />
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
