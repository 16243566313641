import React, { useState } from "react";

import Aside from "./Aside";
import Header from "./Header";
import { LangUpdateContext } from "./LangContext";

import { getStore } from "../util/redux/store";

import "./style.scss";

export const Layout = props => {
  const updateLangFn = () => {
    setLang(prevState => ({
      ...prevState,
      updateLang: !prevState.updateLang,
    }));
  };
  const [lang, setLang] = useState({
    updateLang: false,
    updateLangFn: updateLangFn,
  });
  const me = getStore().getState().me;

  return (
    <div className="theme-1-light">
      <LangUpdateContext.Provider value={lang}>
        <Aside />
        <div id="content" className="app-content box-shadow-z3">
          <Header me={me} />
          <div className="app-body">{props.children}</div>
        </div>
      </LangUpdateContext.Provider>
    </div>
  );
};
