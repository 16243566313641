import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

import { PRIORITY } from "./../config/const";
import Avatar from "../../assets/image/avatar.jpg";
import AvatarCompany from "../../assets/image/company.png";
import { modelMap } from "../config/config";
import { MOVEMENTS, WEEK } from "../config/const";
import {MERCHANDISER_MAIN_PAGE} from "../config/lang";

import { call } from "../util/action";
import { getStore } from "../util/redux/store";
import { translate } from "../util/util";

const me = getStore().getState().me;
export function IDFormatter(value) {
  return value;
}

export function IDFormatter2(value) {
  return "";
}

// TABLES
export function openVisit(id) {
  return (
    <a href={`/#/visit/${id}`} className="btn btn-sm btn-outline-primary">
       
      <i className="fa fa-external-link can_open" /> {translate(MERCHANDISER_MAIN_PAGE, "view_btn", me.language)} 
    </a>
  );
}

export function openTask(id) {
  return (
    <a href={`/#/task/${id}`} className="btn btn-sm btn-outline-primary" target="_blank">
      <i className="fa fa-external-link can_open" />
    </a>
  );
}

export function openRoute(id) {
  return (
    <a href={`/#/route/${id}`} className="btn btn-sm btn-outline-primary">
      <i className="fa fa-external-link can_open" /> {translate(MERCHANDISER_MAIN_PAGE, "view_btn", me.language)}
    </a>
  );
}

export function openMerchandiser(id) {
  return (
    <a href={`/#/merchandiser/${id}`} className="btn btn-sm btn-outline-primary">
      <i className="fa fa-external-link can_open" /> {translate(MERCHANDISER_MAIN_PAGE, "view_btn", me.language)}
    </a>
  );
}

export function openPoint(id) {
  return (
    <a href={`/#/point/${id}`} className="btn btn-sm btn-outline-primary">
      <i className="fa fa-external-link can_open" /> {translate(MERCHANDISER_MAIN_PAGE, "view_btn", me.language)}
    </a>
  );
}

export function points(i, item) {
  return (
    <div>
      {item.points.map((i, idx) => (
        <div key={i.point.name + idx}>{i.point.name}</div>
      ))}
    </div>
  );
}

export function pointsCount(i, item) {
  return item.points.length;
}

export function point(i, item) {
  return item.point ? item.point.name + " - " + item.point.address : "";
}

export function taskTags(i, item) {
  return (
    <div>
      {item?.tags?.map((i, idx) => (
        <span key={i.name + idx} className="label rounded primary">
          {i.name}
        </span>
      ))}
    </div>
  );
}

export function taskComponents(i, item) {
  return (
    <span>
      {item.components &&
        item.components.map((i, idx) => (
          <span key={i.name + idx} className="label rounded primary">
            {i.name}
          </span>
        ))}
    </span>
  );
}

export function taskNegative(i, item) {
  return <span>{item.negative_answers_count}</span>;
}

export function taskComment(i, item) {
  return <span>{item.last_answer && item.last_answer.answer}</span>;
}

export function taskStatus(i, item) {
  return <span className={`label rounded ${item.status?.replace(" ", "-")}`}>{item.status}</span>;
}

export function pointsTask(i, item) {
  return (
    <div>
      {item?.tags?.map((i, idx) => (
        <span key={i.name + idx} className="label rounded primary">
          {i.name}
        </span>
      ))}
    </div>
  );
}

export function locality(i, item) {
  return <span>{item.locality && item.locality.name && item.locality.name}</span>;
}

export function pointState(i, item) {
  return <span className={"label rounded " + (item.is_active ? "primary" : "grey")}>{item.is_active ? "Active" : "Not Active"}</span>;
}

export function imageCount(i, item) {
  return item && item.image_count;
}

export function tagCount(i, item) {
  return item?.tags?.length;
}

export function day(i, item) {
  return <span className="dayLabel"> {item && WEEK[item.day_of_the_week]}</span>;
}

export function movement(i, item) {
  return <span> {item && MOVEMENTS[item.movement]}</span>;
}

export function userName(i, item) {
  return (
    <span>
       
      <img className="w-32 img-circle avatar-crop" src={item.photo ? item.photo : Avatar} /> {item && item.first_name} {item && item.last_name}
    </span>
  );
}

export function userState(i, item) {
  return <span className={"label rounded " + (item.is_active ? "primary" : "grey")}>{item.is_active ? "Active" : "Not Active"}</span>;
}

export function supervisorName(i, item) {
  if (item.point && item.point.responsible_supervisors) {
    let user = item.point.responsible_supervisors;
    return (
      <span>
        <img className="w-32 img-circle avatar-crop" src={user.photo ? user.photo : Avatar} /> {user.first_name} {user.last_name}
      </span>
    );
  }
}
export function createdByInfo(i, item) {
  const user = item.created_by;
  if (user) {
    return (
      <span>
        <img className="w-32 img-circle avatar-crop" src={user.photo ? user.photo : Avatar} /> {user.first_name} {user.last_name} 
      </span>
    );
  }
}

export function merchandiserName(i, item) {
  const user = item.point?.responsible_merchandiser;
  if (user) {
    return (
      <span>
        <img className="w-32 img-circle avatar-crop" src={user.photo || Avatar} /> {user.first_name} {user.last_name}
      </span>
    );
  }
}

export function merchandiserPhone(i, item) {
  if (item.point && item.point.responsible_merchandiser) {
    let user = item.point.responsible_merchandiser;
    return <span>{user.phone_number} </span>;
  }
}

export function networkName(i, item) {
  return (
    item.network && (
      <span>
         
        <img className="w-32 img-circle avatar-crop" src={item.network.image ? item.network.image : AvatarCompany} /> {item.network.name}
      </span>
    )
  );
}

export function networkTask(i, item) {
  return item.network && item.network.name && item.network.name;
}

export function getNetworkData(id) {
    let networkName = "";
    call({ method: "GET", url: modelMap["network"]}, response => {
        networkName = response?.find(item => item.id === id)?.name
    });

  return networkName || "—";
}

// DATES
export function userDateJoined(i, item) {
  if (!item.date_joined) return;
  let date = new Date(item.date_joined);
  return moment(date).format("DD/MM/yyyy");
}

export function userDateTillNewbie(i, item) {
  if (!item.date_till_newbie) return;
  let date = new Date(item.date_till_newbie);
  return moment(date).format("DD/MM/yyyy");
}

export function userDateDeactivated(i, item) {
  if (!item.date_deactivated) return;
  let date = new Date(item.date_deactivated);
  return moment(date).format("DD/MM/yyyy");
}

export function dateStart(i, item) {
  if (!item.start) return;
  let date = new Date(item.start);
  return moment(date).format("DD/MM/yyyy");
}

export function dateEnd(i, item) {
  if (!item.end) return;
  let date = new Date(item.end);
  return moment(date).format("DD/MM/yyyy");
}

export function dateCreated(i, item) {
  if (!item.end) return;
  let date = new Date(item.created);
  return moment(date).format("DD/MM/yyyy");
}

export function dateTimeCreated(item) {
  let date = new Date(item.created);
  return moment(date).format("DD/MM/yyyy hh:mm:ss");
}

// REVIEW
export function reviewTaskSalePriceMonitoring(i, item) {
  if (item.additional_info.sale_price && item.additional_info.sale_price !== "None") {
    let sale_price = item.additional_info.sale_price;

    return <p>{sale_price}</p>;
  }
}
export function reviewTaskPriceMonitoring(i, item) {
  if (item.additional_info.price && item.additional_info.price !== "None") {
    let price = item.additional_info.price;

    return <p>{price}</p>;
  }
}

export function reviewMerchandiserNamePhone(i, item) {
  if (item?.visit?.user) {
    let user = item.visit.user;

    return (
      <span>
         
        <img className="w-32 img-circle avatar-crop" src={user.photo ? user.photo : Avatar} />
        <span>
          {user.first_name} {user.last_name} {user.phone && <span>,{user.phone}</span>}
        </span>
      </span>
    );
  }
}

export function reviewTaskSupervisor(i, item) {
  if (item.visit && item.visit.point && item.visit.point.responsible_supervisors) {
    let user = item.visit.point.responsible_supervisors[0];

    return (
      <span>
        <img className="w-32 img-circle avatar-crop" src={user?.photo ? user?.photo : Avatar} />
        <span>
          {user?.first_name} {user?.last_name}
        </span>
        {(user?.phone || user?.phone_number) && <span> ,{user?.phone || user?.phone_number}</span>}
      </span>
    );
  }
}

export function reviewTaskNework(i, item) {
  item.network = item.task.network;

  return (
    item.network && (
      <span>
        <img className="w-32 img-circle avatar-crop" src={item.network.image ? item.network.image : AvatarCompany} /> {item.network.name}
      </span>
    )
  );
}
export function reviewTaskVisitPoint(i, item) {
  if (item?.visit?.point) {
    let point = item.visit.point;

    return (
      <Link to={"/point/" + point.id} target="_blank">
        {point.name}
      </Link>
    );
  }
  return null;
}

export function reviewTaskPoint(i, item) {
  if (item?.task?.point) {
    let point = item.task.point;

    return (
      <Link to={"/point/" + point.id} target="_blank">
        {point.name}
      </Link>
    );
  }
  return null;
}

export function taskPoint(item) {
  if (item.point) {
    let point = item.point;
    return (
      <Link to={"/point/" + point.id} target="_blank">
        {point.name}
      </Link>
    );
  }
}

export function getTaskNetwork(item) {
  if (item.point) {
    let point = item.point;
    return (
      <Link to={"/point/" + point.id} target="_blank">
        {point.name}
      </Link>
    );
  }
}


export function reviewTaskPriority(i, item) {
  return <div>{PRIORITY[item.task.priority] ? PRIORITY[item.task.priority] : ""}</div>;
}

export function dateFormat(date) {
  if (!date) return;
  date = new Date(date);
  return moment(date).format("DD/MM/yyyy HH:mm");
}

export function dateFormat2(date) {
  date = new Date(date);
  return moment(date).format("yyyy-MM-DD");
}

export function dateFormatFull(date) {
  date = new Date(date);
  return moment(date).format("yyyy-MM-DDT23:59:59");
}