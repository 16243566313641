import Nav1 from '/assets/image/icon/nav1.svg'
import Nav2 from '/assets/image/icon/nav2.svg'
import Nav3 from '/assets/image/icon/nav3.svg'
import Nav4 from '/assets/image/icon/nav4.svg'
import Logout from '/assets/image/icon/logout.png'

import formUpload from '/assets/image/icon/misc/inbound.png'

export default function Icon(name){
    if (name=='nav1') return Nav1;
    if (name=='nav2') return Nav2;
    if (name=='nav3') return Nav3;
    if (name=='nav4') return Nav4;

    if (name=='form-upload') return formUpload;

    if (name=='logout') return Logout;
}
