import React, { Component } from "react";
import { TableHeaderColumn } from "react-bootstrap-table";

import EntityTable from "../../component/EntityTable";
import { MERCHANDISER_LIST } from "../../config/lang";

import { openMerchandiser, userName, movement, userState, userDateJoined } from "../../util/formatter";
import { translate } from "../../util/util";

class List extends Component {
  render() {
    const lang = this.props.currentUser.language;

    return (
      <div className="container-fluid">
        {/*<PageHeader>
          <PageHeaderTitle>
            <span className="faIcon">
              <i className="fa fa-users" />
            </span>
            Мерчендайзери
          </PageHeaderTitle>
        </PageHeader>*/}

        <div className="box p-4">
          <EntityTable
            entity="merchandiser"
            columns={[
              {
                dataField: "id",
                text: "",
                formatter: openMerchandiser,
              },
              {
                dataField: "name",
                text: translate(MERCHANDISER_LIST, "user_name", lang),
                formatter: userName,
              },
              {
                dataField: "phone_number",
                text: translate(MERCHANDISER_LIST, "phone_number", lang),
              },
              {
                dataField: "movement",
                text: translate(MERCHANDISER_LIST, "movement", lang),
                formatter: movement,
              },
              {
                dataField: "userState",
                text: translate(MERCHANDISER_LIST, "user_state", lang),
                formatter: userState,
              },
              {
                dataField: "userDateJoined",
                text: translate(MERCHANDISER_LIST, "date_join", lang),
                formatter: userDateJoined,
              },
            ]}>
            <TableHeaderColumn dataField="id" dataFormat={openMerchandiser} isKey width="120px"></TableHeaderColumn>
            <TableHeaderColumn dataFormat={userName}>{translate(MERCHANDISER_LIST, "user_name", lang)}</TableHeaderColumn>
            <TableHeaderColumn dataField="phone_number">{translate(MERCHANDISER_LIST, "phone_number", lang)}</TableHeaderColumn>
            <TableHeaderColumn dataFormat={movement} filterValue={movement}>
              {translate(MERCHANDISER_LIST, "movement", lang)}
            </TableHeaderColumn>
            <TableHeaderColumn dataFormat={userState}>{translate(MERCHANDISER_LIST, "user_state", lang)}</TableHeaderColumn>
            <TableHeaderColumn dataFormat={userDateJoined}>{translate(MERCHANDISER_LIST, "date_join", lang)}</TableHeaderColumn>
          </EntityTable>
        </div>
      </div>
    );
  }
}

export default List;
