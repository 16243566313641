import React from "react";
import GoogleMapReact from 'google-map-react'
import './map.css'
import {Status} from "./Status";

const LocationPin = ({title='', text='', imageSrc='' }) => (
    <div className="pin">
        <div>
            <img src={imageSrc} alt={title} />
            <Status status='green'/>
        </div>
        <div>
            <p className='pin_name'>{title}</p>
            <p className="pin-location">{text}</p>
        </div>
    </div>
)

const Map = ({ location, zoomLevel, title, imageSrc}) => (
    <div className="map">
        <div className="google-map">
            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyBDjCcVGg_oXNHGEzI1d8YZhjUpg-V7zwA' }}
                defaultCenter={location}
                defaultZoom={zoomLevel}
            >
                <LocationPin
                    lat={location.lat}
                    lng={location.lng}
                    text={location.address}
                    title={title}
                    imageSrc={imageSrc}
                />
            </GoogleMapReact>
        </div>
    </div>
)
export default Map