import React, { Component } from "react";
import { TableHeaderColumn } from "react-bootstrap-table";

import EntityTable from "../../component/EntityTable";
import { TAKS_LIST } from "../../config/lang";

import {
  openTask,
  imageCount,
  dateEnd,
  supervisorName,
  merchandiserName,
  merchandiserPhone,
  taskNegative,
  taskComment,
  networkTask,
  point,
  taskStatus, createdByInfo
} from "../../util/formatter";
import { translate } from "../../util/util";

import "./style.scss";

class TaskList extends Component {
  render() {
    const lang = this.props.currentUser.language;
    return (
      <div className="container-fluid task-list">
        {/*<PageHeader>
          <PageHeaderTitle>
            <span className="faIcon">
              <i className="fa fa-tasks" />
            </span>
            Завдання
          </PageHeaderTitle>
        </PageHeader>*/}
        <div className="box p-4">
          <EntityTable entity="task" pagination={true}>
            <TableHeaderColumn dataField="id" dataFormat={openTask} width="60px" isKey></TableHeaderColumn>
            <TableHeaderColumn dataFormat={point} width="300px">
              {translate(TAKS_LIST, "point", lang)}
            </TableHeaderColumn>
            <TableHeaderColumn dataFormat={createdByInfo}>{translate(TAKS_LIST, "created_by", lang)}</TableHeaderColumn>

            <TableHeaderColumn dataFormat={supervisorName}>{translate(TAKS_LIST, "supervisor", lang)}</TableHeaderColumn>
            <TableHeaderColumn dataFormat={merchandiserName}>{translate(TAKS_LIST, "merchendiser", lang)}</TableHeaderColumn>
            {/*<TableHeaderColumn dataFormat={merchandiserPhone}>Телефон</TableHeaderColumn>*/}

            <TableHeaderColumn dataField="title" width="200px">
              {translate(TAKS_LIST, "name", lang)}
            </TableHeaderColumn>

            {/* <TableHeaderColumn dataFormat={dateStart}>Старт</TableHeaderColumn> */}
            <TableHeaderColumn dataFormat={dateEnd}>{translate(TAKS_LIST, "finishing", lang)}</TableHeaderColumn>

            {/*<TableHeaderColumn dataFormat={taskNegative}>Негативні</TableHeaderColumn>*/}
            {/*<TableHeaderColumn dataFormat={taskComment}>Коментар</TableHeaderColumn>*/}

            {/*<TableHeaderColumn dataFormat={imageCount}>Зображення</TableHeaderColumn>*/}
            <TableHeaderColumn dataFormat={taskStatus}>{translate(TAKS_LIST, "status", lang)}</TableHeaderColumn>
          </EntityTable>
        </div>
      </div>
    );
  }
}

export { TaskList };
