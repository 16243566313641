import React, { Component } from "react";
import EntityDropdown from "/src/component/EntityDropdown";
import { httpRequest } from "/src/util/action";
import { fieldIsRequired } from "/src/util/util";
import moment from "moment";
import Dropzone from "react-dropzone";
import Alert from "react-s-alert";

import { TAKS_IMPORT } from "../../../config/lang";

import { getStore } from "../../../util/redux/store";
import { translate } from "../../../util/util";

import "../style.scss";

const me = getStore().getState().me;
class ImportAdvanced extends Component {
  constructor(props) {
    super(props);

    this.dataTypeList = [
      {
        id: 1,
        label: translate(TAKS_IMPORT, "network_label", me.language),
        value: "network",
      },
      {
        id: 2,
        label: translate(TAKS_IMPORT, "point_label", me.language),
        value: "point",
      },
    ];

    this.state = {
      totalDataSize: 0,
      sizePerPage: 10,
      pageAmount: 1,
      page: 1,
      errorList: [""],
      errors: {
        name: "",
        point: "",
        network: "",
        template: "",
        tasks: [],
      },
      filter: {
        dataType: null, // "network" | "point"
        network: null,
        point: null,
        template: null,
        bulkName: "",
      },
      tasks: [],
      uploadFile: null,
      fileDataToSend: [],

      uploadLogs: [],
      nextUploadPage: null, // string or null
    };
  }

  componentDidMount() {}

  validate = formValues => {
    let errors = { ...this.state.errors };
    let hasErrors = false;
    const errMsg = translate(TAKS_IMPORT, "complete_at_least_one_task_msg", me.language);

    if (!formValues.tasks?.length) {
      this.setState({ errorList: [fieldIsRequired(errMsg)] });
      hasErrors = true;
    } else {
      this.setState({ errorList: [] });
      formValues.tasks.map((task, taskIndex) => {
        errors["tasks"][taskIndex] = { title: "", description: "", start: null, end: null, point: "", network: "" };

        Object.entries(task).map((entry, index) => {
          if (!entry[1]?.length && entry[0] !== "point" && entry[0] !== "network") {
            errors["tasks"][taskIndex][entry[0]] = fieldIsRequired();
            hasErrors = true;
          }
        });
      });
    }

    if (!formValues.action_name) {
      errors["name"] = fieldIsRequired();
    }
    if (!formValues.template) {
      errors["template"] = fieldIsRequired();
    }

    this.setState({ errors });

    console.log("formValues", formValues);

    return !hasErrors;
  };

  submit = (fileDataToSend = []) => {
    let tasks = this.prepareItemsToSend(this.state, fileDataToSend);

    if (!this.validate(tasks)) {
      return;
    }
    this.setState({
      errorList: [""],
      errors: {
        name: "",
        point: "",
        network: "",
        template: "",
        tasks: [],
      },
    });
    httpRequest({ method: "POST", url: "tasks/bulk_create/", post: tasks }, async response => {
      const result = await response.json();

      if (response.status === 200) {
        Alert.success("Items successfully saved", { position: "bottom-right" });

        this.setState({
          tasks: [],
          uploadFile: null,
          fileDataToSend: [],
        });
      }

      if (response.status === 400) {
        this.setState({
          errorList: [result.details],
        });
      }
    });
  };

  prepareItemsToSend = (state, fileDataToSend) => {
    const points = fileDataToSend.map(item => item.point).filter(point => !!point) || [];
    const networks = fileDataToSend.map(item => item.network).filter(network => !!network) || [];

    return {
      action_name: state.filter.bulkName,
      networks,
      points,
      template: state.filter.template,
      tasks: fileDataToSend.length
        ? fileDataToSend
        : state.tasks?.map((item, idx) => ({
            title: item.title || "",
            description: item.info || "",
            start: moment(item.start).format("yyyy-MM-DDT00:00"),
            end: moment(item.end).format("yyyy-MM-DDT00:00"),
            point: item.point || "",
            network: item.network || "",
          })),
    };
  };

  onChange = (property, value, idx) => {
    let tasks = this.state.tasks;
    tasks[idx][property] = value;
    this.setState({ tasks });
  };

  changeFilter = (filterEntity, item) => {
    let filter = { ...this.state.filter };

    filter[filterEntity] = item?.id;

    this.setState(prevState => ({
      filter,
    }));
  };

  handleCangeDataType = option => {
    this.setState(prevState => ({
      filter: {
        ...prevState.filter,
        dataType: option.value,
      },
    }));
  };

  handleChangeBulkName = e => {
    this.setState(prevState => ({
      filter: {
        ...prevState.filter,
        bulkName: e.target.value || "",
      },
    }));
  };

  uploadFile = acceptedFiles => {
    let file = acceptedFiles[0];
    let reader = new FileReader();
    reader.onload = e => {
      const uploadFile = reader.result.split(/\r?\n/).filter(item => item?.length);

      this.getUploadData(uploadFile);
      this.setState({ uploadFile: file });
    };
    reader.readAsText(file);
  };

  getUploadData = (stringArray = [""]) => {
    stringArray.shift();

    const preparedUploadData = stringArray.map((row, index) => {
      const fields = stringArray[index].split(",");

      return {
        title: fields[0] || "",
        description: fields[1] || "",
        start: moment(fields[2], "DD/MM/YYYY").format("yyyy-MM-DDT00:00"),
        end: moment(fields[3], "DD/MM/YYYY").format("yyyy-MM-DDT00:00"),
        point: fields[4] || "",
        network: fields[5] || "",
      };
    });

    this.setState({ fileDataToSend: preparedUploadData });

    return preparedUploadData;
  };

  renderErrors = (errors = {}) => {
    const RenderErrorHead = () => (
      <>
        <div>{translate(TAKS_IMPORT, "line_title", me.language)}</div>
        <div>{translate(TAKS_IMPORT, "name_title", me.language)}</div>
        <div>{translate(TAKS_IMPORT, "description_title", me.language)}</div>
        <div>{translate(TAKS_IMPORT, "start_date_title", me.language)}</div>
        <div>{translate(TAKS_IMPORT, "end_date_title", me.language)}</div>
        <div>{translate(TAKS_IMPORT, "point_label", me.language)}</div>
        <div>{translate(TAKS_IMPORT, "network_label", me.language)}</div>
      </>
    );
    const RenderErrorBody = () => {
      return (
        errors?.tasks?.map((taskError, index) => (
          <React.Fragment key={index}>
            <div>{index}</div>
            <div>{taskError.title}</div>
            <div>{taskError.description}</div>
            <div>{taskError.start}</div>
            <div>{taskError.end}</div>
            <div>{taskError.point}</div>
            <div>{taskError.network}</div>
          </React.Fragment>
        )) || null
      );
    };
    return (
      errors.tasks?.length && (
        <div className="error-task-list">
          <RenderErrorHead />
          <RenderErrorBody />
        </div>
      )
    );
  };

  render() {
    const { errorList, filter, uploadFile, fileDataToSend, errors } = this.state;
    const isShowErrorList = errorList.some(item => !!item);

    return (
      <div className="box p-4">
        {isShowErrorList && (
          <div className="error-container">
            <div className="error">
              {errorList?.map((error, index) => (
                <div key={index}>{error}</div>
              ))}
            </div>
          </div>
        )}
        {!!errors.tasks?.length && (
          <div className="error-container">
            <div className="error">{this.renderErrors(errors)}</div>
          </div>
        )}
        <div className="filter-bar mb-2 boxForm">
          <input
            type="text"
            className="form-control searchInput searchInputPhoto mr-1"
            placeholder={translate(TAKS_IMPORT, "download_name", me.language)}
            value={filter.bulkName}
            onChange={this.handleChangeBulkName}
          />
          <EntityDropdown
            entity="template"
            value={filter.template}
            asynch={true}
            onChange={o => {
              this.changeFilter("template", o);
            }}
          />
          <div>
            <Dropzone
              onDrop={acceptedFiles => this.uploadFile(acceptedFiles)}
              accept={".csv"}
              styles={{ dropzone: { minHeight: 150, maxHeight: 250 } }}
              multiple={false}>
              {({ getRootProps, getInputProps }) => (
                <div className="file-input mr-1" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <span>{uploadFile?.name || translate(TAKS_IMPORT, "click_or_drag_file", me.language)}</span>
                </div>
              )}
            </Dropzone>
            <button className="btn btn-primary" disabled={!fileDataToSend?.length} onClick={e => this.submit(fileDataToSend)}>
              <i className="fa fa-save" /> {translate(TAKS_IMPORT, "download_btn", me.language)}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export { ImportAdvanced };
