import React, { Component } from "react";
import { Link } from "react-router-dom";

// import PhotoList from "../../component/PhotoList";
import PhotoTaskSlider from "../../component/PhotoTaskSlider";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { PRIORITY } from "../../config/const";
import { TAKS_REVIEW } from "../../config/lang";

import { dateFormat, reviewTaskPoint, reviewMerchandiserNamePhone, reviewTaskSupervisor } from "../../util/formatter";
import { getStore } from "../../util/redux/store";
import { translate } from "../../util/util";

import "./style.scss";

const me = getStore().getState().me;

class TaskReviewDetails extends Component {
  componentDidMount = () => {
    let { acceptRejectAnswer, passTaskToModal, moveNext } = this.props;

    document.addEventListener("keydown", function(e) {
      switch (e.keyCode) {
        case 37:
          moveNext(-1);
          break; // LEFT
        case 39:
          moveNext(+1);
          break; // RIGHT
        case 38:
          acceptRejectAnswer("ACCEPT");
          break; // UP
        case 40:
          passTaskToModal();
          break; // DOWN
      }
    });
  };

  render() {
    let { item, acceptRejectAnswer, passTaskToModal, activePhotoIndex } = this.props;
    const task = item.task;
    const tags = item.selected_tags;
    return (
      <div className="container-fluid task-review-details-container">
        <div className="p-4">
          <div className="item taskBlock reviewTaskPreview">
            {/*TODO revert for release to prod*/}
            <div className="p-a-md">
              <div className="row">
                <div className="col-md-5 photoContainer">
                  <div className="box p-2 photoContainer photo-wrapper">
                    <div className="row previewPhotoNav">
                      <div className="col-md-12">
                        <h5>{translate(TAKS_REVIEW, "photo_title", me.language)}</h5>
                      </div>
                    </div>
                    <div className="col-md-12 photoCanvas photo-carousel">
                      {item.photos.length > 0 ? (
                        <PhotoTaskSlider activePhotoIndex={activePhotoIndex} photos={item.photos} />
                      ) : (
                        item.photo && <img src={item.photo} className="previewImage" />
                      )}
                      {/* {item.photo&&<Photo src={item.photo} />} */}
                    </div>
                  </div>
                </div>

                <div className="col-md-7">
                  <div className="box p-a-md">
                    <h5>{translate(TAKS_REVIEW, "details_task", me.language)}</h5>

                    <div className="row text-left">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>{translate(TAKS_REVIEW, "title_task", me.language)}</label>
                          <p>{task?.title || "—"}</p>
                        </div>

                        <div className="form-group">
                          <label>{translate(TAKS_REVIEW, "description_task", me.language)}</label>
                          <p>{task?.description || "—"}</p>
                        </div>

                        <div className="form-group">
                          <label>{translate(TAKS_REVIEW, "point", me.language)}</label>
                          <p>{reviewTaskPoint("", item)}</p>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>{translate(TAKS_REVIEW, "tags_task", me.language)}</label>
                          <p>
                            {task &&
                              task.tags?.map((i, index) => (
                                <span key={index} className="label rounded primary">
                                  {i.name}
                                </span>
                              ))}
                          </p>
                        </div>

                        <div className="form-group">
                          <label>{translate(TAKS_REVIEW, "images_task", me.language)}</label>
                          <p>
                            {task &&
                              task.images?.map((i, index) => (
                                <a key={index} href={i.image} target="_blank">
                                  {" "}
                                  <i className="fa fa-photo" />{" "}
                                </a>
                              ))}
                          </p>
                        </div>

                        <div className="form-group">
                          <label>{translate(TAKS_REVIEW, "priority", me.language)}</label>
                          <p>{task && PRIORITY[task.priority]}</p>
                        </div>

                        <div className="form-group">
                          <label>{translate(TAKS_REVIEW, "deadline_task", me.language)}</label>
                          <p>{task && dateFormat(task.end)}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="box p-a-md">
                    <h5>{translate(TAKS_REVIEW, "details_response_task", me.language)}</h5>

                    <div className="row text-left">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>{translate(TAKS_REVIEW, "merchandiser", me.language)}</label>
                          <p>{reviewMerchandiserNamePhone("", item)}</p>
                        </div>

                        <div className="form-group">
                          <label>{translate(TAKS_REVIEW, "supervisor", me.language)}</label>
                          <p>{reviewTaskSupervisor("", item)}</p>
                        </div>

                        <div className="form-group">
                          <label>{translate(TAKS_REVIEW, "date_response_task", me.language)}</label>
                          <p>{dateFormat(item.created)}</p>
                        </div>

                        <div className="form-group">
                          <label>{translate(TAKS_REVIEW, "text_response_task", me.language)}</label>
                          <p>{item.answer}</p>
                        </div>

                        <div className="form-group">
                          <label>{translate(TAKS_REVIEW, "photo_response_task", me.language)}</label>
                          <div>
                            <a href={item.photo} target="_blank">
                              <i className="fa fa-photo" />
                            </a>
                          </div>
                        </div>

                        {item.boolean_answer !== null ? (
                          <div className="form-group">
                            {/* <label>Так/Ні</label> */}
                            <span className="mr-2">
                              {item?.boolean_answer ? <RadioButtonCheckedIcon color={"info"} /> : <RadioButtonUncheckedIcon color={"info"} />}
                              {translate(TAKS_REVIEW, "yes", me.language)}
                            </span>
                            <span>
                              {!item?.boolean_answer ? <RadioButtonCheckedIcon color={"info"} /> : <RadioButtonUncheckedIcon color={"info"} />}
                              {translate(TAKS_REVIEW, "no", me.language)}
                            </span>
                          </div>
                        ) : null}

                        {item.visit && (
                          <div className="form-group">
                            <label>{translate(TAKS_REVIEW, "visit_id", me.language)}</label>
                            <p>
                              <Link to={"visit/" + item.visit.id} target="_blank">
                                {item.visit.id}
                              </Link>
                            </p>
                          </div>
                        )}
                        {tags && (
                          <div className="form-group tags-group">
                            <label>{translate(TAKS_REVIEW, "tags_task", me.language)}</label>
                            <div>
                              {tags.map((i, index) => (
                                <span key={index} className="label rounded primary">
                                  {i}
                                </span>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {!item.is_supervised && (
                    <div className="box p-4">
                      <div className="row">
                        <div className="col-md-6">
                          <div
                            className="btn btn-primary acceptBtn"
                            onClick={() => {
                              acceptRejectAnswer("ACCEPT");
                            }}>
                            {translate(TAKS_REVIEW, "apply_btn", me.language)} <i className="fa fa-check" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="btn btn-primary rejectBtn" onClick={passTaskToModal}>
                            {translate(TAKS_REVIEW, "reject_btn", me.language)} <i className="fa fa-times" />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { TaskReviewDetails };
