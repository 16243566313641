import React, { Component } from "react";
import EntityDropdown from "/src/component/EntityDropdown";
import { DatePicker } from "/src/component/general/DatePicker";
import { Field } from "/src/component/general/form/Field";
import PointFilter from "/src/component/PointFilter";
import ReactSelect from "/src/component/ReactSelect";
import { call, httpRequest } from "/src/util/action";
import { getStore } from "/src/util/redux/store";
import { fieldIsRequired } from "/src/util/util";
import moment from "moment";
import Dropzone from "react-dropzone";
import Alert from "react-s-alert";

import { TAKS_IMPORT } from "../../../config/lang";

import { translate } from "../../../util/util";

import "../style.scss";

const me = getStore().getState().me;

class ImportCore extends Component {
  constructor(props) {
    super(props);

    this.dataTypeList = [
      {
        id: 1,
        label: "Мережа",
        value: "network",
      },
      {
        id: 2,
        label: "Точка",
        value: "point",
      },
    ];

    this.state = {
      totalDataSize: 0,
      sizePerPage: 10,
      pageAmount: 1,
      page: 1,
      errorList: [""],
      errors: {
        name: "",
        point: "",
        network: "",
        template: "",
        tasks: [],
      },
      filter: {
        dataType: null, // "network" | "point"
        network: null,
        point: null,
        template: null,
        bulkName: "",
      },
      tasks: [],
      uploadFile: null,
      fileDataToSend: [],
      formValues: null,
    };
  }

  componentDidMount() {}

  validate = formValues => {
    let errors = { ...this.state.errors };
    let hasErrors = false;

    if (!formValues.tasks?.length) {
      const stringTranslate = translate(TAKS_IMPORT, "complete_at_least_one_task_msg", me.language);
      this.setState({ errorList: [fieldIsRequired(stringTranslate)] });
      hasErrors = true;
    } else {
      this.setState({ errorList: [] });
      formValues.tasks.map((task, taskIndex) => {
        errors["tasks"][taskIndex] = { title: "", description: "", start: null, end: null, point: "", network: "", images: [] };

        Object.entries(task).map(entry => {
          if (!entry[1]?.length && entry[0] !== "point" && entry[0] !== "network" && entry[0] !== "images") {
            errors["tasks"][taskIndex][entry[0]] = fieldIsRequired();
            hasErrors = true;
          }
        });
      });
    }

    if (!formValues.action_name) {
      errors["name"] = fieldIsRequired();
    }
    if (!formValues.template) {
      errors["template"] = fieldIsRequired();
    }

    this.setState({ errors });

    return !hasErrors;
  };

  submit = (fileDataToSend = []) => {
    let tasks = this.prepareItemsToSend(this.state, fileDataToSend);

    if (!this.validate(tasks)) {
      return;
    }
    this.setState({
      errorList: [""],
      errors: {
        name: "",
        point: "",
        network: "",
        template: "",
        tasks: [],
      },
    });
    httpRequest({ method: "POST", url: "tasks/bulk_create/", post: tasks }, async response => {
      const result = await response.json();

      if (response.status === 200) {
        Alert.success("Items successfully saved", { position: "bottom-right" });
        this.setState({
          tasks: [],
          uploadFile: null,
          fileDataToSend: [],
          filter: {
            dataType: null, // "network" | "point"
            network: null,
            point: null,
            template: null,
            bulkName: "",
          },
        });
      }

      if (response.status === 400) {
        this.setState({
          errorList: [result.details],
        });
      }
    });
  };

  prepareItemsToSend = (state, fileDataToSend) => {
    const tasks = fileDataToSend.length
      ? fileDataToSend.map(item => ({ ...item, point: state.filter.point?.code || "", network: state.filter.network?.code || "" }))
      : state.tasks?.map(item => ({
          title: item.title || "",
          description: item.info || "",
          start: moment(item.start).format("yyyy-MM-DDT00:00"),
          end: moment(item.end).format("yyyy-MM-DDT00:00"),
          point: state.filter.point?.code || "",
          network: state.filter.network?.code || "",
          images: item.images?.map(item => item.id),
        }));
    return {
      action_name: state.filter.bulkName,
      points: state.filter.point?.code ? [state.filter.point.code] : [],
      networks: state.filter.network?.code ? [state.filter.network?.code] : [],
      template: state.filter.template?.id,
      tasks,
    };
  };

  deleteRow = idx => {
    this.setState(prevState => ({
      tasks: prevState.tasks.filter((item, index) => index !== idx),
    }));
  };

  addRow = () => {
    this.setState({
      tasks: [
        ...this.state.tasks,
        {
          title: "",
          info: "",
          images: [],
          start: new Date(),
          end: new Date(new Date().setDate(new Date().getDate() + 14)),
        },
      ],
    });
  };

  onChange = (property, value, idx) => {
    let tasks = this.state.tasks;
    tasks[idx][property] = value;
    this.setState({ tasks });
  };

  changeFilter = (filterEntity, item) => {
    let filter = { ...this.state.filter };

    filter[filterEntity] = item;

    this.setState(prevState => ({
      filter,
    }));
  };

  handleChangeDataType = option => {
    this.setState(prevState => ({
      filter: {
        ...prevState.filter,
        dataType: option?.value || null,
      },
    }));
  };

  handleChangeBulkName = e => {
    this.setState(prevState => ({
      filter: {
        ...prevState.filter,
        bulkName: e.target.value || "",
      },
    }));
  };

  uploadFile = acceptedFiles => {
    let file = acceptedFiles[0];
    let reader = new FileReader();
    reader.onload = e => {
      const uploadFile = reader.result.split(/\r?\n/).filter(item => item?.length);

      this.getUploadData(uploadFile);
      this.setState({ uploadFile: file });
    };
    reader.readAsText(file);
  };

  getUploadData = (stringArray = [""]) => {
    stringArray.shift();

    const preparedUploadData = stringArray.map((row, index) => {
      const fields = stringArray[index].split(",");

      return {
        title: fields[0] || "",
        description: fields[1] || "",
        start: moment(fields[2], "DD/MM/YYYY").format("yyyy-MM-DDT00:00"),
        end: moment(fields[3], "DD/MM/YYYY").format("yyyy-MM-DDT00:00"),
      };
    });

    this.setState({ fileDataToSend: preparedUploadData });

    return preparedUploadData;
  };

  uploadImages = (acceptedFiles, rowIndex) => {
    let file = acceptedFiles[0];
    let reader = new FileReader();
    reader.onload = e => {
      let { result } = e.target;

      call({ method: "POST", url: "images/", post: { owner: me.id, image: result } }, image => {
        if (image?.id) {
          this.setState(prevState => ({
            tasks: prevState.tasks.map((task, index) => (index === rowIndex ? { ...task, images: [image] } : task)),
          }));
        } else {
          console.log("Error while saving image. Error: " + JSON.stringify(image), { position: "bottom-right" });
          console.log(image);
        }
      });
    };
    reader.readAsDataURL(file);
  };

  deleteImage = (rowIndex, imageIndex) => {
    let images = this.state.tasks[rowIndex].images;
    let id = images[imageIndex]?.id; // we could upload only one file in 'images' field in row now

    call({ method: "DELETE", url: "images/" + id + "/", body: {} }, () => {
      Alert.success("Image successfully deleted", { position: "top" });
    });
    this.setState(prevState => ({
      tasks: prevState.tasks.map((task, taskIndex) => (rowIndex === taskIndex ? { ...task, images: [] } : task)),
    }));
  };

  renderUploadFileResult = (stringArray = [""]) => {
    const titles = stringArray.shift();

    const renderHead = (numRow = 0) => (
      <thead className="primary text-center">
        <tr>
          {titles?.split(",").map((field, index) => (
            <th key={index}>{field}</th>
          ))}
        </tr>
      </thead>
    );
    const renderList = (numRow = 0) => {
      return (
        <tbody>
          {stringArray.map((row, index) => (
            <tr key={index}>
              {stringArray[index].split(",").map((field, index) => (
                <td key={index}>{field}</td>
              ))}
            </tr>
          ))}
        </tbody>
      );
    };

    return (
      stringArray.some(item => !!item) && (
        <table className="table table-hover table-bordered table-tasks-import">
          {renderHead()}
          {renderList()}
        </table>
      )
    );
  };

  renderErrors = (errors = {}) => {
    const RenderErrorHead = () => (
      <>
        <div>{translate(TAKS_IMPORT, "line_title", me.language)}</div>
        <div>{translate(TAKS_IMPORT, "name_title", me.language)}</div>
        <div>{translate(TAKS_IMPORT, "description_title", me.language)}</div>
        <div>{translate(TAKS_IMPORT, "start_date_title", me.language)}</div>
        <div>{translate(TAKS_IMPORT, "end_date_title", me.language)}</div>
        <div>{translate(TAKS_IMPORT, "point_label", me.language)}</div>
        <div>{translate(TAKS_IMPORT, "network_label", me.language)}</div>
      </>
    );
    const RenderErrorBody = () => {
      return (
        errors?.tasks?.map((taskError, index) => (
          <React.Fragment key={index}>
            <div>{index}</div>
            <div>{taskError.title}</div>
            <div> {taskError.description}</div>
            <div>{taskError.start}</div>
            <div>{taskError.end}</div>
            <div>{taskError.point}</div>
            <div>{taskError.network}</div>
          </React.Fragment>
        )) || null
      );
    };
    return (
      errors.tasks?.length && (
        <div className="error-task-list">
          <RenderErrorHead />
          <RenderErrorBody />
        </div>
      )
    );
  };

  renderRow = (item, index) => {
    return (
      <tr key={index}>
        <td>
          <input type="text" value={item.title} className="form-control" onChange={o => this.onChange("title", o.target.value, index)} />
        </td>

        <td>
          <DatePicker selected={new Date(item.start)} onChange={date => this.onChange("start", date, index)} className="datePicker form-control" />
        </td>

        <td>
          <DatePicker selected={new Date(item.end)} onChange={date => this.onChange("end", date, index)} className="datePicker form-control" />
        </td>

        <td>
          {index === 0 || this.state.filter.dataType === "network" ? (
            <div>
              {this.state.tasks[index].images?.length ? (
                <div className="mt-3 taskImagesEdit">
                  {this.state.tasks[index].images?.map((image, idx) => (
                    <div key={idx} className="d-inline-block">
                      <img src={image.image} />
                      <span className="deleteImage" onClick={() => this.deleteImage(index, idx)}>
                        <i className="fa fa-minus-square-o" />
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <Dropzone
                  onDrop={acceptedFiles => this.uploadImages(acceptedFiles, index)}
                  accept={"image/jpeg, image/png"}
                  styles={{ dropzone: { minHeight: 50, maxHeight: 150 } }}
                  multiple={false}>
                  {({ getRootProps, getInputProps }) => (
                    <section className="dropzone-flex">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <span>{translate(TAKS_IMPORT, "select_download_type", me.language)}</span>
                      </div>
                    </section>
                  )}
                </Dropzone>
              )}
            </div>
          ) : this.state.filter.dataType === null ? (
            <>{translate(TAKS_IMPORT, "click_drag_img", me.language)}</>
          ) : (
            <>
              {translate(TAKS_IMPORT, "only_one_photo_allowed_for_type_point", me.language)}
            </>
          )}
        </td>

        <td>
          <textarea className="form-control" onChange={o => this.onChange("info", o.target.value, index)} defaultValue={item.info} />
        </td>

        <td>
          <button className="btn btn-sm table-tasks-import-delete">
            <i className="fa fa-minus-square" onClick={() => this.deleteRow(index)} />
          </button>
        </td>
      </tr>
    );
  };

  render() {
    const { errorList, filter, uploadFile, fileDataToSend, errors } = this.state;
    const isShowErrorList = errorList.some(item => !!item);

    return (
      <div className="box p-4">
        {isShowErrorList && (
          <div className="error-container">
            <div className="error">
              {errorList?.map((error, index) => (
                <div key={index}>{error}</div>
              ))}
            </div>
          </div>
        )}
        {!!errors.tasks?.length && (
          <div className="error-container">
            <div className="error">{this.renderErrors(errors)}</div>
          </div>
        )}
        <div className="filter-bar mb-2 boxForm">
          <Field className="import-title" error={errors.name}>
            <input
              name="bulkName"
              type="text"
              className="form-control searchInput searchInputPhoto mr-1"
              placeholder={translate(TAKS_IMPORT, "download_name", me.language)}
              value={filter.bulkName}
              onChange={this.handleChangeBulkName}
            />
          </Field>

          <EntityDropdown
            entity="template"
            value={filter.template}
            asynch={true}
            onChange={o => {
              this.changeFilter("template", o);
            }}
            useFullOptionObject
          />
          <ReactSelect
            name="dataTypeList"
            entity="dataTypeList"
            options={this.dataTypeList}
            onChange={this.handleChangeDataType}
            placeholder={translate(TAKS_IMPORT, "type_loading", me.language)}
          />
          {filter.dataType === "network" && (
            <EntityDropdown
              entity="network"
              value={filter.network}
              onChange={o => {
                this.changeFilter("network", o);
              }}
              useFullOptionObject
            />
          )}
          {filter.dataType === "point" && (
            <PointFilter
              value={filter.point}
              onChange={o => {
                this.changeFilter("point", o);
              }}
              useFullOptionObject
            />
          )}
          <div>
            <Dropzone
              onDrop={acceptedFiles => this.uploadFile(acceptedFiles)}
              accept={".csv"}
              styles={{ dropzone: { minHeight: 150, maxHeight: 250 } }}
              multiple={false}>
              {({ getRootProps, getInputProps }) => {
                return (
                  <div className="file-input mr-1" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <span>{uploadFile?.name || translate(TAKS_IMPORT, "click_or_drag_file", me.language)}</span>
                  </div>
                );
              }}
            </Dropzone>
            <button className="btn btn-primary" disabled={!fileDataToSend?.length} onClick={e => this.submit(fileDataToSend)}>
              <i className="fa fa-save" /> {translate(TAKS_IMPORT, "download_btn", me.language)}
            </button>
          </div>
        </div>
        {/*<div className="upload-file-result">{this.renderUploadFileResult(uploadFile)}</div>*/}

        <table className="table table-hover table-bordered table-tasks-import">
          <thead className="primary text-center">
            <tr>
              <th width="150">{translate(TAKS_IMPORT, "name_title", me.language)}</th>
              <th width="150">{translate(TAKS_IMPORT, "start_date_title", me.language)}</th>
              <th width="150">{translate(TAKS_IMPORT, "end_date_title", me.language)}</th>
              <th width="150">{translate(TAKS_IMPORT, "photo_title", me.language)}</th>
              <th width="300">{translate(TAKS_IMPORT, "description_title", me.language)}</th>
              <th width="30"> </th>
            </tr>
          </thead>
          <tbody>{this.state.tasks.map((item, index) => this.renderRow(item, index))}</tbody>
        </table>

        <div className="row">
          <div className="col-md-12 text-center">
            <button className="btn btn-outline-primary" onClick={e => this.addRow()}>
              <i className="fa fa-plus" /> {translate(TAKS_IMPORT, "add_record_btn", me.language)}
            </button>
            <button className="btn btn-primary  ml-3" onClick={e => this.submit()}>
              <i className="fa fa-save" /> {translate(TAKS_IMPORT, "save_btn", me.language)}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ImportCore;
