import React from "react";
import { getStore } from "./redux/store";

export const Authorization = allowedRoles => WrappedComponent => {
  return class WithAuthorization extends React.Component {
    constructor(props) {
      super(props);
    }

    render() {
      const { me } = getStore().getState();
      if (me) {
        return <WrappedComponent {...this.props} currentUser={me} />;
      } else {
        window.location.replace("/#/");
        return null;
      }
    }
  };
};
