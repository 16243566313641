import React, { Component } from "react";

class Edit extends Component {
  render() {
    let { item } = this.props;

    return (
      <div className="row m-t">
        <div className="col-md-12">
          <div className="box boxEdit">
            <div className="box-body">
              <form role="form">
                <div className="form-group">
                  <label>Title</label>
                  <input type="text" className="form-control" placeholder="" value={item && item.title} />
                </div>

                <div className="form-group">
                  <label>Start</label>
                  <input type="text" className="form-control" placeholder="" value={item && item.start} />
                </div>

                <div className="form-group">
                  <label>End</label>
                  <input type="text" className="form-control" placeholder="" value={item && item.end} />
                </div>

                <div className="form-group">
                  <label>Day of the week</label>
                  <input type="text" className="form-control" placeholder="" value={item && item.day_of_the_week} />
                </div>

                <div className="btn btn-primary white m-b" onClick={() => this.submit()}>
                  Save changes
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Edit;
